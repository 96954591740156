@charset "UTF-8";
/*------------------------------------------------------------------------
# Project Yogi
# ------------------------------------------------------------------------
# Coded by TK-Themes
--------------------------------------------------------------------------*/
textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
  border: 1px solid #dbdada;
  outline: 0;
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  padding: 0.7142857143em 1.1428571429em;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0; }

selecttextarea, selectinput[type="text"], selectinput[type="password"], selectinput[type="datetime"], selectinput[type="datetime-local"], selectinput[type="date"], selectinput[type="month"], selectinput[type="time"], selectinput[type="week"], selectinput[type="number"], selectinput[type="email"], selectinput[type="url"], selectinput[type="search"], selectinput[type="tel"], selectinput[type="color"] {
  height: auto;
  line-height: auto; }

textareatextarea, textareainput[type="text"], textareainput[type="password"], textareainput[type="datetime"], textareainput[type="datetime-local"], textareainput[type="date"], textareainput[type="month"], textareainput[type="time"], textareainput[type="week"], textareainput[type="number"], textareainput[type="email"], textareainput[type="url"], textareainput[type="search"], textareainput[type="tel"], textareainput[type="color"], select[multiple] textarea, select[multiple] input[type="text"], select[multiple] input[type="password"], select[multiple] input[type="datetime"], select[multiple] input[type="datetime-local"], select[multiple] input[type="date"], select[multiple] input[type="month"], select[multiple] input[type="time"], select[multiple] input[type="week"], select[multiple] input[type="number"], select[multiple] input[type="email"], select[multiple] input[type="url"], select[multiple] input[type="search"], select[multiple] input[type="tel"], select[multiple] input[type="color"] {
  height: auto; }

.custom-title {
  margin-top: 0;
  font-size: 36px;
  text-transform: uppercase;
  color: #fe6367;
  position: relative;
  padding-bottom: 10px;
  letter-spacing: 3px;
  line-height: 1.7; }

.custom-title span {
  color: #454545;
  font-weight: bold; }

.custom-title:before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 25px;
  height: 1px;
  background: #fe6367; }

.custom-title-home {
  margin-top: 0;
  font-size: 36px;
  text-transform: uppercase;
  color: #fe6367;
  position: relative;
  padding-bottom: 10px;
  letter-spacing: 3px;
  line-height: 1.7;
  font-weight: bold;
  padding-top: 55px;
  padding-bottom: 25px;
  margin-bottom: 50px;
  background: url(../images/icon-title.png) top left no-repeat; }

.custom-title-home span {
  color: #454545;
  font-weight: bold; }

.custom-title-home:before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 25px;
  height: 1px;
  background: #fe6367; }

.custom-title-home span {
  color: #454545;
  font-weight: bold; }

.custom-title-home:before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 25px;
  height: 1px;
  background: #fe6367; }

.custom-title-home-center {
  margin-top: 0;
  font-size: 36px;
  text-transform: uppercase;
  color: #fe6367;
  position: relative;
  padding-bottom: 10px;
  letter-spacing: 3px;
  line-height: 1.7;
  font-weight: bold;
  padding-top: 55px;
  padding-bottom: 25px;
  margin-bottom: 50px;
  background: url(../images/icon-title.png) top left no-repeat;
  background-position: top center;
  text-align: center; }

.custom-title-home-center span {
  color: #454545;
  font-weight: bold; }

.custom-title-home-center:before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 25px;
  height: 1px;
  background: #fe6367; }

.custom-title-home-center span {
  color: #454545;
  font-weight: bold; }

.custom-title-home-center:before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 25px;
  height: 1px;
  background: #fe6367; }

.custom-title-home-center span {
  color: #454545;
  font-weight: bold; }

.custom-title-home-center:before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 25px;
  height: 1px;
  background: #fe6367; }

.custom-title-home-center span {
  color: #454545;
  font-weight: bold; }

.custom-title-home-center:before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 25px;
  height: 1px;
  background: #fe6367; }

.custom-title-home-center:before {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

@media print {
  * {
    text-shadow: none !important;
    color: #000 !important;
    background: transparent !important;
    box-shadow: none !important; }
  a, a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  a[href^="javascript:"]:after, a[href^="#"]:after {
    content: ""; }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; }
  select {
    background: #fff !important; }
  .navbar {
    display: none; }
  .table td, .table th {
    background-color: #fff !important; }
  .btn > .caret, .dropup > .btn > .caret, .widget_newsletterwidget .newsletter-submit > .caret, .dropup > .widget_newsletterwidget .newsletter-submit > .caret {
    border-top-color: #000 !important; }
  .label {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ddd !important; } }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-size: 14px;
  -webkit-tap-highlight-color: transparent; }

body {
  font-family: "PT Sans",sans-serif;
  font-size: 14px;
  line-height: 1.7;
  color: #5f5f5f;
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px; }

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

a {
  color: #5f5f5f;
  text-decoration: none;
  -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  -o-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease; }

a:hover, a:focus {
  color: #fe6367;
  text-decoration: none; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border: 0;
  max-width: 100%;
  height: auto; }

.gm-style img {
  max-width: none; }

.img-responsive, .thumbnail > img, .thumbnail a > img, .carousel-inner > .item > img, .carousel-inner > .item > a > img {
  display: block;
  width: 100% \9;
  max-width: 100%;
  height: auto; }

.img-rounded {
  border-radius: 3px; }

.img-thumbnail {
  padding: 0.2857142857142857em;
  line-height: 1.7;
  background-color: transparent;
  border: 1px solid #dddddd;
  border-radius: 3px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  width: 100% \9;
  max-width: 100%;
  height: auto; }

.img-circle {
  border-radius: 50%; }

img.grayscale, .grayscale > img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale"); }

img.grayscale:hover, .grayscale > img:hover {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%); }

hr {
  margin-top: 1.6428571428571428em;
  margin-bottom: 1.6428571428571428em;
  border: 0;
  border-top: 1px solid #f4f4f4; }

.sr-only, .jp-controls a span {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

iframe {
  width: 100%;
  max-width: 100%;
  border: 0px; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'PT Sans',serif;
  font-weight: bold;
  line-height: 1.2;
  color: #454545;
  font-style: normal; }

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #5f5f5f; }

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
  color: #454545; }

h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, .h1 a:hover, .h2 a:hover, .h3 a:hover, .h4 a:hover, .h5 a:hover, .h6 a:hover {
  color: #fe6367;
  text-decoration: none; }

h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 1.6428571428571428rem;
  margin-bottom: 0.8214285714285714rem; }

h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h1 .small, .h1 .small, h2 .small, .h2 .small, h3 .small, .h3 .small {
  font-size: 65%; }

h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0.8214285714285714em;
  margin-bottom: 0.8214285714285714em; }

h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small, h4 .small, .h4 .small, h5 .small, .h5 .small, h6 .small, .h6 .small {
  font-size: 75%; }

h1, .h1 {
  font-size: 2.5714285714285716em; }

h2, .h2 {
  font-size: 2.142857142857143em; }

h3, .h3 {
  font-size: 1.7142857142857142em; }

h4, .h4 {
  font-size: 1.2857142857142858em; }

h5, .h5 {
  font-size: 1em; }

h6, .h6 {
  font-size: 0.8571428571428571em; }

h1.white, h2.white, h3.white, h4.white, h5.white {
  color: white; }

h1.text-shadow, h2.text-shadow, h3.text-shadow, h4.text-shadow, h5.text-shadow {
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2); }

p {
  margin: 0 0 0.8214285714em; }

.lead {
  margin-bottom: 1.6428571428571428em;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4; }

@media (min-width: 768px) {
  .lead {
    font-size: 21px; } }

cite {
  font-style: normal; }

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-nowrap {
  white-space: nowrap; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.text-muted {
  color: #5f5f5f; }

.text-primary {
  color: #fe6367; }

a.text-primary:hover {
  color: #fe3036; }

.text-success {
  color: #3c763d; }

a.text-success:hover {
  color: #2b542c; }

.text-info {
  color: #31708f; }

a.text-info:hover {
  color: #245269; }

.text-warning {
  color: #8a6d3b; }

a.text-warning:hover {
  color: #66512c; }

.text-danger {
  color: #a94442; }

a.text-danger:hover {
  color: #843534; }

.bg-primary {
  color: #fff;
  background-color: #fe6367; }

a.bg-primary:hover {
  background-color: #fe3036; }

.bg-success {
  background-color: #dff0d8; }

a.bg-success:hover {
  background-color: #c1e2b3; }

.bg-info {
  background-color: #d9edf7; }

a.bg-info:hover {
  background-color: #afd9ee; }

.bg-warning {
  background-color: #fcf8e3; }

a.bg-warning:hover {
  background-color: #f7ecb5; }

.bg-danger {
  background-color: #f2dede; }

a.bg-danger:hover {
  background-color: #e4b9b9; }

.bg-primary-overlay {
  background: rgba(254, 99, 103, 0.95); }

.bg-cover {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important; }

.bg-transparent {
  background-color: transparent; }

.page-header {
  padding-bottom: -0.1785714285714286em;
  margin: 3.2857142857em 0 1.6428571429em;
  border-bottom: 1px solid #f4f4f4; }

ul, ol {
  margin-top: 0;
  margin-bottom: 0.8214285714285714em; }

ul ul, ol ul, ul ol, ol ol {
  margin-bottom: 0; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px; }

.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px; }

dl {
  margin-top: 0;
  margin-bottom: 1.6428571428571428em; }

dt, dd {
  line-height: 1.7; }

dt {
  font-weight: bold; }

dd {
  margin-left: 0; }

@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .dl-horizontal dd {
    margin-left: 180px; } }

abbr[title], abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #5f5f5f; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

blockquote {
  padding: 0.8214285714em 1.6428571429em;
  margin: 0 0 1.6428571429em;
  font-size: 17.5px;
  border-left: 5px solid #f4f4f4; }

blockquote p:last-child, blockquote ul:last-child, blockquote ol:last-child {
  margin-bottom: 0; }

blockquote footer, blockquote small, blockquote .small {
  display: block;
  font-size: 80%;
  line-height: 1.7;
  color: #5f5f5f; }

blockquote footer:before, blockquote small:before, blockquote .small:before {
  content: '\2014 \00A0'; }

.blockquote-reverse, blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #f4f4f4;
  border-left: 0;
  text-align: right; }

.blockquote-reverse footer:before, blockquote.pull-right footer:before, .blockquote-reverse small:before, blockquote.pull-right small:before, .blockquote-reverse .small:before, blockquote.pull-right .small:before {
  content: ''; }

.blockquote-reverse footer:after, blockquote.pull-right footer:after, .blockquote-reverse small:after, blockquote.pull-right small:after, .blockquote-reverse .small:after, blockquote.pull-right .small:after {
  content: '\00A0 \2014'; }

blockquote:before, blockquote:after {
  content: ""; }

address {
  margin-bottom: 1.6428571428571428em;
  font-style: normal;
  line-height: 1.7; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 1.0714285714285714em;
  padding-right: 1.0714285714285714em; }

@media (min-width: 768px) {
  .container {
    width: 722.1428571428571px; } }

@media (min-width: 992px) {
  .container {
    width: 942.1428571428571px; } }

@media (min-width: 1200px) {
  .container {
    width: 1172.142857142857px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 1.0714285714285714em;
  padding-right: 1.0714285714285714em; }

.container-boxed {
  margin-right: auto;
  margin-left: auto;
  padding-left: 1.0714285714285714em;
  padding-right: 1.0714285714285714em; }

@media (min-width: 768px) {
  .container-boxed {
    width: 96.2%; } }

@media (min-width: 992px) {
  .container-boxed {
    width: 97%; } }

@media (min-width: 1200px) {
  .container-boxed {
    width: 97%; } }

.container-boxed.max {
  max-width: 1200px; }

.container-boxed.offset {
  padding-top: 0px;
  padding-bottom: 100px;
  margin: 0 auto; }

@media (max-width: 767px) {
  .container-boxed {
    width: 100%; }
  .container-boxed.max {
    max-width: 100%; }
  .container-boxed.offset {
    padding-top: 0;
    padding-bottom: 0;
    margin: 40px auto; } }

.container-fullwidth {
  overflow: hidden;
  width: 100%; }

@media (min-width: 800px) and (max-width: 991px) {
  .container-boxed {
    width: 97%; }
  .noo-main {
    float: left;
    width: 66.66666666666666%; }
  .col-md-12.noo-main {
    width: inherit;
    float: none; }
  .noo-sidebar {
    float: left;
    width: 33.33333333333333%; } }

.row, .noo-pricing-table {
  margin-left: -1.0714285714285714em;
  margin-right: -1.0714285714285714em; }

.noo-vc-row {
  position: relative; }

.noo-vc-row.bg-image {
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat; }

.noo-vc-row.bg-image .noo-vc-col {
  position: relative;
  z-index: 1; }

.noo-vc-row.image-repeat {
  background-repeat: repeat; }

.noo-vc-row.bg-pattern {
  background-repeat: repeat; }

.noo-vc-row.bg-video {
  position: relative;
  overflow: hidden; }

.noo-vc-row.bg-video #big-video-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }

.noo-vc-row.bg-video #big-video-wrap .vjs-tech {
  left: 0;
  margin: 0; }

.noo-vc-row.border-top {
  border-top: 1px solid #dbdada; }

.noo-vc-row.border-left {
  border-left: 1px solid #dbdada; }

.noo-vc-row.border-right {
  border-right: 1px solid #dbdada; }

.noo-vc-row.border-bottom {
  border-bottom: 1px solid #dbdada; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 1.0714285714285714em;
  padding-right: 1.0714285714285714em; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-12 {
  width: 100%; }

.col-xs-11 {
  width: 91.66666666666666%; }

.col-xs-10 {
  width: 83.33333333333334%; }

.col-xs-9 {
  width: 75%; }

.col-xs-8 {
  width: 66.66666666666666%; }

.col-xs-7 {
  width: 58.333333333333336%; }

.col-xs-6 {
  width: 50%; }

.col-xs-5 {
  width: 41.66666666666667%; }

.col-xs-4 {
  width: 33.33333333333333%; }

.col-xs-3 {
  width: 25%; }

.col-xs-2 {
  width: 16.666666666666664%; }

.col-xs-1 {
  width: 8.333333333333332%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-pull-11 {
  right: 91.66666666666666%; }

.col-xs-pull-10 {
  right: 83.33333333333334%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-8 {
  right: 66.66666666666666%; }

.col-xs-pull-7 {
  right: 58.333333333333336%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-5 {
  right: 41.66666666666667%; }

.col-xs-pull-4 {
  right: 33.33333333333333%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-2 {
  right: 16.666666666666664%; }

.col-xs-pull-1 {
  right: 8.333333333333332%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-push-11 {
  left: 91.66666666666666%; }

.col-xs-push-10 {
  left: 83.33333333333334%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-8 {
  left: 66.66666666666666%; }

.col-xs-push-7 {
  left: 58.333333333333336%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-5 {
  left: 41.66666666666667%; }

.col-xs-push-4 {
  left: 33.33333333333333%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-2 {
  left: 16.666666666666664%; }

.col-xs-push-1 {
  left: 8.333333333333332%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-offset-12 {
  margin-left: 100%; }

.col-xs-offset-11 {
  margin-left: 91.66666666666666%; }

.col-xs-offset-10 {
  margin-left: 83.33333333333334%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-8 {
  margin-left: 66.66666666666666%; }

.col-xs-offset-7 {
  margin-left: 58.333333333333336%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-5 {
  margin-left: 41.66666666666667%; }

.col-xs-offset-4 {
  margin-left: 33.33333333333333%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-2 {
  margin-left: 16.666666666666664%; }

.col-xs-offset-1 {
  margin-left: 8.333333333333332%; }

.col-xs-offset-0 {
  margin-left: 0%; }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-11 {
    width: 91.66666666666666%; }
  .col-sm-10 {
    width: 83.33333333333334%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-8 {
    width: 66.66666666666666%; }
  .col-sm-7 {
    width: 58.333333333333336%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-5 {
    width: 41.66666666666667%; }
  .col-sm-4 {
    width: 33.33333333333333%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-2 {
    width: 16.666666666666664%; }
  .col-sm-1 {
    width: 8.333333333333332%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-pull-11 {
    right: 91.66666666666666%; }
  .col-sm-pull-10 {
    right: 83.33333333333334%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-8 {
    right: 66.66666666666666%; }
  .col-sm-pull-7 {
    right: 58.333333333333336%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-5 {
    right: 41.66666666666667%; }
  .col-sm-pull-4 {
    right: 33.33333333333333%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-2 {
    right: 16.666666666666664%; }
  .col-sm-pull-1 {
    right: 8.333333333333332%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-push-11 {
    left: 91.66666666666666%; }
  .col-sm-push-10 {
    left: 83.33333333333334%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-8 {
    left: 66.66666666666666%; }
  .col-sm-push-7 {
    left: 58.333333333333336%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-5 {
    left: 41.66666666666667%; }
  .col-sm-push-4 {
    left: 33.33333333333333%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-2 {
    left: 16.666666666666664%; }
  .col-sm-push-1 {
    left: 8.333333333333332%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-offset-12 {
    margin-left: 100%; }
  .col-sm-offset-11 {
    margin-left: 91.66666666666666%; }
  .col-sm-offset-10 {
    margin-left: 83.33333333333334%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-8 {
    margin-left: 66.66666666666666%; }
  .col-sm-offset-7 {
    margin-left: 58.333333333333336%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-5 {
    margin-left: 41.66666666666667%; }
  .col-sm-offset-4 {
    margin-left: 33.33333333333333%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-2 {
    margin-left: 16.666666666666664%; }
  .col-sm-offset-1 {
    margin-left: 8.333333333333332%; }
  .col-sm-offset-0 {
    margin-left: 0%; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-12 {
    width: 100%; }
  .col-md-11 {
    width: 91.66666666666666%; }
  .col-md-10 {
    width: 83.33333333333334%; }
  .col-md-9 {
    width: 75%; }
  .col-md-8 {
    width: 66.66666666666666%; }
  .col-md-7 {
    width: 58.333333333333336%; }
  .col-md-6 {
    width: 50%; }
  .col-md-5 {
    width: 41.66666666666667%; }
  .col-md-4 {
    width: 33.33333333333333%; }
  .col-md-3 {
    width: 25%; }
  .col-md-2 {
    width: 16.666666666666664%; }
  .col-md-1 {
    width: 8.333333333333332%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-pull-11 {
    right: 91.66666666666666%; }
  .col-md-pull-10 {
    right: 83.33333333333334%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-8 {
    right: 66.66666666666666%; }
  .col-md-pull-7 {
    right: 58.333333333333336%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-5 {
    right: 41.66666666666667%; }
  .col-md-pull-4 {
    right: 33.33333333333333%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-2 {
    right: 16.666666666666664%; }
  .col-md-pull-1 {
    right: 8.333333333333332%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-push-11 {
    left: 91.66666666666666%; }
  .col-md-push-10 {
    left: 83.33333333333334%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-8 {
    left: 66.66666666666666%; }
  .col-md-push-7 {
    left: 58.333333333333336%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-5 {
    left: 41.66666666666667%; }
  .col-md-push-4 {
    left: 33.33333333333333%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-2 {
    left: 16.666666666666664%; }
  .col-md-push-1 {
    left: 8.333333333333332%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-offset-12 {
    margin-left: 100%; }
  .col-md-offset-11 {
    margin-left: 91.66666666666666%; }
  .col-md-offset-10 {
    margin-left: 83.33333333333334%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-8 {
    margin-left: 66.66666666666666%; }
  .col-md-offset-7 {
    margin-left: 58.333333333333336%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-5 {
    margin-left: 41.66666666666667%; }
  .col-md-offset-4 {
    margin-left: 33.33333333333333%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-2 {
    margin-left: 16.666666666666664%; }
  .col-md-offset-1 {
    margin-left: 8.333333333333332%; }
  .col-md-offset-0 {
    margin-left: 0%; } }

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-11 {
    width: 91.66666666666666%; }
  .col-lg-10 {
    width: 83.33333333333334%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-8 {
    width: 66.66666666666666%; }
  .col-lg-7 {
    width: 58.333333333333336%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-5 {
    width: 41.66666666666667%; }
  .col-lg-4 {
    width: 33.33333333333333%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-2 {
    width: 16.666666666666664%; }
  .col-lg-1 {
    width: 8.333333333333332%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-pull-11 {
    right: 91.66666666666666%; }
  .col-lg-pull-10 {
    right: 83.33333333333334%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-8 {
    right: 66.66666666666666%; }
  .col-lg-pull-7 {
    right: 58.333333333333336%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-5 {
    right: 41.66666666666667%; }
  .col-lg-pull-4 {
    right: 33.33333333333333%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-2 {
    right: 16.666666666666664%; }
  .col-lg-pull-1 {
    right: 8.333333333333332%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-push-11 {
    left: 91.66666666666666%; }
  .col-lg-push-10 {
    left: 83.33333333333334%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-8 {
    left: 66.66666666666666%; }
  .col-lg-push-7 {
    left: 58.333333333333336%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-5 {
    left: 41.66666666666667%; }
  .col-lg-push-4 {
    left: 33.33333333333333%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-2 {
    left: 16.666666666666664%; }
  .col-lg-push-1 {
    left: 8.333333333333332%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-offset-12 {
    margin-left: 100%; }
  .col-lg-offset-11 {
    margin-left: 91.66666666666666%; }
  .col-lg-offset-10 {
    margin-left: 83.33333333333334%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-8 {
    margin-left: 66.66666666666666%; }
  .col-lg-offset-7 {
    margin-left: 58.333333333333336%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-5 {
    margin-left: 41.66666666666667%; }
  .col-lg-offset-4 {
    margin-left: 33.33333333333333%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-2 {
    margin-left: 16.666666666666664%; }
  .col-lg-offset-1 {
    margin-left: 8.333333333333332%; }
  .col-lg-offset-0 {
    margin-left: 0%; } }

.clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .container-boxed:before, .container-boxed:after, .container-fullwidth:before, .container-fullwidth:after, .row:before, .row:after, .panel-body:before, .panel-body:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .navbar:before, .navbar:after, .navbar-header:before, .navbar-header:after, .navbar-collapse:before, .navbar-collapse:after, .sf-menu:before, .sf-menu:after, .noo-menu:before, .noo-menu:after, .modal-footer:before, .modal-footer:after, .nav:before, .nav:after, .content:before, .content:after, .content-excerpt:before, .content-excerpt:after, #author-bio:before, #author-bio:after, .widget_tag_cloud .tagcloud:before, .widget_tag_cloud .tagcloud:after, .widget_product_tag_cloud .tagcloud:before, .widget_product_tag_cloud .tagcloud:after, .comments-area > .comment-respond .comment-form-fields:before, .comments-area > .comment-respond .comment-form-fields:after, .masonry-header:before, .masonry-header:after, .masonry-style-vibrant .masonry-portfolio .masonry-container .content-wrap .portfolio-action .content-share-portfolio .noo-social:before, .masonry-style-vibrant .masonry-portfolio .masonry-container .content-wrap .portfolio-action .content-share-portfolio .noo-social:after, .noo-pricing-table:before, .noo-pricing-table:after {
  content: " ";
  display: table; }

.clearfix:after, .dl-horizontal dd:after, .container:after, .container-fluid:after, .container-boxed:after, .container-fullwidth:after, .row:after, .panel-body:after, .form-horizontal .form-group:after, .navbar:after, .navbar-header:after, .navbar-collapse:after, .sf-menu:after, .noo-menu:after, .modal-footer:after, .nav:after, .content:after, .content-excerpt:after, #author-bio:after, .widget_tag_cloud .tagcloud:after, .widget_product_tag_cloud .tagcloud:after, .comments-area > .comment-respond .comment-form-fields:after, .masonry-header:after, .masonry-style-vibrant .masonry-portfolio .masonry-container .content-wrap .portfolio-action .content-share-portfolio .noo-social:after, .noo-pricing-table:after {
  clear: both; }

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

.text-bold {
  font-weight: bold !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.hidden {
  display: none !important;
  visibility: hidden !important; }

.affix {
  position: fixed;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20); }

.close:hover, .close:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50); }

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }

.fade.in {
  opacity: 1; }

.collapse {
  display: none; }

.collapse.in {
  display: block; }

tr.collapse.in {
  display: table-row; }

tbody.collapse.in {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease; }

@-ms-viewport {
  width: device-width; }

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table; }
  tr.visible-print {
    display: table-row !important; }
  th.visible-print,
  td.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }

@media print {
  .visible-print-block {
    display: block !important; } }

.visible-print-inline {
  display: none !important; }

@media print {
  .visible-print-inline {
    display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }

@media print {
  .visible-print-inline-block {
    display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

/**
 * 3.0 - Structure
 *  3.1 - panels
 */
.panel {
  margin-bottom: 1.6428571428571428em;
  background-color: #ffffff;
  border: 1px solid transparent;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }

.panel-body {
  padding: 15px; }

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px; }

.panel-heading > .dropdown .dropdown-toggle {
  color: inherit; }

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.125rem;
  color: inherit; }

.panel-title > a {
  color: inherit; }

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #dddddd;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px; }

.panel > .list-group {
  margin-bottom: 0; }

.panel > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0; }

.panel > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px; }

.panel > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px; }

.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0; }

.list-group + .panel-footer {
  border-top-width: 0; }

.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
  margin-bottom: 0; }

.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px; }

.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-left-radius: 2px; }

.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: 2px; }

.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px; }

.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 2px; }

.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 2px; }

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive {
  border-top: 1px solid #dbdada; }

.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0; }

.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0; }

.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0; }

.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0; }

.panel > .table-bordered > thead > tr:first-child > td,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel > .table-bordered > tbody > tr:first-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel > .table-bordered > thead > tr:first-child > th,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
.panel > .table-bordered > tbody > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
  border-bottom: 0; }

.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0; }

.panel > .table-responsive {
  border: 0;
  margin-bottom: 0; }

.panel-group {
  margin-bottom: 1.6428571428571428em; }

.panel-group .panel {
  margin-bottom: 0;
  border-radius: 3px; }

.panel-group .panel + .panel {
  margin-top: 5px; }

.panel-group .panel-heading {
  border-bottom: 0; }

.panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: 1px solid #dddddd; }

.panel-group .panel-footer {
  border-top: 0; }

.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #dddddd; }

.panel-default {
  border-color: #dddddd; }

.panel-default > .panel-heading {
  color: #333333;
  background-color: #f5f5f5;
  border-color: #dddddd; }

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #dddddd; }

.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #333333; }

.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #dddddd; }

.panel-primary {
  border-color: #fe6367; }

.panel-primary > .panel-heading {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367; }

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #fe6367; }

.panel-primary > .panel-heading .badge {
  color: #fe6367;
  background-color: #ffffff; }

.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #fe6367; }

.panel-success {
  border-color: #d6e9c6; }

.panel-success > .panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6; }

.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d6e9c6; }

.panel-success > .panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d; }

.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d6e9c6; }

.panel-info {
  border-color: #bce8f1; }

.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1; }

.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bce8f1; }

.panel-info > .panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f; }

.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bce8f1; }

.panel-warning {
  border-color: #faebcc; }

.panel-warning > .panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc; }

.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #faebcc; }

.panel-warning > .panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b; }

.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #faebcc; }

.panel-danger {
  border-color: #ebccd1; }

.panel-danger > .panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1; }

.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ebccd1; }

.panel-danger > .panel-heading .badge {
  color: #f2dede;
  background-color: #a94442; }

.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ebccd1; }

/**
 * 3.0 - Structure
 *  3.2 - 
 */
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }

.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15); }

.well-lg {
  padding: 24px;
  border-radius: 3px; }

.well-sm {
  padding: 9px;
  border-radius: 3px; }

/**
 * 4.0 - Basic Element
 *  4.1 - tables
 */
table {
  background-color: transparent; }

th {
  text-align: left; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1.6428571428571428em; }

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 0.5714285714285714em;
  line-height: 1.7;
  vertical-align: top;
  border-top: 1px solid #dbdada; }

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #dbdada; }

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0; }

.table > tbody + tbody {
  border-top: 2px solid #dbdada; }

.table .table {
  background-color: transparent; }

.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.35714285714285715em; }

.table-bordered {
  border: 1px solid #dbdada; }

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #dbdada; }

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px; }

.table-striped {
  border: 1px solid #dbdada; }

.table-striped > thead {
  background-color: #5f5f5f; }

.table-striped > thead tr > th {
  border: 1px solid #5f5f5f !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: #fff; }

.table-striped > thead tr > th:hover {
  background-color: #6e6e6e; }

.table-striped > thead > tr > th,
.table-striped > tbody > tr > th,
.table-striped > tfoot > tr > th,
.table-striped > thead > tr > td,
.table-striped > tbody > tr > td,
.table-striped > tfoot > tr > td {
  text-align: center;
  border: 1px solid #dbdada; }

.table-striped > thead > tr > th,
.table-striped > thead > tr > td {
  border-bottom-width: 1px; }

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f5f5f5; }

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: #fff; }

.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: #f5f5f5; }

table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column; }

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell; }

.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5; }

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8; }

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #dff0d8; }

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6; }

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #d9edf7; }

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3; }

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3; }

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc; }

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #f2dede; }

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc; }

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 1.2321428571428572em;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #dbdada;
    -webkit-overflow-scrolling: touch; }
  .table-responsive > .table {
    margin-bottom: 0; }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap; }
  .table-responsive > .table-bordered {
    border: 0; }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0; }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0; }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0; } }

/**
 * 4.0 - Basic Element
 *  4.2 - forms
 */
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0; }

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 1.6428571428571428em;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5; }

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 300; }

input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

input[type="radio"],
input[type="checkbox"] {
  margin: 0.45em 0 0;
  margin-top: 1px \9;
  line-height: normal; }

input[type="file"] {
  display: block; }

input[type="range"] {
  display: block;
  width: 100%; }

select[multiple],
select[size] {
  height: auto; }

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

output {
  display: block;
  padding-top: 1.4285714285714286em;
  font-size: 14px;
  line-height: 1.7;
  color: #5f5f5f; }

.form-control,
.widget_newsletterwidget .newsletter-email,
.wpcf7-form-control:not(.form-submit) {
  display: block;
  width: 100%;
  padding: 0.4285714286em 1.2857142857em;
  font-size: 14px;
  line-height: 1.7;
  color: #5f5f5f;
  background-color: transparent;
  background-image: none;
  border: 1px solid #dbdada;
  border-radius: 0;
  letter-spacing: 0.5px;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }

.form-control:focus,
.widget_newsletterwidget .newsletter-email:focus,
.wpcf7-form-control:not(.form-submit):focus {
  border-color: #797979;
  outline: 0; }

.form-control::-moz-placeholder,
.widget_newsletterwidget .newsletter-email::-moz-placeholder,
.wpcf7-form-control:not(.form-submit)::-moz-placeholder {
  color: #8c8c8c;
  opacity: 1; }

.form-control:-ms-input-placeholder,
.widget_newsletterwidget .newsletter-email:-ms-input-placeholder,
.wpcf7-form-control:not(.form-submit):-ms-input-placeholder {
  color: #8c8c8c; }

.form-control::-webkit-input-placeholder,
.widget_newsletterwidget .newsletter-email::-webkit-input-placeholder,
.wpcf7-form-control:not(.form-submit)::-webkit-input-placeholder {
  color: #8c8c8c; }

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control,
.widget_newsletterwidget .newsletter-email[disabled],
.widget_newsletterwidget .newsletter-email[readonly],
fieldset[disabled] .widget_newsletterwidget .newsletter-email,
.wpcf7-form-control:not(.form-submit)[disabled],
.wpcf7-form-control:not(.form-submit)[readonly],
fieldset[disabled] .wpcf7-form-control:not(.form-submit) {
  cursor: not-allowed;
  background-color: #f4f4f4;
  opacity: 1; }

textarea.form-control,
textarea.widget_newsletterwidget .newsletter-email,
textarea.wpcf7-form-control:not(.form-submit) {
  height: auto; }

input[type="search"] {
  -webkit-appearance: none; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  line-height: 2.9285714285714284em;
  line-height: 1.7 \0; }

input[type="date"].input-sm,
input[type="time"].input-sm,
input[type="datetime-local"].input-sm,
input[type="month"].input-sm {
  line-height: 2.7142857142857144em; }

input[type="date"].input-lg,
input[type="time"].input-lg,
input[type="datetime-local"].input-lg,
input[type="month"].input-lg {
  line-height: 5.428571428571429em; }

.form-group {
  margin-bottom: 15px;
  position: relative; }

.radio,
.checkbox {
  position: relative;
  display: block;
  min-height: 1.6428571428571428em;
  margin-top: 10px;
  margin-bottom: 10px; }

.radio label,
.checkbox label {
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer; }

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9; }

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; }

.radio-inline,
.checkbox-inline {
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer; }

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; }

input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled,
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed; }

.radio-inline.disabled,
.checkbox-inline.disabled,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox-inline {
  cursor: not-allowed; }

.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed; }

.form-control-static {
  padding-top: 1.4285714285714286em;
  padding-bottom: 1.4285714285714286em;
  margin-bottom: 0; }

.form-control-static.input-lg,
.form-control-static.input-sm {
  padding-left: 0;
  padding-right: 0; }

.input-sm,
.form-horizontal .form-group-sm .form-control,
.form-horizontal .form-group-sm .widget_newsletterwidget .newsletter-email,
.form-horizontal .form-group-sm .wpcf7-form-control:not(.form-submit) {
  height: 2.7142857142857144em;
  padding: 0.3571428571em 0.7142857143em;
  font-size: 0.8571428571428571em;
  line-height: 1.5;
  border-radius: 3px; }

select.input-sm {
  height: 2.7142857142857144em;
  line-height: 2.7142857142857144em; }

textarea.input-sm,
select[multiple].input-sm {
  height: auto; }

.input-lg,
.form-horizontal .form-group-lg .form-control,
.form-horizontal .form-group-lg .widget_newsletterwidget .newsletter-email,
.form-horizontal .form-group-lg .wpcf7-form-control:not(.form-submit) {
  height: 5.428571428571429em;
  padding: 0.7142857143em 1.1428571429em;
  font-size: 1.2857142857142858em;
  line-height: 2;
  border-radius: 3px; }

select.input-lg {
  height: 5.428571428571429em;
  line-height: 5.428571428571429em; }

textarea.input-lg,
select[multiple].input-lg {
  height: auto; }

.has-feedback {
  position: relative; }

.has-feedback .form-control,
.has-feedback .widget_newsletterwidget .newsletter-email,
.has-feedback .wpcf7-form-control:not(.form-submit) {
  padding-right: 3.6607142857142856em; }

.form-control-feedback {
  position: absolute;
  top: 6.642857142857142em;
  right: 0;
  z-index: 2;
  display: block;
  width: 2.9285714285714284em;
  height: 2.9285714285714284em;
  line-height: 2.9285714285714284em;
  text-align: center; }

.input-lg + .form-control-feedback {
  width: 5.428571428571429em;
  height: 5.428571428571429em;
  line-height: 5.428571428571429em; }

.input-sm + .form-control-feedback {
  width: 2.7142857142857144em;
  height: 2.7142857142857144em;
  line-height: 2.7142857142857144em; }

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
  color: #3c763d; }

.has-success .form-control,
.has-success .widget_newsletterwidget .newsletter-email,
.has-success .wpcf7-form-control:not(.form-submit) {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-success .form-control:focus,
.has-success .widget_newsletterwidget .newsletter-email:focus,
.has-success .wpcf7-form-control:not(.form-submit):focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168; }

.has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8; }

.has-success .form-control-feedback {
  color: #3c763d; }

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #8a6d3b; }

.has-warning .form-control,
.has-warning .widget_newsletterwidget .newsletter-email,
.has-warning .wpcf7-form-control:not(.form-submit) {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-warning .form-control:focus,
.has-warning .widget_newsletterwidget .newsletter-email:focus,
.has-warning .wpcf7-form-control:not(.form-submit):focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b; }

.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #8a6d3b;
  background-color: #fcf8e3; }

.has-warning .form-control-feedback {
  color: #8a6d3b; }

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #a94442; }

.has-error .form-control,
.has-error .widget_newsletterwidget .newsletter-email,
.has-error .wpcf7-form-control:not(.form-submit) {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-error .form-control:focus,
.has-error .widget_newsletterwidget .newsletter-email:focus,
.has-error .wpcf7-form-control:not(.form-submit):focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; }

.has-error .input-group-addon {
  color: #a94442;
  border-color: #a94442;
  background-color: #f2dede; }

.has-error .form-control-feedback {
  color: #a94442; }

.has-feedback label.sr-only ~ .form-control-feedback,
.has-feedback label.jp-controls a span ~ .form-control-feedback {
  top: 0; }

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #9f9f9f; }

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .form-control,
  .form-inline .widget_newsletterwidget .newsletter-email,
  .form-inline .wpcf7-form-control:not(.form-submit) {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle; }
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control,
  .form-inline .input-group .widget_newsletterwidget .newsletter-email,
  .form-inline .input-group .wpcf7-form-control:not(.form-submit) {
    width: auto; }
  .form-inline .input-group > .form-control,
  .form-inline .input-group > .widget_newsletterwidget .newsletter-email,
  .form-inline .input-group > .wpcf7-form-control:not(.form-submit) {
    width: 100%; }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .radio label,
  .form-inline .checkbox label {
    padding-left: 0; }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0; }
  .form-inline .has-feedback .form-control-feedback {
    top: 0; } }

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1.4285714285714286em; }

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 3.071428571428571em; }

.form-horizontal .form-group {
  margin-left: -1.0714285714285714em;
  margin-right: -1.0714285714285714em; }

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 0.7142857142857143em; } }

.form-horizontal .has-feedback .form-control-feedback {
  top: 0;
  right: 1.0714285714285714em; }

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 2.428571428571429em; } }

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 1.3571428571428572em; } }

.form-group .dropdown > span {
  height: 2.9285714285714284em;
  line-height: 2.9285714285714284em;
  padding: 0 20px;
  display: block;
  cursor: pointer;
  border: 1px solid #dbdada;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  overflow: hidden;
  position: relative; }

.form-group .dropdown > span:before {
  content: "\f107";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 5px;
  opacity: 0.5;
  filter: alpha(opacity=50); }

.form-group .dropdown > span:before {
  border-left: 1px solid #dbdada;
  display: block;
  height: 2.9285714285714284em;
  line-height: 2.9285714285714284em;
  position: absolute;
  right: 0;
  text-align: center;
  width: 2.9285714285714284em; }

.form-group .dropdown .dropdown-menu {
  width: 100%;
  border: 1px solid #dbdada;
  padding: 0;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.175);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.175);
  background: #fff; }

.form-group .dropdown .dropdown-menu > li {
  border-bottom: 1px solid #dbdada;
  padding: 0;
  margin: 0; }

.form-group .dropdown .dropdown-menu > li:last-child {
  border: none; }

.form-group .dropdown .dropdown-menu > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: inherit; }

.form-group .dropdown .dropdown-menu > li > a:focus,
.form-group .dropdown .dropdown-menu > li > a:hover {
  background-color: #fe6367;
  color: #ffffff; }

input[type="checkbox"] {
  border: 0;
  background: #f4f4f4;
  color: #555;
  clear: none;
  cursor: pointer;
  display: inline-block;
  line-height: 0;
  height: 20px;
  margin: -4px 4px 0 0;
  outline: 0;
  padding: 0 !important;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  -webkit-appearance: none;
  -webkit-transition: 0.05s border-color ease-in-out;
  transition: 0.05s border-color ease-in-out;
  position: relative; }

input[type="checkbox"]:checked:before {
  content: "\f00c";
  color: #fe6367;
  position: absolute;
  float: left;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  font-family: "fontawesome";
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  left: 3px;
  top: 10px;
  font-size: 12px; }

input[type="radio"] {
  border: 1px solid #bbb;
  background: #fff;
  color: #555;
  clear: none;
  cursor: pointer;
  display: inline-block;
  line-height: 0;
  height: 16px;
  margin: 0 5px 0 0;
  outline: 0;
  padding: 0 !important;
  text-align: center;
  vertical-align: middle;
  width: 16px;
  min-width: 16px;
  -webkit-appearance: none;
  -webkit-transition: 0.05s border-color ease-in-out;
  transition: 0.05s border-color ease-in-out;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: relative; }

input[type="radio"]:checked:before {
  content: "\2022";
  text-indent: -9999px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  font-size: 24px;
  width: 6px;
  height: 6px;
  margin: 4px;
  line-height: 16px;
  background: #fe6367;
  float: left;
  display: inline-block;
  vertical-align: middle;
  font: normal 21px/1 "dashicons";
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/**
 * 4.0 - Basic Element
 *  4.3 - buttons
 */
.btn,
.widget_newsletterwidget .newsletter-submit {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 0px solid transparent;
  white-space: nowrap;
  background: #e7e7e7;
  text-transform: uppercase;
  padding: 0.7142857143em 3em;
  font-size: 14px;
  line-height: 1.7;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: border-color color 0.2s ease;
  -o-transition: border-color color 0.2s ease;
  transition: border-color color 0.2s ease; }

.btn:hover,
.btn:focus,
.widget_newsletterwidget .newsletter-submit:hover,
.widget_newsletterwidget .newsletter-submit:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.widget_newsletterwidget .newsletter-submit:focus,
.widget_newsletterwidget .newsletter-submit:active:focus,
.widget_newsletterwidget .newsletter-submit.active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.btn:hover,
.btn:focus,
.widget_newsletterwidget .newsletter-submit:hover,
.widget_newsletterwidget .newsletter-submit:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.btn:active,
.btn.active,
.widget_newsletterwidget .newsletter-submit:active,
.widget_newsletterwidget .newsletter-submit.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn,
.widget_newsletterwidget .newsletter-submit.disabled,
.widget_newsletterwidget .newsletter-submit[disabled],
fieldset[disabled] .widget_newsletterwidget .newsletter-submit {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn.square,
.widget_newsletterwidget .newsletter-submit.square {
  border-radius: 0; }

.btn.pill,
.widget_newsletterwidget .newsletter-submit.pill {
  border-radius: 100em; }

.btn.rounded,
.widget_newsletterwidget .newsletter-submit.rounded {
  border-radius: 0.225em; }

.btn i,
.widget_newsletterwidget .newsletter-submit i {
  padding-right: 10px; }

.btn.icon-right i,
.widget_newsletterwidget .newsletter-submit.icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.pressable,
.widget_newsletterwidget .newsletter-submit {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 -2px rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 -2px rgba(0, 0, 0, 0.12);
  border-color: rgba(255, 255, 255, 0.1);
  -webkit-transition: box-shadow 0.1s ease-in-out, background-color 0.2s ease-in-out, transform 0.1s ease-in-out;
  -o-transition: box-shadow 0.1s ease-in-out, background-color 0.2s ease-in-out, transform 0.1s ease-in-out;
  transition: box-shadow 0.1s ease-in-out, background-color 0.2s ease-in-out, transform 0.1s ease-in-out; }

.pressable:active,
.widget_newsletterwidget .newsletter-submit:active {
  outline: none;
  -webkit-box-shadow: inset rgba(255, 255, 255, 0.6) 0 0.3em 0.3em, inset rgba(0, 0, 0, 0.2) 0 -0.1em 0.3em, rgba(0, 0, 0, 0.4) 0 0.1em 1px, rgba(0, 0, 0, 0.2) 0 0.2em 6px;
  box-shadow: inset rgba(255, 255, 255, 0.6) 0 0.3em 0.3em, inset rgba(0, 0, 0, 0.2) 0 -0.1em 0.3em, rgba(0, 0, 0, 0.4) 0 0.1em 1px, rgba(0, 0, 0, 0.2) 0 0.2em 6px;
  -webkit-transform: translate(0, 0.2em);
  -ms-transform: translate(0, 0.2em);
  -o-transform: translate(0, 0.2em);
  transform: translate(0, 0.2em); }

.btn-link {
  color: #5f5f5f;
  font-weight: normal;
  cursor: pointer;
  border-radius: 0; }

.btn-link,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-color: transparent; }

.btn-link:hover,
.btn-link:focus {
  color: #fe6367;
  text-decoration: none;
  background-color: transparent; }

.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
  color: #5f5f5f;
  text-decoration: none; }

.btn-lg {
  padding: 0.7142857143em 1.1428571429em;
  font-size: 1.2857142857142858em;
  line-height: 2;
  border-radius: 3px; }

.btn-sm {
  padding: 0.3571428571em 0.7142857143em;
  font-size: 0.8571428571428571em;
  line-height: 1.5;
  border-radius: 3px; }

.btn-xs {
  padding: 0.0714285714em 0.3571428571em;
  font-size: 0.8571428571428571em;
  line-height: 1.5;
  border-radius: 3px; }

.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0; }

.btn-block + .btn-block {
  margin-top: 5px; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 0px solid transparent;
  white-space: nowrap;
  background: #e7e7e7;
  text-transform: uppercase;
  padding: 0.7142857143em 3em;
  font-size: 14px;
  line-height: 1.7;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: border-color color 0.2s ease;
  -o-transition: border-color color 0.2s ease;
  transition: border-color color 0.2s ease; }

.button:hover,
.button:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.button:hover,
.button:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.button:focus,
.button:active:focus,
.button.active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.button:hover,
.button:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.button:active,
.button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.button.disabled,
.button[disabled],
fieldset[disabled] .button {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.button.square {
  border-radius: 0; }

.button.pill {
  border-radius: 100em; }

.button.rounded {
  border-radius: 0.225em; }

.button i {
  padding-right: 10px; }

.button.icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.btn-default {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 0px solid transparent;
  white-space: nowrap;
  background: #e7e7e7;
  text-transform: uppercase;
  padding: 0.7142857143em 3em;
  font-size: 14px;
  line-height: 1.7;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: border-color color 0.2s ease;
  -o-transition: border-color color 0.2s ease;
  transition: border-color color 0.2s ease;
  color: #5f5f5f;
  background-color: #e7e7e7;
  border-color: 0; }

.btn-default:hover,
.btn-default:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.btn-default:hover,
.btn-default:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.btn-default:focus,
.btn-default:active:focus,
.btn-default.active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.btn-default:hover,
.btn-default:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.btn-default:active,
.btn-default.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn-default.square {
  border-radius: 0; }

.btn-default.pill {
  border-radius: 100em; }

.btn-default.rounded {
  border-radius: 0.225em; }

.btn-default i {
  padding-right: 10px; }

.btn-default.icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn-primary,
.widget_newsletterwidget .newsletter-submit {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 0px solid transparent;
  white-space: nowrap;
  background: #e7e7e7;
  text-transform: uppercase;
  padding: 0.7142857143em 3em;
  font-size: 14px;
  line-height: 1.7;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: border-color color 0.2s ease;
  -o-transition: border-color color 0.2s ease;
  transition: border-color color 0.2s ease;
  color: #ffffff;
  background-color: #fe6367;
  border-color: 0; }

.btn-primary:hover,
.btn-primary:focus,
.widget_newsletterwidget .newsletter-submit:hover,
.widget_newsletterwidget .newsletter-submit:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.btn-primary:hover,
.btn-primary:focus,
.widget_newsletterwidget .newsletter-submit:hover,
.widget_newsletterwidget .newsletter-submit:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.widget_newsletterwidget .newsletter-submit:focus,
.widget_newsletterwidget .newsletter-submit:active:focus,
.widget_newsletterwidget .newsletter-submit.active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.btn-primary:hover,
.btn-primary:focus,
.widget_newsletterwidget .newsletter-submit:hover,
.widget_newsletterwidget .newsletter-submit:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.btn-primary:active,
.btn-primary.active,
.widget_newsletterwidget .newsletter-submit:active,
.widget_newsletterwidget .newsletter-submit.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.widget_newsletterwidget .newsletter-submit.disabled,
.widget_newsletterwidget .newsletter-submit[disabled],
fieldset[disabled] .widget_newsletterwidget .newsletter-submit {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn-primary.square,
.widget_newsletterwidget .newsletter-submit.square {
  border-radius: 0; }

.btn-primary.pill,
.widget_newsletterwidget .newsletter-submit.pill {
  border-radius: 100em; }

.btn-primary.rounded,
.widget_newsletterwidget .newsletter-submit.rounded {
  border-radius: 0.225em; }

.btn-primary i,
.widget_newsletterwidget .newsletter-submit i {
  padding-right: 10px; }

.btn-primary.icon-right i,
.widget_newsletterwidget .newsletter-submit.icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary,
.widget_newsletterwidget .newsletter-submit:hover,
.widget_newsletterwidget .newsletter-submit:focus,
.widget_newsletterwidget .newsletter-submit:active,
.widget_newsletterwidget .newsletter-submit.active,
.open > .dropdown-toggle.widget_newsletterwidget .newsletter-submit {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn-primary:hover,
.btn-primary:focus,
.widget_newsletterwidget .newsletter-submit:hover,
.widget_newsletterwidget .newsletter-submit:focus {
  outline: 0;
  background: #5f5f5f; }

.btn-success {
  color: #ffffff;
  background-color: #5cb85c;
  border-color: #4cae4c; }

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn-info {
  color: #ffffff;
  background-color: #5bc0de;
  border-color: #46b8da; }

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn-warning {
  color: #ffffff;
  background-color: #f0ad4e;
  border-color: #eea236; }

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn-danger {
  color: #ffffff;
  background-color: #d9534f;
  border-color: #d43f3a; }

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn-darker {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 0px solid transparent;
  white-space: nowrap;
  background: #e7e7e7;
  text-transform: uppercase;
  padding: 0.7142857143em 3em;
  font-size: 14px;
  line-height: 1.7;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: border-color color 0.2s ease;
  -o-transition: border-color color 0.2s ease;
  transition: border-color color 0.2s ease;
  background: #454545; }

.btn-darker:hover,
.btn-darker:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.btn-darker:hover,
.btn-darker:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.btn-darker:focus,
.btn-darker:active:focus,
.btn-darker.active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.btn-darker:hover,
.btn-darker:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.btn-darker:active,
.btn-darker.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.btn-darker.disabled,
.btn-darker[disabled],
fieldset[disabled] .btn-darker {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn-darker.square {
  border-radius: 0; }

.btn-darker.pill {
  border-radius: 100em; }

.btn-darker.rounded {
  border-radius: 0.225em; }

.btn-darker i {
  padding-right: 10px; }

.btn-darker.icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.btn-darker:hover,
.btn-darker:focus {
  outline: 0;
  background: #fe6367; }

/**
 * 4.0 - Basic Element
 *  4.4 - thumbnails
 */
.thumbnail {
  display: block;
  padding: 0.2857142857142857em;
  margin-bottom: 1.6428571428571428em;
  line-height: 1.7;
  background-color: transparent;
  border: 1px solid #dddddd;
  border-radius: 3px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.thumbnail > img,
.thumbnail a > img {
  margin-left: auto;
  margin-right: auto; }

a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: #5f5f5f; }

.thumbnail .caption {
  padding: 0.6428571428571429em;
  color: #5f5f5f; }

/**
 * 4.0 - Basic Element
 *  4.5 - dropdowns
 */
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.dropdown {
  position: relative; }

.dropdown-toggle:focus {
  outline: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box; }

.dropdown-menu.pull-right {
  right: 0;
  left: auto; }

.dropdown-menu .divider {
  height: 1px;
  margin: -0.1785714285714286em 0;
  overflow: hidden;
  background-color: #e5e5e5; }

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.7;
  color: #333333;
  white-space: nowrap; }

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5; }

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  background-color: #fe6367; }

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #5f5f5f; }

.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed; }

.open > .dropdown-menu {
  display: block; }

.open > a {
  outline: 0; }

.dropdown-menu-right {
  left: auto;
  right: 0; }

.dropdown-menu-left {
  left: 0;
  right: auto; }

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 0.8571428571428571em;
  line-height: 1.7;
  color: #5f5f5f;
  white-space: nowrap; }

.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990; }

.pull-right > .dropdown-menu {
  right: 0;
  left: auto; }

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid;
  content: ""; }

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px; }

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    left: auto;
    right: 0; }
  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto; } }

/**
 * 4.0 - Basic Element
 *  4.6 - navbar
 */
.navbar {
  position: relative;
  height: 75px;
  z-index: 1000;
  background-color: #2a2a2a; }

.navbar .container,
.navbar .container-fluid,
.navbar .container-boxed {
  position: relative; }

@media (min-width: 992px) {
  .navbar-header {
    float: left; } }

.navbar-collapse {
  float: right; }

@media (max-width: 991px) {
  .navbar-collapse {
    float: none;
    clear: both; } }

@media (min-width: 992px) {
  .navbar-collapse {
    width: auto; }
  .navbar-collapse.collapse:not(.noo-user-navbar-collapse) {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important; } }

.fixed-top {
  width: 100%;
  -webkit-transition: background-color 0.4s ease 0s;
  -o-transition: background-color 0.4s ease 0s;
  transition: background-color 0.4s ease 0s; }

@media (min-width: 992px) {
  .navbar-fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 0 1px #f4f4f4;
    box-shadow: 0 0 0 1px #f4f4f4; }
  .navbar-fixed-top.bg-transparent {
    background-color: #ffffff; } }

.navbar-fixed-top .navbar-brand {
  background: #fe6367; }

body.boxed-layout .navbar-fixed-top {
  margin-right: auto;
  margin-left: auto;
  width: 90%;
  max-width: 1200px; }

body.nice-scroll:not(.boxed-layout) .navbar-fixed-top {
  padding-right: 10px; }

body.boxed-layout.nice-scroll .navbar-fixed-top {
  width: 90%;
  width: -webkit-calc( 90% - 10px);
  /** Safari 6, Chrome 19-25 **/
  width: -moz-calc( 90% - 10px);
  /** FF 4-15  **/
  width: calc( 90% - 10px);
  /** FF 16+, IE 9+, Opera 15, Chrome 26+, Safari 7 and future other browsers **/
  left: -10px; }

.navbar-fixed-top.navbar {
  height: 60px; }

.navbar-fixed-top {
  top: 0; }

@media (min-width: 992px) {
  .navbar-fixed-top.navbar-shrink {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .navbar-fixed-top.navbar-shrink .navbar-nav > li > a {
    color: #5f5f5f; }
  .navbar-fixed-top.navbar-shrink .navbar-nav > li > a:hover {
    color: #fe6367; }
  .navbar-fixed-top.navbar-shrink .navbar-nav > li.current-menu-item > a,
  .navbar-fixed-top.navbar-shrink .navbar-nav > li.current-menu-parent > a,
  .navbar-fixed-top.navbar-shrink .navbar-nav > li.active > a {
    color: #fe6367; }
  .navbar-fixed-top.navbar-shrink .navbar-brand .noo-logo-img,
  .navbar-fixed-top.navbar-shrink .navbar-brand .noo-logo-retina-img {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    position: relative;
    vertical-align: top; } }

.navbar-brand {
  float: left;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 30px;
  height: 75px;
  line-height: 75px;
  padding: 0 35px; }

.navbar-brand,
.navbar-brand:hover {
  color: #ffffff; }

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none; }

.navbar-brand img {
  max-height: 75px;
  vertical-align: baseline; }

.navbar-brand .noo-logo-img,
.navbar-brand .noo-logo-retina-img {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  position: relative;
  vertical-align: middle; }

.navbar-brand .noo-logo-img {
  display: block; }

.navbar-brand .noo-logo-retina-img,
.navbar-brand .noo-logo-floating {
  display: none; }

.navbar-fixed-top .navbar-brand .noo-logo-img,
.navbar-fixed-top .navbar-brand .noo-logo-retina-img {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  position: relative;
  vertical-align: baseline; }

/* retina logo */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .navbar-brand .noo-logo-img {
    display: none; }
  .navbar-brand .noo-logo-retina-img {
    display: block; } }

.navbar-toggle {
  position: relative;
  float: right;
  font-size: 25px;
  height: 75px;
  display: table;
  padding: 0 15px;
  border-radius: 3px;
  background-image: none;
  cursor: pointer; }

.navbar-toggle i {
  display: table-cell;
  vertical-align: middle; }

.navbar-toggle:hover {
  text-decoration: none; }

@media (min-width: 992px) {
  .navbar-toggle {
    display: none; } }

.sf-menu,
.noo-menu,
.sf-menu *,
.noo-menu * {
  margin: 0;
  padding: 0;
  list-style: none; }

.sf-menu a,
.noo-menu a {
  display: block;
  position: relative;
  text-decoration: none; }

.sf-menu > li,
.noo-menu > li {
  display: inline-block; }

@media (max-width: 991px) {
  .sf-menu > li,
  .noo-menu > li {
    display: block; } }

.sf-menu li,
.noo-menu li {
  position: relative; }

.sf-menu li:hover > ul.sub-menu,
.noo-menu li:hover > ul.sub-menu,
.sf-menu li .sfHover > ul.sub-menu,
.noo-menu li .sfHover > ul.sub-menu {
  display: block; }

.sf-menu ul.sub-menu,
.noo-menu ul.sub-menu {
  position: absolute;
  display: none;
  top: auto;
  left: auto;
  width: auto;
  min-width: 210px; }

.noo-menu {
  margin-right: auto !important;
  margin-left: auto !important; }

.noo-menu li > a {
  color: #5f5f5f;
  padding: 10px 12px; }

.noo-menu li > a:hover,
.noo-menu li > a:focus {
  background-color: transparent;
  color: #fe6367; }

.noo-menu li.current-menu-item > a,
.noo-menu li.current-menu-parent > a,
.noo-menu li.active > a {
  background-color: transparent;
  color: #fe6367; }

.noo-menu li.disabled > a,
.noo-menu li.disabled > a:hover,
.noo-menu li.disabled > a:focus {
  color: #5f5f5f;
  background-color: #5f5f5f; }

.noo-menu ul.sub-menu {
  z-index: 1030;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px; }

.noo-menu ul.sub-menu ul.sub-menu {
  top: 10px;
  left: 95%;
  right: auto; }

.sf-arrows .sub-menu .sf-with-ul:after {
  content: "\f105";
  font-family: "fontawesome";
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  speak: none;
  right: 15px;
  position: absolute; }

@media (max-width: 991px) {
  .navbar-nav li {
    border-top: 1px solid #f2f2f2;
    background-color: #2a2a2a; } }

.navbar-nav > li > a {
  text-transform: uppercase; }

@media (max-width: 991px) {
  .navbar-nav > li > a {
    padding-top: 10px !important;
    padding-bottom: 10px !important; } }

.navbar-nav > li > a {
  color: #ffffff;
  padding: 0 22px;
  line-height: 1;
  font-size: 13px; }

.navbar-nav > li > a:before {
  content: '';
  width: 0;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
  background: #fe6367; }

@media (max-width: 991px) {
  .navbar-nav > li > a:before {
    display: none; } }

@media (max-width: 1199px) {
  .navbar-nav > li > a {
    padding-left: 18px;
    padding-right: 18px; } }

.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus {
  color: #fe6367;
  background-color: transparent; }

.navbar-nav > li > a:hover:before,
.navbar-nav > li > a:focus:before {
  width: 100%; }

.navbar-nav > li:hover > a,
.navbar-nav > li .sfHover > a {
  background-color: transparent;
  color: #fe6367; }

.navbar-nav > li.current-menu-item > a,
.navbar-nav > li.current-menu-parent > a,
.navbar-nav > li.active > a {
  background-color: transparent;
  color: #fe6367; }

.navbar-nav > li.current-menu-item > a:before,
.navbar-nav > li.current-menu-parent > a:before,
.navbar-nav > li.active > a:before {
  width: 100%; }

.navbar-nav > li.disabled > a,
.navbar-nav > li.disabled > a:hover,
.navbar-nav > li.disabled > a:focus {
  color: #cccccc;
  background-color: transparent; }

.navbar-nav ul.sub-menu {
  z-index: 1030;
  font-size: 13px; }

@media (max-width: 991px) {
  .navbar-nav ul.sub-menu > li > a {
    padding-left: 25px; }
  .navbar-nav ul.sub-menu > li li a {
    padding-left: 35px; } }

.navbar-nav ul.sub-menu li {
  display: block; }

.navbar-nav ul.sub-menu li > a {
  color: #ffffff;
  line-height: 22px;
  padding: 7px 20px;
  text-transform: uppercase; }

@media (max-width: 991px) {
  .navbar-nav ul.sub-menu li > a {
    padding-left: 30px; } }

.navbar-nav ul.sub-menu li > a:hover {
  color: #ffffff;
  background-color: #fe4a4e; }

.navbar-nav ul.sub-menu li:hover .navbar-nav ul.sub-menu li.sfHover > a {
  color: #ffffff;
  background-color: #fe4a4e; }

.navbar-nav ul.sub-menu li.current-menu-item > a {
  color: #ffffff;
  background-color: #fe4a4e; }

@media (min-width: 992px) {
  .navbar-nav {
    float: right; }
  .navbar-nav > li > ul.sub-menu {
    left: auto;
    right: 0; }
  .navbar-nav > li > ul.sub-menu ul.sub-menu {
    top: 10px;
    left: auto;
    right: 95%; } }

@media (max-width: 991px) {
  .navbar-nav > li {
    float: none; }
  .navbar-nav li > a {
    white-space: normal; }
  .navbar-nav li {
    -webkit-transition: none;
    -o-transition: none;
    transition: none; }
  .navbar-nav ul.sub-menu {
    position: static;
    float: none;
    max-width: none;
    padding: 0;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none; }
  .navbar-nav ul.sub-menu:before,
  .navbar-nav ul.sub-menu:after {
    display: none; }
  .navbar-nav ul.sub-menu a {
    font-size: 13px; } }

@media (min-width: 992px) {
  .navbar .navbar-brand {
    line-height: 75px; }
  .navbar .navbar-nav > li > a {
    line-height: 75px; } }

@media (min-width: 992px) {
  .navbar-nav.sf-menu > li ul.sub-menu {
    background-color: #fe6367;
    padding: 15px 0; }
  .navbar-nav.sf-menu > li li.fly-left > ul.sub-menu {
    left: auto;
    right: 95%;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2); }
  .navbar-nav.sf-menu > li li.fly-right > ul.sub-menu {
    left: 95%;
    right: auto;
    box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.135); }
  .navbar-nav.sf-menu > li.align-left > ul.sub-menu {
    left: 0;
    right: auto; }
  .navbar-nav.sf-menu > li.align-right > ul.sub-menu {
    left: auto;
    right: 0; }
  .navbar-nav.sf-menu > li.align-right > ul.sub-menu:before {
    right: 35px !important;
    left: auto !important;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fe6367;
    border-left: 8px solid transparent; }
  .navbar-nav.sf-menu > li.align-center > ul.sub-menu {
    left: 50% !important;
    right: auto;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0); }
  .navbar-nav.sf-menu > li.align-center > ul.sub-menu:before {
    right: 50%;
    left: auto;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fe6367;
    border-left: 8px solid transparent; }
  .navbar-nav.sf-menu > li.full-width {
    position: inherit !important; }
  .navbar-nav.sf-menu > li.full-width > ul.sub-menu {
    width: 100%;
    left: 0; }
  .navbar-nav.sf-menu > li.full-width.sfHover > ul.sub-menu:before {
    border: 0; }
  .navbar-nav.sf-menu > li.full-width.sfHover > a {
    position: relative; }
  .navbar-nav.sf-menu > li.full-width.sfHover > a:before {
    content: '';
    position: absolute;
    left: 35px;
    bottom: 0px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fe6367;
    border-left: 6px solid transparent; }
  .navbar-nav.sf-menu > li.megamenu > ul.sub-menu {
    padding: 15px 0 15px 4px; }
  .navbar-nav.sf-menu > li.megamenu > ul.sub-menu li {
    float: left;
    width: 100%; }
  .navbar-nav.sf-menu > li.megamenu > ul.sub-menu li > a {
    white-space: normal; }
  .navbar-nav.sf-menu > li.megamenu > ul.sub-menu > li {
    display: table-cell !important;
    float: none !important;
    margin: 5px 0;
    padding-left: 0;
    padding-right: 0;
    vertical-align: top;
    position: relative;
    width: 32.8%; }
  .navbar-nav.sf-menu > li.megamenu > ul.sub-menu > li > ul.sub-menu {
    display: block !important;
    opacity: 1 !important;
    top: 0px !important;
    width: 100%;
    left: 0px !important;
    position: relative !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent; }
  .navbar-nav.sf-menu > li.megamenu > ul.sub-menu > li.menu-item-has-children {
    border-right: 1px solid rgba(65, 65, 65, 0.1); }
  .navbar-nav.sf-menu > li.megamenu > ul.sub-menu > li.menu-item-has-children:last-child {
    border-right: none !important; }
  .navbar-nav.sf-menu > li.megamenu > ul.sub-menu > li.menu-item-has-children > a {
    background-color: transparent;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px; }
  .navbar-nav.sf-menu > li.megamenu > ul.sub-menu > li.menu-item-has-children > a.sf-with-ul:before,
  .navbar-nav.sf-menu > li.megamenu > ul.sub-menu > li.menu-item-has-children > a.sf-with-ul:after {
    display: none; }
  .navbar-nav.sf-menu > li.megamenu > ul.sub-menu > li.menu-item-has-children:hover > a,
  .navbar-nav.sf-menu > li.megamenu > ul.sub-menu > li.menu-item-has-children.sfHover > a {
    background-color: transparent; }
  .navbar-nav.sf-menu > li.megamenu ul.sub-menu li > a {
    border-top: 0; }
  .navbar-nav.sf-menu > li.megamenu.columns-2 > ul.sub-menu > li {
    width: 49.6%; }
  .navbar-nav.sf-menu > li.megamenu.columns-2 > ul.sub-menu > li:nth-child(2n) {
    border-right: none !important; }
  .navbar-nav.sf-menu > li.megamenu.columns-2 > ul.sub-menu > li:nth-child(2n+1) {
    clear: both; }
  .navbar-nav.sf-menu > li.megamenu.columns-3 > ul.sub-menu > li {
    width: 32.8%; }
  .navbar-nav.sf-menu > li.megamenu.columns-3 > ul.sub-menu > li:nth-child(3n) {
    border-right: none !important; }
  .navbar-nav.sf-menu > li.megamenu.columns-3 > ul.sub-menu > li:nth-child(3n+1) {
    clear: both; }
  .navbar-nav.sf-menu > li.megamenu.columns-4 > ul.sub-menu > li {
    width: 24.45%; }
  .navbar-nav.sf-menu > li.megamenu.columns-4 > ul.sub-menu > li:nth-child(4n) {
    border-right: none !important; }
  .navbar-nav.sf-menu > li.megamenu.columns-4 > ul.sub-menu > li:nth-child(4n+1) {
    clear: both; }
  .navbar-nav.sf-menu > li.megamenu.columns-5 > ul.sub-menu > li {
    width: 19.45%; }
  .navbar-nav.sf-menu > li.megamenu.columns-5 > ul.sub-menu > li:nth-child(5n) {
    border-right: none !important; }
  .navbar-nav.sf-menu > li.megamenu.columns-5 > ul.sub-menu > li:nth-child(5n+1) {
    clear: both; }
  .navbar-nav.sf-menu > li.megamenu.columns-6 > ul.sub-menu > li {
    width: 16.1%; }
  .navbar-nav.sf-menu > li.megamenu.columns-6 > ul.sub-menu > li:nth-child(6n) {
    border-right: none !important; }
  .navbar-nav.sf-menu > li.megamenu.columns-6 > ul.sub-menu > li:nth-child(6n+1) {
    clear: both; } }

.navbar-toggle.collapsed {
  color: #ffffff; }

.navbar-toggle.collapsed:hover,
.navbar-toggle.collapsed:focus {
  color: #d9d9d9; }

.noo-navbar {
  display: inline-block;
  width: 100%; }

.navbar-nav li.navbar-donate-btn {
  padding: 0 10px; }

@media (max-width: 991px) {
  .navbar-nav li.navbar-donate-btn {
    padding: 15px;
    text-align: center; } }

.navbar-nav li.navbar-donate-btn > a {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 0px solid transparent;
  white-space: nowrap;
  background: #e7e7e7;
  text-transform: uppercase;
  padding: 0.7142857143em 3em;
  font-size: 14px;
  line-height: 1.7;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: border-color color 0.2s ease;
  -o-transition: border-color color 0.2s ease;
  transition: border-color color 0.2s ease;
  color: #ffffff;
  background-color: #fe6367;
  border-color: 0;
  padding: 0.7142857143em 1.1428571429em;
  font-size: 1.2857142857142858em;
  line-height: 2;
  border-radius: 3px;
  line-height: 1 !important;
  color: #fff !important;
  vertical-align: baseline;
  font-weight: bold; }

.navbar-nav li.navbar-donate-btn > a:hover,
.navbar-nav li.navbar-donate-btn > a:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.navbar-nav li.navbar-donate-btn > a:hover,
.navbar-nav li.navbar-donate-btn > a:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.navbar-nav li.navbar-donate-btn > a:focus,
.navbar-nav li.navbar-donate-btn > a:active:focus,
.navbar-nav li.navbar-donate-btn > a.active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.navbar-nav li.navbar-donate-btn > a:hover,
.navbar-nav li.navbar-donate-btn > a:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.navbar-nav li.navbar-donate-btn > a:active,
.navbar-nav li.navbar-donate-btn > a.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.navbar-nav li.navbar-donate-btn > a.disabled,
.navbar-nav li.navbar-donate-btn > a[disabled],
fieldset[disabled] .navbar-nav li.navbar-donate-btn > a {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.navbar-nav li.navbar-donate-btn > a.square {
  border-radius: 0; }

.navbar-nav li.navbar-donate-btn > a.pill {
  border-radius: 100em; }

.navbar-nav li.navbar-donate-btn > a.rounded {
  border-radius: 0.225em; }

.navbar-nav li.navbar-donate-btn > a i {
  padding-right: 10px; }

.navbar-nav li.navbar-donate-btn > a.icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.navbar-nav li.navbar-donate-btn > a:hover,
.navbar-nav li.navbar-donate-btn > a:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.navbar-nav li.navbar-donate-btn > a:hover,
.navbar-nav li.navbar-donate-btn > a:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.navbar-nav li.navbar-donate-btn > a:focus,
.navbar-nav li.navbar-donate-btn > a:active:focus,
.navbar-nav li.navbar-donate-btn > a.active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.navbar-nav li.navbar-donate-btn > a:hover,
.navbar-nav li.navbar-donate-btn > a:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.navbar-nav li.navbar-donate-btn > a:active,
.navbar-nav li.navbar-donate-btn > a.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.navbar-nav li.navbar-donate-btn > a.disabled,
.navbar-nav li.navbar-donate-btn > a[disabled],
fieldset[disabled] .navbar-nav li.navbar-donate-btn > a {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.navbar-nav li.navbar-donate-btn > a.square {
  border-radius: 0; }

.navbar-nav li.navbar-donate-btn > a.pill {
  border-radius: 100em; }

.navbar-nav li.navbar-donate-btn > a.rounded {
  border-radius: 0.225em; }

.navbar-nav li.navbar-donate-btn > a i {
  padding-right: 10px; }

.navbar-nav li.navbar-donate-btn > a.icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.navbar-nav li.navbar-donate-btn > a:hover,
.navbar-nav li.navbar-donate-btn > a:focus,
.navbar-nav li.navbar-donate-btn > a:active,
.navbar-nav li.navbar-donate-btn > a.active,
.open > .dropdown-toggle.navbar-nav li.navbar-donate-btn > a {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.navbar-nav li.navbar-donate-btn > a:hover,
.navbar-nav li.navbar-donate-btn > a:focus {
  outline: 0;
  background: #5f5f5f; }

.navbar-nav li.navbar-donate-btn > a:hover,
.navbar-nav li.navbar-donate-btn > a:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.navbar-nav li.navbar-donate-btn > a:hover,
.navbar-nav li.navbar-donate-btn > a:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.navbar-nav li.navbar-donate-btn > a:focus,
.navbar-nav li.navbar-donate-btn > a:active:focus,
.navbar-nav li.navbar-donate-btn > a.active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.navbar-nav li.navbar-donate-btn > a:hover,
.navbar-nav li.navbar-donate-btn > a:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.navbar-nav li.navbar-donate-btn > a:active,
.navbar-nav li.navbar-donate-btn > a.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.navbar-nav li.navbar-donate-btn > a.disabled,
.navbar-nav li.navbar-donate-btn > a[disabled],
fieldset[disabled] .navbar-nav li.navbar-donate-btn > a {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.navbar-nav li.navbar-donate-btn > a.square {
  border-radius: 0; }

.navbar-nav li.navbar-donate-btn > a.pill {
  border-radius: 100em; }

.navbar-nav li.navbar-donate-btn > a.rounded {
  border-radius: 0.225em; }

.navbar-nav li.navbar-donate-btn > a i {
  padding-right: 10px; }

.navbar-nav li.navbar-donate-btn > a.icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.navbar-nav li.navbar-donate-btn > a:hover,
.navbar-nav li.navbar-donate-btn > a:focus,
.navbar-nav li.navbar-donate-btn > a:active,
.navbar-nav li.navbar-donate-btn > a.active,
.open > .dropdown-toggle.navbar-nav li.navbar-donate-btn > a {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.navbar-nav li.navbar-donate-btn > a:hover,
.navbar-nav li.navbar-donate-btn > a:focus {
  outline: 0;
  background: #5f5f5f; }

@media (min-width: 992px) {
  body.page-menu-center-vertical:not(.boxed-layout) .navbar.navbar-default:not(.navbar-fixed-top) {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    background: rgba(0, 0, 0, 0.8); }
  body.page-menu-center-vertical:not(.boxed-layout) .navbar.navbar-default:not(.navbar-fixed-top) .navbar-brand {
    background: #fe6367; } }

.page-menu-transparent .noo-topbar {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.1); }

.page-menu-transparent .noo-topbar .topbar-right .searchform .searchinput {
  background: transparent; }

.page-menu-transparent .navbar:not(.navbar-fixed-top) {
  background: transparent;
  border-color: transparent;
  color: #fff; }

@media (max-width: 991px) {
  .navbar-nav.header-right {
    display: none; } }

.navbar-nav.header-right > li > a {
  cursor: pointer;
  padding: 0 10px; }

.navbar-nav.header-right > li > a:before {
  display: none; }

/**
 * 4.0 - Basic Element
 *  4.7 - modals
 */
.modal-open {
  overflow: hidden; }

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
  z-index: 1050;
  outline: 0; }

.modal.fade .modal-dialog {
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: scale 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0s, transform 0.5s ease-in-out 0s;
  -o-transition: scale 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0s, transform 0.5s ease-in-out 0s;
  -ms-transition: scale 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0s, transform 0.5s ease-in-out 0s;
  -moz-transition: scale 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0s, transform 0.5s ease-in-out 0s;
  transition: scale 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0s, transform 0.5s ease-in-out 0s;
  -webkit-transform-origin: center top 0;
  -moz-transform-origin: center top 0;
  -ms-transform-origin: center top 0;
  transform-origin: center top 0; }

.modal.in .modal-dialog {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.7142857142857143em; }

.modal-dialog.modal-dialog-center {
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%; }

.modal-dialog.modal-dialog-center .modal-body {
  overflow-y: auto; }

.modal-dialog.modal-dialog-center .modal-footer {
  margin-top: 0; }

@media (max-width: 767px) {
  .modal-dialog.modal-dialog-center {
    width: 100%; } }

.modal-content {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #999999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000; }

.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0); }

.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50); }

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.7px; }

.modal-header .close {
  margin-top: -0.14285714285714285em; }

.modal-title {
  margin: 0;
  line-height: 1.7; }

.modal-body {
  position: relative;
  padding: 15px; }

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5; }

.modal-footer .btn + .btn,
.modal-footer .widget_newsletterwidget .newsletter-submit + .widget_newsletterwidget .newsletter-submit {
  margin-left: 0.35714285714285715em;
  margin-bottom: 0; }

.modal-footer .btn-block + .btn-block {
  margin-left: 0; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 2.142857142857143em auto; }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); }
  .modal-sm {
    width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    width: 900px; } }

.donate-modal {
  font-family: "PT Sans", sans-serif; }

.donate-modal .donate-error {
  text-align: center;
  color: #d9534f;
  margin-bottom: 1.0714285714285714em; }

.donate-modal .modal-header {
  padding: 0;
  border: none; }

.donate-modal .modal-header .close {
  font-size: 30px;
  margin: 0;
  opacity: 0.4;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1; }

.donate-modal .modal-body {
  padding: 0; }

.donate-modal .modal-body .nav-tabs {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #dbdada; }

.donate-modal .modal-body .nav-tabs > li {
  margin-bottom: 0;
  width: 50%;
  border: none;
  padding: 0;
  margin: 0; }

.donate-modal .modal-body .nav-tabs > li > a:focus,
.donate-modal .modal-body .nav-tabs > li > a:hover,
.donate-modal .modal-body .nav-tabs > li > a {
  margin: 0;
  text-align: center;
  padding: 2.142857142857143em;
  font-weight: bold;
  border: none;
  color: #a0a0a0;
  background: transparent;
  -webkit-border-radius: 0;
  border-radius: 0; }

.donate-modal .modal-body .nav-tabs > li:first-child > a {
  border-right: 1px solid #dbdada !important; }

.donate-modal .modal-body .nav-tabs > li.active > a {
  background: transparent;
  color: #454545;
  border: none; }

.donate-modal .modal-body .tab-content {
  padding: 2.142857142857143em; }

.donate-modal .modal-body .tab-content .donate-form-radio {
  padding: 1.0714285714285714em 0; }

.donate-modal .modal-body .tab-content .donate-form-radio:before,
.donate-modal .modal-body .tab-content .donate-form-radio:after {
  content: " ";
  display: table; }

.donate-modal .modal-body .tab-content .donate-form-radio:after {
  clear: both; }

.donate-modal .modal-body .tab-content .donate-form-radio:before,
.donate-modal .modal-body .tab-content .donate-form-radio:after {
  content: " ";
  display: table; }

.donate-modal .modal-body .tab-content .donate-form-radio:after {
  clear: both; }

.donate-modal .modal-body .tab-content .donate-form-radio label {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 0px solid transparent;
  white-space: nowrap;
  background: #e7e7e7;
  text-transform: uppercase;
  padding: 0.7142857143em 3em;
  font-size: 14px;
  line-height: 1.7;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: border-color color 0.2s ease;
  -o-transition: border-color color 0.2s ease;
  transition: border-color color 0.2s ease;
  color: #5f5f5f;
  background-color: #e7e7e7;
  border-color: 0;
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  border: 1px solid #dbdada;
  background: #f9f9f9;
  float: left;
  font-weight: bold;
  font-family: "PT Sans", sans-serif; }

.donate-modal .modal-body .tab-content .donate-form-radio label:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.donate-modal .modal-body .tab-content .donate-form-radio label:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.donate-modal .modal-body .tab-content .donate-form-radio label:focus,
.donate-modal .modal-body .tab-content .donate-form-radio label:active:focus,
.donate-modal .modal-body .tab-content .donate-form-radio label.active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.donate-modal .modal-body .tab-content .donate-form-radio label:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.donate-modal .modal-body .tab-content .donate-form-radio label:active,
.donate-modal .modal-body .tab-content .donate-form-radio label.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.donate-modal .modal-body .tab-content .donate-form-radio label.disabled,
.donate-modal .modal-body .tab-content .donate-form-radio label[disabled],
fieldset[disabled] .donate-modal .modal-body .tab-content .donate-form-radio label {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.donate-modal .modal-body .tab-content .donate-form-radio label.square {
  border-radius: 0; }

.donate-modal .modal-body .tab-content .donate-form-radio label.pill {
  border-radius: 100em; }

.donate-modal .modal-body .tab-content .donate-form-radio label.rounded {
  border-radius: 0.225em; }

.donate-modal .modal-body .tab-content .donate-form-radio label i {
  padding-right: 10px; }

.donate-modal .modal-body .tab-content .donate-form-radio label.icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.donate-modal .modal-body .tab-content .donate-form-radio label:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.donate-modal .modal-body .tab-content .donate-form-radio label:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.donate-modal .modal-body .tab-content .donate-form-radio label:focus,
.donate-modal .modal-body .tab-content .donate-form-radio label:active:focus,
.donate-modal .modal-body .tab-content .donate-form-radio label.active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.donate-modal .modal-body .tab-content .donate-form-radio label:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.donate-modal .modal-body .tab-content .donate-form-radio label:active,
.donate-modal .modal-body .tab-content .donate-form-radio label.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.donate-modal .modal-body .tab-content .donate-form-radio label.disabled,
.donate-modal .modal-body .tab-content .donate-form-radio label[disabled],
fieldset[disabled] .donate-modal .modal-body .tab-content .donate-form-radio label {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.donate-modal .modal-body .tab-content .donate-form-radio label.square {
  border-radius: 0; }

.donate-modal .modal-body .tab-content .donate-form-radio label.pill {
  border-radius: 100em; }

.donate-modal .modal-body .tab-content .donate-form-radio label.rounded {
  border-radius: 0.225em; }

.donate-modal .modal-body .tab-content .donate-form-radio label i {
  padding-right: 10px; }

.donate-modal .modal-body .tab-content .donate-form-radio label.icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.donate-modal .modal-body .tab-content .donate-form-radio label:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label:focus,
.donate-modal .modal-body .tab-content .donate-form-radio label:active,
.donate-modal .modal-body .tab-content .donate-form-radio label.active,
.open > .dropdown-toggle.donate-modal .modal-body .tab-content .donate-form-radio label {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.donate-modal .modal-body .tab-content .donate-form-radio label:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.donate-modal .modal-body .tab-content .donate-form-radio label:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.donate-modal .modal-body .tab-content .donate-form-radio label:focus,
.donate-modal .modal-body .tab-content .donate-form-radio label:active:focus,
.donate-modal .modal-body .tab-content .donate-form-radio label.active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.donate-modal .modal-body .tab-content .donate-form-radio label:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.donate-modal .modal-body .tab-content .donate-form-radio label:active,
.donate-modal .modal-body .tab-content .donate-form-radio label.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.donate-modal .modal-body .tab-content .donate-form-radio label.disabled,
.donate-modal .modal-body .tab-content .donate-form-radio label[disabled],
fieldset[disabled] .donate-modal .modal-body .tab-content .donate-form-radio label {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.donate-modal .modal-body .tab-content .donate-form-radio label.square {
  border-radius: 0; }

.donate-modal .modal-body .tab-content .donate-form-radio label.pill {
  border-radius: 100em; }

.donate-modal .modal-body .tab-content .donate-form-radio label.rounded {
  border-radius: 0.225em; }

.donate-modal .modal-body .tab-content .donate-form-radio label i {
  padding-right: 10px; }

.donate-modal .modal-body .tab-content .donate-form-radio label.icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.donate-modal .modal-body .tab-content .donate-form-radio label:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label:focus,
.donate-modal .modal-body .tab-content .donate-form-radio label:active,
.donate-modal .modal-body .tab-content .donate-form-radio label.active,
.open > .dropdown-toggle.donate-modal .modal-body .tab-content .donate-form-radio label {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

@media (max-width: 767px) {
  .donate-modal .modal-body .tab-content .donate-form-radio label {
    margin-bottom: 1.0714285714285714em; } }

.donate-modal .modal-body .tab-content .donate-form-radio label input[type=radio] {
  display: none; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 0px solid transparent;
  white-space: nowrap;
  background: #e7e7e7;
  text-transform: uppercase;
  padding: 0.7142857143em 3em;
  font-size: 14px;
  line-height: 1.7;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: border-color color 0.2s ease;
  -o-transition: border-color color 0.2s ease;
  transition: border-color color 0.2s ease;
  color: #ffffff;
  background-color: #fe6367;
  border-color: 0; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected:focus,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected:active:focus,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected.active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected:active,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected.disabled,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected[disabled],
fieldset[disabled] .donate-modal .modal-body .tab-content .donate-form-radio label.selected {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected.square {
  border-radius: 0; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected.pill {
  border-radius: 100em; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected.rounded {
  border-radius: 0.225em; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected i {
  padding-right: 10px; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected.icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected:focus,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected:active,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected.active,
.open > .dropdown-toggle.donate-modal .modal-body .tab-content .donate-form-radio label.selected {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected:focus {
  outline: 0;
  background: #5f5f5f; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected:focus,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected:active:focus,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected.active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected:active,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected.disabled,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected[disabled],
fieldset[disabled] .donate-modal .modal-body .tab-content .donate-form-radio label.selected {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected.square {
  border-radius: 0; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected.pill {
  border-radius: 100em; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected.rounded {
  border-radius: 0.225em; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected i {
  padding-right: 10px; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected.icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected:focus,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected:active,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected.active,
.open > .dropdown-toggle.donate-modal .modal-body .tab-content .donate-form-radio label.selected {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.donate-modal .modal-body .tab-content .donate-form-radio label.selected:hover,
.donate-modal .modal-body .tab-content .donate-form-radio label.selected:focus {
  outline: 0;
  background: #5f5f5f; }

.donate-modal .modal-body .tab-content .donate-form-text {
  display: block; }

.donate-modal .modal-body .tab-content .donate-form-text label {
  display: block;
  margin: 0; }

.donate-modal .modal-body .tab-content .donate-form-text .donate-form-val-by-input {
  display: none; }

.donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input {
  height: 2.9285714285714284em;
  display: block;
  padding: 0.4285714286em 1.2857142857em;
  font-size: 14px;
  line-height: 1.7;
  color: #5f5f5f;
  background-color: transparent;
  background-image: none;
  border: 1px solid #dbdada;
  border-radius: 0;
  letter-spacing: 0.5px;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-family: "PT Sans", sans-serif;
  width: 100%;
  margin: 1.0714285714285714em 0;
  font-weight: bold; }

.donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input:focus {
  border-color: #797979;
  outline: 0; }

.donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input::-moz-placeholder {
  color: #8c8c8c;
  opacity: 1; }

.donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input:-ms-input-placeholder {
  color: #8c8c8c; }

.donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input::-webkit-input-placeholder {
  color: #8c8c8c; }

.donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input[disabled],
.donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input[readonly],
fieldset[disabled] .donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input {
  cursor: not-allowed;
  background-color: #f4f4f4;
  opacity: 1; }

textarea.donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input {
  height: auto; }

.donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input:focus {
  border-color: #797979;
  outline: 0; }

.donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input::-moz-placeholder {
  color: #8c8c8c;
  opacity: 1; }

.donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input:-ms-input-placeholder {
  color: #8c8c8c; }

.donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input::-webkit-input-placeholder {
  color: #8c8c8c; }

.donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input[disabled],
.donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input[readonly],
fieldset[disabled] .donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input {
  cursor: not-allowed;
  background-color: #f4f4f4;
  opacity: 1; }

textarea.donate-modal .modal-body .tab-content .donate-form-text .donate-form-text-input {
  height: auto; }

.donate-modal .modal-body .tab-content .donate-form-text .selected .donate-form-text-input {
  border-color: #fe6367; }

.donate-modal .modal-body .tab-content .donate-form-info textarea,
.donate-modal .modal-body .tab-content .donate-form-info input[type=text] {
  height: 2.9285714285714284em;
  padding: 0.4285714286em 1.2857142857em;
  font-size: 14px;
  line-height: 1.7;
  color: #5f5f5f;
  background-color: transparent;
  background-image: none;
  border: 1px solid #dbdada;
  border-radius: 0;
  letter-spacing: 0.5px;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-family: "PT Sans", sans-serif;
  display: block;
  width: 100%;
  margin-bottom: 1.0714285714285714em; }

.donate-modal .modal-body .tab-content .donate-form-info textarea:focus,
.donate-modal .modal-body .tab-content .donate-form-info input[type=text]:focus {
  border-color: #797979;
  outline: 0; }

.donate-modal .modal-body .tab-content .donate-form-info textarea::-moz-placeholder,
.donate-modal .modal-body .tab-content .donate-form-info input[type=text]::-moz-placeholder {
  color: #8c8c8c;
  opacity: 1; }

.donate-modal .modal-body .tab-content .donate-form-info textarea:-ms-input-placeholder,
.donate-modal .modal-body .tab-content .donate-form-info input[type=text]:-ms-input-placeholder {
  color: #8c8c8c; }

.donate-modal .modal-body .tab-content .donate-form-info textarea::-webkit-input-placeholder,
.donate-modal .modal-body .tab-content .donate-form-info input[type=text]::-webkit-input-placeholder {
  color: #8c8c8c; }

.donate-modal .modal-body .tab-content .donate-form-info textarea[disabled],
.donate-modal .modal-body .tab-content .donate-form-info input[type=text][disabled],
.donate-modal .modal-body .tab-content .donate-form-info textarea[readonly],
.donate-modal .modal-body .tab-content .donate-form-info input[type=text][readonly],
fieldset[disabled] .donate-modal .modal-body .tab-content .donate-form-info textarea,
fieldset[disabled] .donate-modal .modal-body .tab-content .donate-form-info input[type=text] {
  cursor: not-allowed;
  background-color: #f4f4f4;
  opacity: 1; }

textarea.donate-modal .modal-body .tab-content .donate-form-info textarea,
textarea.donate-modal .modal-body .tab-content .donate-form-info input[type=text] {
  height: auto; }

.donate-modal .modal-body .tab-content .donate-form-info textarea:focus,
.donate-modal .modal-body .tab-content .donate-form-info input[type=text]:focus {
  border-color: #797979;
  outline: 0; }

.donate-modal .modal-body .tab-content .donate-form-info textarea::-moz-placeholder,
.donate-modal .modal-body .tab-content .donate-form-info input[type=text]::-moz-placeholder {
  color: #8c8c8c;
  opacity: 1; }

.donate-modal .modal-body .tab-content .donate-form-info textarea:-ms-input-placeholder,
.donate-modal .modal-body .tab-content .donate-form-info input[type=text]:-ms-input-placeholder {
  color: #8c8c8c; }

.donate-modal .modal-body .tab-content .donate-form-info textarea::-webkit-input-placeholder,
.donate-modal .modal-body .tab-content .donate-form-info input[type=text]::-webkit-input-placeholder {
  color: #8c8c8c; }

.donate-modal .modal-body .tab-content .donate-form-info textarea[disabled],
.donate-modal .modal-body .tab-content .donate-form-info input[type=text][disabled],
.donate-modal .modal-body .tab-content .donate-form-info textarea[readonly],
.donate-modal .modal-body .tab-content .donate-form-info input[type=text][readonly],
fieldset[disabled] .donate-modal .modal-body .tab-content .donate-form-info textarea,
fieldset[disabled] .donate-modal .modal-body .tab-content .donate-form-info input[type=text] {
  cursor: not-allowed;
  background-color: #f4f4f4;
  opacity: 1; }

textarea.donate-modal .modal-body .tab-content .donate-form-info textarea,
textarea.donate-modal .modal-body .tab-content .donate-form-info input[type=text] {
  height: auto; }

.donate-modal .modal-body .tab-content .donate-form-info textarea {
  height: 150px; }

.donate-modal .donate-form-contine {
  text-align: center; }

/**
 * 4.0 - Basic Element
 *  4.8 - footer
 */
.colophon:before,
.colophon:after {
  content: " ";
  display: table; }

.colophon:after {
  clear: both; }

.colophon:before,
.colophon:after {
  content: " ";
  display: table; }

.colophon:after {
  clear: both; }

.colophon.wigetized {
  position: relative;
  background: #2a2a2a;
  border-bottom: 1px solid #2a2a2a; }

.colophon.wigetized > .container-boxed > div > div {
  padding-top: 90px;
  padding-bottom: 90px; }

@media (max-width: 767px) {
  .colophon.wigetized > .container-boxed > div > div {
    padding-top: 50px;
    padding-bottom: 50px; } }

@media (max-width: 991px) {
  .colophon.wigetized > .container-boxed > div > div {
    padding-top: 50px;
    padding-bottom: 30px; } }

@media (min-width: 992px) {
  .colophon.wigetized > .container-boxed > div {
    display: table;
    width: 100%;
    height: 100%;
    margin: 0; }
  .colophon.wigetized > .container-boxed > div > div {
    display: table-cell;
    height: 100%;
    vertical-align: top;
    border-right: 1px solid #2a2a2a;
    float: none; }
  .colophon.wigetized > .container-boxed > div > div:last-child {
    border: none; } }

.colophon.wigetized > .container-boxed .mailchimp-widget {
  max-width: 90%;
  margin: 0 auto; }

@media (max-width: 767px) {
  .colophon.wigetized .text-right,
  .colophon.wigetized .text-left,
  .colophon.wigetized .text-center {
    text-align: center; } }

.colophon.wigetized input,
.colophon.wigetized textarea {
  text-transform: uppercase;
  border-color: #dbdada; }

.wigetized .widget {
  color: #5f5f5f; }

.wigetized .widget a {
  color: #5f5f5f; }

.wigetized .widget a:hover {
  color: #fe6367; }

.wigetized .widget .widget-title {
  color: #ffffff;
  font-size: 1.2857142857142858em;
  font-weight: bold;
  position: relative;
  padding-bottom: 10px; }

.wigetized .widget .widget-title:before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 25px;
  height: 1px;
  background: #ffffff; }

.wigetized .widget .twitter_user,
.wigetized .widget .twitter_user a {
  color: #fff; }

.wigetized .widget ul li,
.wigetized .widget ol li {
  color: #5f5f5f;
  border: none;
  line-height: 2em;
  padding: 5px 0; }

.wigetized .widget ul li a,
.wigetized .widget ol li a {
  color: #5f5f5f; }

.wigetized .widget ul li a:hover,
.wigetized .widget ol li a:hover {
  color: #fe6367; }

.wigetized .widget ul li .fa,
.wigetized .widget ol li .fa,
.wigetized .widget ul li .noo-vc-accordion .panel-title a:before,
.wigetized .widget ol li .noo-vc-accordion .panel-title a:before {
  font-size: 1.2857142857142858em;
  padding-right: 1.0714285714285714em; }

.wigetized .widget.widget_recent_entries li a {
  color: #737373; }

.wigetized .widget.widget_recent_entries li a:hover {
  color: #fe6367; }

.wigetized ul.menu > li {
  font-size: 14px;
  border-bottom: 1px dotted;
  line-height: 2em;
  padding: 0.7em 0; }

.wigetized ul.menu > li a:before {
  content: "\f111";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 5px;
  opacity: 0.5;
  filter: alpha(opacity=50); }

.wigetized ul.menu > li a:before {
  font-size: 8px;
  padding-right: 10px;
  opacity: 1;
  top: -3px; }

.colophon.site-info {
  background: #232323; }

.colophon.site-info .footer-menu {
  padding: 0.3em 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2); }

.colophon.site-info .footer-menu .noo-menu {
  display: table;
  margin: 0 auto; }

.colophon.site-info .footer-menu .noo-menu li a {
  padding: 0.7142857143em 0.8571428571em;
  color: #737373; }

.colophon.site-info .footer-menu .noo-menu li a:hover {
  color: #fe6367; }

.colophon.site-info .footer-menu .noo-menu li.current-menu-item > a {
  background: none; }

.colophon.site-info .footer-more {
  padding: 20px 0;
  color: #737373; }

.colophon.site-info .footer-more a {
  color: #737373; }

.colophon.site-info .footer-more a:hover {
  color: #fe6367; }

.colophon.site-info .footer-more .noo-bottom-bar-content {
  text-align: center; }

a.go-to-top,
a.go-to-top:hover a.go-to-top:focus {
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  bottom: 72px;
  opacity: 0;
  filter: alpha(opacity=0);
  right: -60px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  color: #fff;
  font-size: 40px;
  height: 50px;
  line-height: 1;
  position: fixed;
  text-align: center;
  width: 50px;
  z-index: 100;
  visibility: hidden; }

a.go-to-top.on,
a.go-to-top:hover a.go-to-top:focus.on {
  opacity: 0.8;
  filter: alpha(opacity=80);
  visibility: visible;
  right: 20px; }

a.go-to-top.on:hover,
a.go-to-top:hover a.go-to-top:focus.on:hover {
  opacity: 1;
  filter: alpha(opacity=100); }

.noo-topbar {
  position: relative;
  background: #292929;
  z-index: 1001;
  line-height: 40px; }

@media (max-width: 991px) {
  .noo-topbar {
    display: none; } }

.noo-topbar .navbar-nav li > a:before {
  display: none; }

.noo-topbar .topbar-left {
  font-size: 0.8571428571428571em;
  color: #7e7e7e;
  margin: 0; }

.noo-topbar a {
  color: #7e7e7e; }

.noo-topbar a:hover {
  color: #ffffff; }

.noo-page-heading {
  position: relative;
  padding: 50px 0 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center; }

@media (max-width: 991px) {
  .noo-page-heading {
    padding: 120px 0; } }

@media (max-width: 767px) {
  .noo-page-heading {
    padding: 100px 0; } }

.noo-page-heading .page-title {
  margin-top: 0;
  font-size: 48px;
  text-transform: uppercase;
  color: #fe6367;
  position: relative;
  padding-bottom: 10px;
  letter-spacing: 3px;
  line-height: 1.7; }

.noo-page-heading .page-title span {
  color: #454545;
  font-weight: bold; }

.noo-page-heading .page-title:before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 25px;
  height: 1px;
  background: #fe6367; }

@media (max-width: 991px) {
  .noo-page-heading .page-title {
    font-size: 2.142857142857143em; } }

.noo-page-heading.heading-bg-image {
  padding: 180px 0;
  background-image: url("../images/page-heading-bg.jpg"); }

/**
 * 5.0 - Additional Element
 *  5.1 - alerts
 */
.alert {
  padding: 15px;
  margin-bottom: 1.6428571428571428em;
  border: 1px solid transparent;
  border-radius: 0;
  font-size: 14px; }

.alert h4 {
  margin-top: 0;
  color: inherit; }

.alert .alert-link {
  font-weight: bold; }

.alert > p,
.alert > ul {
  margin-bottom: 0; }

.alert > p + p {
  margin-top: 0.35714285714285715em;
  padding-left: 0.42857142857142855em; }

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px; }

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit; }

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d; }

.alert-success hr {
  border-top-color: #c9e2b3; }

.alert-success .alert-link {
  color: #2b542c; }

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f; }

.alert-info hr {
  border-top-color: #a6e1ec; }

.alert-info .alert-link {
  color: #245269; }

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b; }

.alert-warning hr {
  border-top-color: #f7e1b5; }

.alert-warning .alert-link {
  color: #66512c; }

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442; }

.alert-danger hr {
  border-top-color: #e4b9c0; }

.alert-danger .alert-link {
  color: #843534; }

/**
 * 5.0 - Additional Element
 *  5.3 - code
 */
code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }

pre code {
  white-space: normal; }

code {
  padding: 2px 4px;
  font-size: 0.8571428571428571em;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 3px;
  display: block;
  color: #555;
  padding: 0.8em 1em;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word; }

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #ffffff;
  background-color: #333333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25); }

kbd kbd {
  padding: 0;
  font-size: 100%;
  box-shadow: none; }

pre {
  display: block;
  padding: 0.3214285714285714em;
  margin: 0 0 0.8214285714em;
  font-size: 13px;
  line-height: 1.7;
  word-break: break-all;
  word-wrap: break-word;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 3px; }

pre code {
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border-radius: 0; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

/**
 * 5.0 - Additional Element
 *  5.4 - call-to-action
 */
.jumbotron {
  padding: 30px;
  margin-bottom: 30px;
  color: inherit;
  background-color: #f4f4f4; }

.jumbotron h1,
.jumbotron .h1 {
  color: inherit; }

.jumbotron p {
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200; }

.jumbotron > hr {
  border-top-color: #dbdbdb; }

.container .jumbotron {
  border-radius: 3px; }

.jumbotron .container {
  max-width: 100%; }

@media screen and (min-width: 768px) {
  .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px; }
  .container .jumbotron {
    padding-left: 60px;
    padding-right: 60px; }
  .jumbotron h1,
  .jumbotron .h1 {
    font-size: 63px; } }

/**
 * 5.0 - Additional Element
 *  5.5 - labels
 */
.label {
  display: inline;
  padding: 0.3em 1em 0.4em;
  font-size: 0.8571428571428571em;
  font-weight: 400;
  font-weight: bold;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline; }

a.label:hover,
a.label:focus {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer; }

.label:empty {
  display: none; }

.btn .label,
.widget_newsletterwidget .newsletter-submit .label {
  position: relative;
  top: -1px; }

.label-default {
  background-color: #5f5f5f; }

.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #464646; }

.label-primary {
  background-color: #fe6367; }

.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #fe3036; }

.label-success {
  background-color: #5cb85c; }

.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #449d44; }

.label-info {
  background-color: #5bc0de; }

.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #31b0d5; }

.label-warning {
  background-color: #f0ad4e; }

.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #ec971f; }

.label-danger {
  background-color: #d9534f; }

.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #c9302c; }

/**
 * 5.0 - Additional Element
 *  5.6 - media
 */
.media,
.media-body {
  overflow: hidden;
  zoom: 1; }

.media,
.media .media {
  margin-top: 15px; }

.media:first-child {
  margin-top: 0; }

.media-object {
  display: block; }

.media-heading {
  margin: 0 0 5px; }

.media > .pull-left {
  margin-right: 10px; }

.media > .pull-right {
  margin-left: 10px; }

.media-list {
  padding-left: 0;
  list-style: none; }

/**
 * 5.0 - Additional Element
 *  5.7 - accordion-tab
 */
.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none; }

.nav > li {
  position: relative;
  display: block; }

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 12px; }

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: transparent; }

.nav > li.disabled > a {
  color: #5f5f5f; }

.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
  color: #5f5f5f;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed; }

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
  border-color: #5f5f5f; }

.nav .nav-divider {
  height: 1px;
  margin: -0.1785714285714286em 0;
  overflow: hidden;
  background-color: #e5e5e5; }

.nav > li > a > img {
  max-width: none; }

.nav-tabs {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 1.0714285714285714em; }

.nav-tabs > li {
  float: left;
  margin-bottom: -1px; }

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.7;
  border: 1px solid transparent;
  border-radius: 3px 3px 0 0; }

.nav-tabs > li > a:hover {
  border-color: #f4f4f4 #f4f4f4 #dddddd; }

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #454545;
  background-color: transparent;
  border: 1px solid #dddddd;
  border-bottom-color: transparent;
  cursor: default; }

.nav-tabs.nav-justified {
  width: 100%;
  border-bottom: 0; }

.nav-tabs.nav-justified > li {
  float: none; }

.nav-tabs.nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px; }

.nav-tabs.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto; }

@media (min-width: 768px) {
  .nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%; }
  .nav-tabs.nav-justified > li > a {
    margin-bottom: 0; } }

.nav-tabs.nav-justified > li > a {
  margin-right: 0;
  border-radius: 3px; }

.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
  border: 1px solid #dddddd; }

@media (min-width: 768px) {
  .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #dddddd;
    border-radius: 3px 3px 0 0; }
  .nav-tabs.nav-justified > .active > a,
  .nav-tabs.nav-justified > .active > a:hover,
  .nav-tabs.nav-justified > .active > a:focus {
    border-bottom-color: transparent; } }

.nav-pills > li {
  float: left; }

.nav-pills > li > a {
  border-radius: 3px; }

.nav-pills > li + li {
  margin-left: 2px; }

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: #ffffff;
  background-color: #fe6367; }

.nav-stacked > li {
  float: none; }

.nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0; }

.nav-justified {
  width: 100%; }

.nav-justified > li {
  float: none; }

.nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px; }

.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto; }

@media (min-width: 768px) {
  .nav-justified > li {
    display: table-cell;
    width: 1%; }
  .nav-justified > li > a {
    margin-bottom: 0; } }

.nav-tabs-justified {
  border-bottom: 0; }

.nav-tabs-justified > li > a {
  margin-right: 0;
  border-radius: 3px; }

.nav-tabs-justified > .active > a,
.nav-tabs-justified > .active > a:hover,
.nav-tabs-justified > .active > a:focus {
  border: 1px solid #dddddd; }

@media (min-width: 768px) {
  .nav-tabs-justified > li > a {
    border-bottom: 1px solid #dddddd;
    border-radius: 3px 3px 0 0; }
  .nav-tabs-justified > .active > a,
  .nav-tabs-justified > .active > a:hover,
  .nav-tabs-justified > .active > a:focus {
    border-bottom-color: transparent; } }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.noo-vc-accordion.panel-group {
  margin-bottom: 20px;
  margin-top: 20px; }

.noo-vc-accordion.panel-group .panel {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  border: none;
  -webkit-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  background: transparent; }

.noo-vc-accordion.panel-group .panel + .panel {
  margin-top: 2px; }

.noo-vc-accordion.panel-group .panel-heading {
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  color: #302e38; }

.noo-vc-accordion.panel-group .panel-heading:hover,
.noo-vc-accordion.panel-group .panel-heading.active {
  background-color: #5f5f5f;
  border-color: #5f5f5f; }

.noo-vc-accordion.panel-group .panel-heading:hover .panel-title,
.noo-vc-accordion.panel-group .panel-heading.active .panel-title {
  background-color: #5f5f5f;
  color: #ffffff; }

.noo-vc-accordion.panel-group .panel-heading:hover:hover,
.noo-vc-accordion.panel-group .panel-heading.active:hover {
  background-color: #5f5f5f;
  border-color: #5f5f5f; }

.noo-vc-accordion.panel-group .panel-heading:hover a,
.noo-vc-accordion.panel-group .panel-heading.active a {
  color: #ffffff; }

.noo-vc-accordion.panel-group .panel-title {
  margin-top: 0;
  margin-bottom: 0;
  color: #302e38;
  font-size: 1.2857142857142858em; }

.noo-vc-accordion.panel-group .panel-title > a {
  color: #302e38;
  line-height: 1.7;
  display: block !important; }

.noo-vc-accordion.panel-group .panel-body {
  padding: 15px;
  border-style: solid;
  border-color: #f5f5f5;
  border-width: 0 1px 1px !important; }

.noo-vc-accordion .panel-heading.active .panel-title a {
  color: #ffffff; }

.noo-vc-accordion .panel-title a:before {
  margin-left: 0.7142857142857143em;
  margin-right: 1.4285714285714286em; }

.noo-vc-accordion.icon-dark_circe .panel-heading.active .panel-title a:before {
  content: "\f056";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 0;
  opacity: 1;
  filter: alpha(opacity=100); }

.noo-vc-accordion.icon-dark_circe .panel-heading .panel-title a:before {
  content: "\f055";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 0;
  opacity: 1;
  filter: alpha(opacity=100); }

.noo-vc-accordion.icon-light_square .panel-heading.active .panel-title a:before {
  content: "\f147";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 0;
  opacity: 1;
  filter: alpha(opacity=100); }

.noo-vc-accordion.icon-light_square .panel-heading .panel-title a:before {
  content: "\f196";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 0;
  opacity: 1;
  filter: alpha(opacity=100); }

.noo-vc-accordion.icon-dark_square .panel-heading.active .panel-title a:before {
  content: "\f146";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 0;
  opacity: 1;
  filter: alpha(opacity=100); }

.noo-vc-accordion.icon-dark_square .panel-heading .panel-title a:before {
  content: "\f0fe";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 0;
  opacity: 1;
  filter: alpha(opacity=100); }

.noo-vc-accordion.icon-simple .panel-heading.active .panel-title a:before {
  content: "\f068";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 0;
  opacity: 1;
  filter: alpha(opacity=100); }

.noo-vc-accordion.icon-simple .panel-heading .panel-title a:before {
  content: "\f067";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 0;
  opacity: 1;
  filter: alpha(opacity=100); }

.noo-vc-accordion.icon-left_arrow .panel-heading.active .panel-title a:before {
  content: "\f106";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 0;
  opacity: 1;
  filter: alpha(opacity=100); }

.noo-vc-accordion.icon-left_arrow .panel-heading .panel-title a:before {
  content: "\f107";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 0;
  opacity: 1;
  filter: alpha(opacity=100); }

.noo-vc-accordion.icon-right_arrow .panel-heading.active .panel-title a:before {
  content: "\f106";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 0;
  opacity: 1;
  filter: alpha(opacity=100); }

.noo-vc-accordion.icon-right_arrow .panel-heading {
  position: relative; }

.noo-vc-accordion.icon-right_arrow .panel-heading .panel-title a {
  padding-left: 15px; }

.noo-vc-accordion.icon-right_arrow .panel-heading .panel-title a:before {
  content: "\f107";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 0;
  opacity: 1;
  filter: alpha(opacity=100); }

.noo-vc-accordion.icon-right_arrow .panel-heading .panel-title a:before {
  float: right;
  position: absolute !important;
  top: 21%;
  right: 0px; }

.noo-vc-accordion.icon-light_circe .panel-heading.active .panel-title a:before {
  content: "\f056";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 0;
  opacity: 1;
  filter: alpha(opacity=100); }

.noo-vc-accordion.icon-light_circe .panel-heading .panel-title a:before {
  content: "\f055";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 0;
  opacity: 1;
  filter: alpha(opacity=100); }

/*
 *    Tab Shortcode
 */
.noo-tabs {
  width: 100%; }

.noo-tabs .nav > li > a {
  background-color: #dbdada; }

.noo-tabs .nav > li > a i[class^='fa'] {
  margin-right: 10px !important; }

.tabs-left > .nav-tabs {
  float: left;
  width: 22.5%; }

@media (max-width: 768px) {
  .tabs-left > .nav-tabs {
    float: none;
    width: 100% !important; } }

.tabs-left > .nav-tabs > li {
  float: none;
  border: none;
  min-width: 100%;
  margin-bottom: 0; }

.tabs-left > .nav-tabs > li:hover {
  color: transparent; }

.tabs-left > .nav-tabs > li.active > a,
.tabs-left > .nav-tabs > li.active > a:hover,
.tabs-left > .nav-tabs > li.active > a:focus {
  color: #fff;
  background-color: #5f5f5f;
  border: 1px solid #dddddd;
  border-top-color: #dddddd;
  border-right-color: transparent;
  cursor: default;
  border-width: 1px 0 1px 1px; }

@media (max-width: 767px) {
  .tabs-left > .nav-tabs > li.active > a,
  .tabs-left > .nav-tabs > li.active > a:hover,
  .tabs-left > .nav-tabs > li.active > a:focus {
    border-right: 1px solid #dddddd;
    margin-right: 0px; } }

.tabs-left > .nav-tabs > li > a {
  border-width: 1px 0 1px 1px;
  margin-right: -1px;
  margin-bottom: 2px;
  padding: 0.7142857142857143em 0;
  border: 1px solid #dddddd;
  border-right-color: transparent;
  text-align: left;
  font-size: 1.2857142857142858em;
  padding-left: 30px;
  background-color: #dbdada; }

@media (max-width: 767px) {
  .tabs-left > .nav-tabs > li > a {
    border-right: 1px solid #dddddd;
    margin-right: 0px; } }

.tabs-left > .nav-tabs > li > a:hover {
  color: #5f5f5f;
  background-color: #dbdada;
  border: 1px solid #dddddd;
  border-right-color: transparent; }

.tabs-left > .nav-tabs > li > a > i {
  padding-right: 6px; }

.tabs-left .tab-content {
  min-height: 212px;
  float: right;
  width: 77.5%; }

@media (max-width: 768px) {
  .tabs-left .tab-content {
    float: none;
    width: 100%; } }

.tabs-left .tab-content .tab-pane.active {
  padding: 26px 30px; }

/**
 * 5.0 - Additional Element
 *  5.8 - popovers
 */
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  white-space: normal; }

.popover.top {
  margin-top: -10px; }

.popover.right {
  margin-left: 10px; }

.popover.bottom {
  margin-top: 10px; }

.popover.left {
  margin-left: -10px; }

.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 2px 2px 0 0; }

.popover-content {
  padding: 9px 14px; }

.popover > .arrow,
.popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.popover > .arrow {
  border-width: 11px; }

.popover > .arrow:after {
  border-width: 10px;
  content: ""; }

.popover.top > .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999999;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -11px; }

.popover.top > .arrow:after {
  content: " ";
  bottom: 1px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: #ffffff; }

.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999999;
  border-right-color: rgba(0, 0, 0, 0.25); }

.popover.right > .arrow:after {
  content: " ";
  left: 1px;
  bottom: -10px;
  border-left-width: 0;
  border-right-color: #ffffff; }

.popover.bottom > .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -11px; }

.popover.bottom > .arrow:after {
  content: " ";
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #ffffff; }

.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999999;
  border-left-color: rgba(0, 0, 0, 0.25); }

.popover.left > .arrow:after {
  content: " ";
  right: 1px;
  border-right-width: 0;
  border-left-color: #ffffff;
  bottom: -10px; }

/**
 * 5.0 - Additional Element
 *  5.9 - progress-bars
 */
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 2.5em 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 2.5em 0; }
  to {
    background-position: 0 0; } }

.progress {
  overflow: hidden;
  height: 30px;
  margin-bottom: 1.6428571428571428em;
  background-color: #f5f5f5;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none; }

.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 0.8571428571428571em;
  line-height: 1.6428571428571428em;
  color: #ffffff;
  text-align: center;
  background-color: #fe6367;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0s ease 0s;
  -o-transition: all 0s ease 0s;
  transition: all 0s ease 0s; }

.progress-bar .progress_label {
  opacity: 0;
  filter: alpha(opacity=0); }

.progress-striped .progress-bar,
.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 2.5em 2.5em; }

.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite; }

.progress-bar[aria-valuenow="1"],
.progress-bar[aria-valuenow="2"] {
  min-width: 2.142857142857143em; }

.progress-bar[aria-valuenow="0"] {
  color: #5f5f5f;
  min-width: 2.142857142857143em;
  background-color: transparent;
  background-image: none;
  box-shadow: none; }

.progress-bar-success {
  background-color: #5cb85c; }

.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-bar-info {
  background-color: #5bc0de; }

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-bar-warning {
  background-color: #f0ad4e; }

.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-bar-danger {
  background-color: #d9534f; }

.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.noo-progress-bar.lean-bars {
  margin-top: 20px; }

.noo-progress-bar.lean-bars .progress {
  height: 5px;
  margin-bottom: 2.5em;
  border-radius: 0;
  overflow: visible;
  position: relative; }

.noo-progress-bar.lean-bars .progress .progress-bar .progress_title,
.noo-progress-bar.lean-bars .progress .progress-bar .progress_label {
  position: absolute;
  top: -1.6em;
  color: #302e38;
  font-size: 14px;
  font-weight: 400; }

.noo-progress-bar.lean-bars .progress .progress-bar .progress_title {
  left: 0; }

.noo-progress-bar.lean-bars .progress .progress-bar .progress_label {
  right: 0; }

.lean-bars.rounded-bars .progress {
  -webkit-border-radius: 3px;
  border-radius: 3px; }

.lean-bars.rounded-bars .progress .progress-bar {
  -webkit-border-radius: 3px;
  border-radius: 3px; }

.noo-progress-bar.thick-bars .progress {
  -webkit-border-radius: 0;
  border-radius: 0; }

.noo-progress-bar.thick-bars .progress .progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 14px; }

.noo-progress-bar.thick-bars .progress .progress-bar .progress_title {
  float: left;
  padding: 0 0 0 1.0714285714em;
  line-height: 1.6em; }

.noo-progress-bar.thick-bars .progress .progress-bar .progress_label {
  float: right;
  padding: 0 1.0714285714em 0 0;
  line-height: 1.6em; }

.thick-bars.rounded-bars .progress,
.thick-bars.rounded-bars .progress-bar {
  -webkit-border-radius: 3px;
  border-radius: 3px; }

/**
 * 5.0 - Additional Element
 *  5.10 - tooltip
 */
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 0.8571428571428571em;
  line-height: 1.4;
  opacity: 0;
  filter: alpha(opacity=0); }

.tooltip.in {
  opacity: 0.9;
  filter: alpha(opacity=90); }

.tooltip.top {
  margin-top: -3px;
  padding: 5px 0; }

.tooltip.right {
  margin-left: 3px;
  padding: 0 5px; }

.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0; }

.tooltip.left {
  margin-left: -3px;
  padding: 0 5px; }

.tooltip.tooltip-social .tooltip-inner {
  border: 1px solid #c2c0c0;
  background: #e0e0e0; }

.tooltip.tooltip-social .tooltip-arrow {
  border-bottom-color: #e0e0e0 !important;
  top: -2px;
  border-width: 0 10px 10px !important; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: #000000;
  border-radius: 3px; }

.tooltip-inner:before,
.tooltip-inner:after {
  content: " ";
  display: table; }

.tooltip-inner:after {
  clear: both; }

.tooltip-inner:before,
.tooltip-inner:after {
  content: " ";
  display: table; }

.tooltip-inner:after {
  clear: both; }

.tooltip-inner .noo-share {
  font-size: 18px;
  margin: 5px 10px;
  color: #a0a0a0;
  float: left; }

.tooltip-inner .noo-share:hover {
  color: #fe6367; }

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000000; }

.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  left: 5px;
  border-width: 5px 5px 0;
  border-top-color: #000000; }

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  right: 5px;
  border-width: 5px 5px 0;
  border-top-color: #000000; }

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000000; }

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000000; }

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000000; }

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  left: 5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000000; }

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  right: 5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000000; }

/**
 * 5.0 - Additional Element
 *  5.11 - responsive-embed
 */
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0; }

.embed-responsive.embed-responsive-16by9 {
  padding-bottom: 56.25%; }

.embed-responsive.embed-responsive-4by3 {
  padding-bottom: 75%; }

/**
 * 6.0 - Wordpress part
 *  6.1 - breadcrumbs
 */
.breadcrumb {
  padding: 8px 0 0;
  margin-bottom: 1.6428571428571428em;
  list-style: none;
  color: #454545;
  margin-bottom: 0; }

.breadcrumb > li {
  display: inline-block; }

.breadcrumb > li + li:before {
  content: "\f105";
  font-family: "fontawesome";
  padding: 0 1.0714285714em; }

.breadcrumb > li > a {
  color: #454545; }

.breadcrumb > li > a:hover {
  color: #fe6367; }

.breadcrumb > li > a i {
  text-decoration: none; }

.breadcrumb > .active span {
  color: #454545; }

/**
 * 6.0 - Wordpress part
 *  6.2 - post_n_page
 */
.hentry {
  background: #f4f4f4;
  position: relative;
  margin-bottom: 2.142857142857143em; }

.hentry p:last-child,
.hentry ul:last-child,
.hentry ol:last-child {
  margin-bottom: 0; }

.hentry .content-wrap {
  padding-top: 2.142857142857143em;
  padding-right: 3.2142857142857144em;
  padding-bottom: 0.5em; }

.hentry .content-wrap .content-category a {
  color: #fe6367; }

.hentry .content-wrap .content-category a:hover {
  color: #5f5f5f; }

@media (max-width: 1199px) {
  .hentry .content-wrap {
    padding-right: 2.142857142857143em;
    padding-top: 10px; } }

@media (max-width: 767px) {
  .hentry .content-wrap {
    padding-left: 2.142857142857143em; } }

.hentry.no-featured .content-wrap {
  margin-left: 2.142857142857143em; }

.single .hentry {
  background: transparent; }

.single .hentry .content-wrap {
  padding-right: 0;
  padding-left: 0; }

.single .hentry .content-wrap ul {
  list-style-type: none; }

.single .hentry .content-wrap ul li {
  line-height: 2; }

.single .hentry .content-wrap ul li:before {
  content: "\f00c";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 5px;
  opacity: 0.5;
  filter: alpha(opacity=50); }

.single .hentry .content-wrap ul li:before {
  color: #fe6367;
  opacity: 1; }

.post-password-form label {
  font-weight: normal;
  font-weight: 600;
  font-size: 18px; }

.post-password-form input[type="password"] {
  height: 2.9285714285714284em;
  display: block;
  width: 100%;
  padding: 0.4285714286em 1.2857142857em;
  font-size: 14px;
  line-height: 1.7;
  color: #5f5f5f;
  background-color: transparent;
  background-image: none;
  border: 1px solid #dbdada;
  border-radius: 0;
  letter-spacing: 0.5px;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  display: inline-block;
  height: auto;
  width: auto; }

.post-password-form input[type="password"]:focus {
  border-color: #797979;
  outline: 0; }

.post-password-form input[type="password"]::-moz-placeholder {
  color: #8c8c8c;
  opacity: 1; }

.post-password-form input[type="password"]:-ms-input-placeholder {
  color: #8c8c8c; }

.post-password-form input[type="password"]::-webkit-input-placeholder {
  color: #8c8c8c; }

.post-password-form input[type="password"][disabled],
.post-password-form input[type="password"][readonly],
fieldset[disabled] .post-password-form input[type="password"] {
  cursor: not-allowed;
  background-color: #f4f4f4;
  opacity: 1; }

textarea.post-password-form input[type="password"] {
  height: auto; }

.post-password-form input[type="password"]:focus {
  border-color: #797979;
  outline: 0; }

.post-password-form input[type="password"]::-moz-placeholder {
  color: #8c8c8c;
  opacity: 1; }

.post-password-form input[type="password"]:-ms-input-placeholder {
  color: #8c8c8c; }

.post-password-form input[type="password"]::-webkit-input-placeholder {
  color: #8c8c8c; }

.post-password-form input[type="password"][disabled],
.post-password-form input[type="password"][readonly],
fieldset[disabled] .post-password-form input[type="password"] {
  cursor: not-allowed;
  background-color: #f4f4f4;
  opacity: 1; }

textarea.post-password-form input[type="password"] {
  height: auto; }

.post-password-form input[type="submit"] {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 0px solid transparent;
  white-space: nowrap;
  background: #e7e7e7;
  text-transform: uppercase;
  padding: 0.7142857143em 3em;
  font-size: 14px;
  line-height: 1.7;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: border-color color 0.2s ease;
  -o-transition: border-color color 0.2s ease;
  transition: border-color color 0.2s ease;
  color: #5f5f5f;
  background-color: #e7e7e7;
  border-color: 0; }

.post-password-form input[type="submit"]:hover,
.post-password-form input[type="submit"]:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.post-password-form input[type="submit"]:hover,
.post-password-form input[type="submit"]:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.post-password-form input[type="submit"]:focus,
.post-password-form input[type="submit"]:active:focus,
.post-password-form input[type="submit"].active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.post-password-form input[type="submit"]:hover,
.post-password-form input[type="submit"]:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.post-password-form input[type="submit"]:active,
.post-password-form input[type="submit"].active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.post-password-form input[type="submit"].disabled,
.post-password-form input[type="submit"][disabled],
fieldset[disabled] .post-password-form input[type="submit"] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.post-password-form input[type="submit"].square {
  border-radius: 0; }

.post-password-form input[type="submit"].pill {
  border-radius: 100em; }

.post-password-form input[type="submit"].rounded {
  border-radius: 0.225em; }

.post-password-form input[type="submit"] i {
  padding-right: 10px; }

.post-password-form input[type="submit"].icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.post-password-form input[type="submit"]:hover,
.post-password-form input[type="submit"]:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.post-password-form input[type="submit"]:hover,
.post-password-form input[type="submit"]:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.post-password-form input[type="submit"]:focus,
.post-password-form input[type="submit"]:active:focus,
.post-password-form input[type="submit"].active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.post-password-form input[type="submit"]:hover,
.post-password-form input[type="submit"]:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.post-password-form input[type="submit"]:active,
.post-password-form input[type="submit"].active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.post-password-form input[type="submit"].disabled,
.post-password-form input[type="submit"][disabled],
fieldset[disabled] .post-password-form input[type="submit"] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.post-password-form input[type="submit"].square {
  border-radius: 0; }

.post-password-form input[type="submit"].pill {
  border-radius: 100em; }

.post-password-form input[type="submit"].rounded {
  border-radius: 0.225em; }

.post-password-form input[type="submit"] i {
  padding-right: 10px; }

.post-password-form input[type="submit"].icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.post-password-form input[type="submit"]:hover,
.post-password-form input[type="submit"]:focus,
.post-password-form input[type="submit"]:active,
.post-password-form input[type="submit"].active,
.open > .dropdown-toggle.post-password-form input[type="submit"] {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.post-password-form input[type="submit"]:hover,
.post-password-form input[type="submit"]:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.post-password-form input[type="submit"]:hover,
.post-password-form input[type="submit"]:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.post-password-form input[type="submit"]:focus,
.post-password-form input[type="submit"]:active:focus,
.post-password-form input[type="submit"].active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.post-password-form input[type="submit"]:hover,
.post-password-form input[type="submit"]:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.post-password-form input[type="submit"]:active,
.post-password-form input[type="submit"].active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.post-password-form input[type="submit"].disabled,
.post-password-form input[type="submit"][disabled],
fieldset[disabled] .post-password-form input[type="submit"] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.post-password-form input[type="submit"].square {
  border-radius: 0; }

.post-password-form input[type="submit"].pill {
  border-radius: 100em; }

.post-password-form input[type="submit"].rounded {
  border-radius: 0.225em; }

.post-password-form input[type="submit"] i {
  padding-right: 10px; }

.post-password-form input[type="submit"].icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.post-password-form input[type="submit"]:hover,
.post-password-form input[type="submit"]:focus,
.post-password-form input[type="submit"]:active,
.post-password-form input[type="submit"].active,
.open > .dropdown-toggle.post-password-form input[type="submit"] {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.content-header {
  position: relative;
  margin-bottom: 3em; }

.content-featured {
  position: relative; }

.content-featured img {
  width: 100%;
  height: auto; }

.has-featured .content-featured {
  overflow: hidden; }

.content-title {
  font-size: 1.2857142857142858em;
  margin: 0.5em 0;
  text-transform: uppercase; }

@media (max-width: 1199px) {
  .content-title {
    font-size: 16px; } }

.single .content-title {
  font-size: 1.7142857142857142em; }

.sticky {
  margin-bottom: 2.142857142857143em; }

.sticky h2.content-title:before {
  content: "\f08d";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 5px;
  opacity: 0.5;
  filter: alpha(opacity=50); }

.sticky h2.content-title:before {
  line-height: 1;
  opacity: 1;
  -webkit-transform: rotate(-36deg);
  -ms-transform: rotate(-36deg);
  -o-transform: rotate(-36deg);
  transform: rotate(-36deg);
  color: #fe6367; }

.content-thumb {
  display: block;
  position: relative; }

.content-thumb:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fe6367;
  opacity: 0.6; }

.noo-post-list .content-thumb {
  max-height: 550px;
  overflow: hidden; }

.content-meta {
  color: #a0a0a0; }

.content-meta:before,
.content-meta:after {
  content: " ";
  display: table; }

.content-meta:after {
  clear: both; }

.content-meta:before,
.content-meta:after {
  content: " ";
  display: table; }

.content-meta:after {
  clear: both; }

.content-meta > span {
  padding-right: 1.0714285714285714em; }

@media (max-width: 1199px) {
  .content-meta > span {
    padding-right: 0.5em; } }

.content-meta > span i {
  padding-right: 3px; }

.content-meta > span > a {
  white-space: nowrap;
  color: #a0a0a0; }

.content-meta > span > a:hover {
  color: #fe6367; }

.content-meta .post_type_class {
  position: absolute;
  top: 1.0714285714285714em;
  right: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f9f9f9;
  text-align: center;
  line-height: 40px;
  border: 1px solid #dbdada; }

.content-meta .post_type_class i {
  line-height: 40px;
  text-align: center;
  float: none;
  width: 40px;
  height: 40px;
  padding-right: 0; }

@media (max-width: 767px) {
  .content-meta .post_type_class {
    display: none; } }

.read-more {
  color: #323232; }

.read-more i {
  margin-right: 4px; }

.read-more:hover,
.read-more:focus {
  color: #fe6367; }

.content,
.content-excerpt {
  padding: 0.5em 0; }

@media (max-width: 1199px) {
  .content,
  .content-excerpt {
    padding-top: 0; } }

.content embed,
.content-excerpt embed,
.content iframe,
.content-excerpt iframe,
.content object,
.content-excerpt object {
  width: 100%;
  max-width: 100%; }

.entry-tags {
  color: #fe6367;
  margin: 2.1428571429em 0 1.0714285714em; }

.entry-tags a {
  color: #fe6367;
  margin-left: 4px; }

.entry-tags a:hover {
  color: #5f5f5f; }

#author-bio {
  padding-top: 2.142857142857143em; }

#author-bio .author-avatar {
  float: left; }

@media (max-width: 767px) {
  #author-bio .author-avatar {
    float: none; } }

#author-bio .author-avatar img {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  z-index: 2;
  position: relative; }

#author-bio .author-info {
  margin-left: 150px; }

#author-bio .author-info h4 {
  margin-top: 0;
  text-transform: uppercase; }

#author-bio .author-info > p {
  color: #a0a0a0; }

@media (max-width: 767px) {
  #author-bio .author-info {
    margin-left: 0;
    margin-top: 1.0714285714285714em; } }

.author-connect {
  position: relative; }

.author-connect .connect-button {
  cursor: pointer;
  color: #fe6367; }

.author-connect .connect-button i {
  margin-right: 5px; }

.author-connect .connect {
  display: none;
  background: #f4f4f4;
  width: 120px;
  position: absolute;
  top: -5px;
  left: -2px;
  padding: 5px;
  text-align: center;
  -webkit-border-radius: 3px;
  border-radius: 3px; }

.author-connect .connect.active {
  display: inline-block; }

.author-connect .connect a {
  display: inline-block;
  padding: 3px; }

.content-footer .content-tags {
  margin-top: 1.6428571428571428em;
  margin-bottom: 0.8214285714285714em; }

.content-footer .content-tags a {
  margin: 0 5px 5px 0;
  color: #454545;
  background: #f4f4f4;
  border: 0;
  line-height: 20px;
  display: inline-block;
  font-size: 14px !important;
  padding: 0.3571428571em 0.7142857143em;
  margin: 0 3px 3px 0; }

.content-footer .content-tags a:hover {
  background: #fe6367;
  color: #ffffff; }

.single .content-footer {
  margin-top: 50px; }

.single .hentry .noo-social {
  position: relative;
  text-align: center; }

.single .hentry .noo-social:before {
  content: '';
  top: 50%;
  height: 1px;
  width: 100%;
  background: #f3f3f3;
  position: absolute;
  left: 0; }

.single .hentry .noo-social a {
  background: #ffffff; }

.single .hentry .noo-social a:hover,
.single .hentry .noo-social a:focus {
  background: #fe6367;
  border-color: #fe6367;
  color: #ffffff; }

.noo-social a {
  text-align: center;
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #dbdada;
  margin: 0 3px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  color: #8c8888; }

.noo-social a:hover,
.noo-social a:focus {
  background: #fe6367;
  border-color: #fe6367;
  color: #ffffff; }

.post-navigation {
  margin: 2.142857142857143em 0;
  overflow: hidden; }

.post-navigation .prev-post,
.post-navigation .next-post {
  background: #f4f4f4;
  width: 49%;
  padding: 2.142857142857143em; }

@media (max-width: 767px) {
  .post-navigation .prev-post,
  .post-navigation .next-post {
    width: 100%;
    float: none; } }

.post-navigation .prev-post h4,
.post-navigation .next-post h4 {
  color: #5f5f5f;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

.post-navigation .prev-post h4 a,
.post-navigation .next-post h4 a {
  color: #5f5f5f; }

.post-navigation .prev-post h4:hover,
.post-navigation .next-post h4:hover,
.post-navigation .prev-post h4:focus,
.post-navigation .next-post h4:focus {
  color: #fe6367; }

.post-navigation .prev-post h4:hover a,
.post-navigation .next-post h4:hover a,
.post-navigation .prev-post h4:focus a,
.post-navigation .next-post h4:focus a {
  color: #fe6367; }

.post-navigation .prev-post {
  float: left; }

.post-navigation .next-post {
  float: right;
  text-align: right; }

.noo-recent-news .noo-rn-item {
  margin-bottom: 20px; }

.noo-recent-news .item-featured {
  position: relative; }

.noo-recent-news .item-featured .icon {
  width: 70px;
  height: 70px;
  line-height: 70px;
  display: inline-block;
  text-align: center;
  background: #fe6367;
  color: #ffffff;
  font-size: 1.7142857142857142em;
  left: 0;
  bottom: 0;
  position: absolute; }

.noo-recent-news .item-content {
  padding: 20px 15px; }

.noo-recent-news .item-title {
  margin-top: 0;
  margin-bottom: 0.5em; }

.noo-recent-news .item-title a {
  color: #323232; }

.noo-recent-news .item-title a:hover {
  color: #fe6367; }

.noo-recent-news .read-more {
  color: #323232; }

.noo-recent-news .read-more:hover,
.noo-recent-news .read-more:focus {
  color: #fe6367; }

.noo-recent-news .item-date {
  color: #8f8f8f;
  font-size: 13px;
  font-style: italic;
  margin-bottom: 15px; }

/**
 * 6.0 - Wordpress part
 *  6.3 - widgets
 */
.widget + .widget {
  margin-top: 2.142857142857143em; }

.widget .widget-title:before {
  opacity: 0.5;
  filter: alpha(opacity=50); }

.widget a:hover,
.widget a:focus {
  text-decoration: none; }

.widget p:last-child,
.widget ul:last-child,
.widget ol:last-child {
  margin-bottom: 0; }

.widget ul,
.widget ol {
  overflow: hidden;
  margin-left: 0;
  padding: 0;
  list-style: none;
  background-color: transparent; }

.widget ul li,
.widget ol li {
  padding: 10px 0; }

.widget ul li a,
.widget ol li a {
  font-size: 14px;
  line-height: 1.7; }

.widget ul li:first-child,
.widget ol li:first-child {
  padding-top: 0; }

.widget select {
  width: 100%;
  margin-bottom: 1.0714285714285714em;
  padding: 7px 1.0714285714em;
  font-style: italic;
  line-height: 1.7; }

.widget option {
  font-style: normal; }

.widget .noo-social {
  padding-top: 2.142857142857143em; }

.widget .noo-social a:first-child {
  margin-left: 0; }

.widget .noo-social a:hover {
  color: #fff; }

.widget-title {
  font-size: 1.2857142857142858em;
  line-height: 1.7;
  margin: 0px 0 2.1428571429em;
  position: relative; }

.noo-sidebar .widget-title {
  margin-top: 0;
  font-size: 1.2857142857142858em;
  text-transform: uppercase;
  color: #fe6367;
  position: relative;
  padding-bottom: 10px;
  letter-spacing: 3px;
  line-height: 1.7; }

.noo-sidebar .widget-title span {
  color: #454545;
  font-weight: bold; }

.noo-sidebar .widget-title:before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 25px;
  height: 1px;
  background: #fe6367; }

.widget_latest_posts ul li img {
  float: left;
  margin-right: 20px; }

.widget_latest_posts ul li h5 {
  margin: 0.5em 0; }

.widget_search {
  display: block;
  position: relative;
  width: 100%;
  clear: both; }

.widget_search form {
  position: relative; }

.widget_search form:before {
  content: "\f002";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 0;
  opacity: 1;
  filter: alpha(opacity=100); }

.widget_search form:before {
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: inherit;
  top: 0;
  width: 2.9285714285714284em;
  cursor: pointer;
  height: 2.9285714285714284em;
  line-height: 2.9285714285714284em;
  background: transparent;
  color: #b8b8b8;
  font-weight: 300;
  -webkit-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0; }

.widget_search #s {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  padding-right: 2.9285714285714284em;
  background: #ebebeb;
  border: 0;
  font-weight: 300;
  height: 3em; }

.widget_calendar #wp-calendar {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #f9f9f9;
  max-width: 100%;
  margin-bottom: 1.4285714285714286em;
  width: 100%;
  position: relative;
  font-size: 14px;
  border-radius: 3px; }

.widget_calendar #wp-calendar caption {
  border-color: #dbdada;
  border-style: solid;
  border-width: 1px 1px 0;
  font-size: 14px;
  font-weight: 300;
  padding: 0.42857142857142855em;
  text-transform: uppercase;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e5e5e5; }

.widget_calendar #wp-calendar th,
.widget_calendar #wp-calendar td {
  text-align: center;
  line-height: 1.625em;
  padding: 0.7142857142857143em; }

.widget_calendar #wp-calendar > thead {
  background-color: transparent; }

.widget_calendar #wp-calendar > thead > tr > th {
  border-bottom: 1px solid #dbdada;
  font-weight: 300;
  font-size: 14px;
  padding: 0.42857142857142855em; }

.widget_calendar #wp-calendar > tbody > tr > td {
  border: none;
  line-height: 1.625em;
  padding: 0.35714285714285715em; }

.widget_calendar #wp-calendar > tbody > tr > td.pad {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none; }

.widget_calendar #wp-calendar > tbody > tr > td.pad:first-child {
  border-left: 0; }

.widget_calendar #wp-calendar > tbody > tr > td.pad:last-child {
  border-right: 0; }

.widget_calendar #wp-calendar > tbody > tr > td > a {
  background: #5f5f5f;
  color: #ffffff;
  display: block;
  border-radius: 3px; }

.widget_calendar #wp-calendar > tfoot {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0; }

.widget_calendar #wp-calendar > tfoot .pad {
  border: none; }

.widget_calendar #wp-calendar #prev,
.widget_calendar #wp-calendar #next {
  font-weight: 300;
  padding: 0.3571428571em 0.7142857143em;
  text-shadow: none;
  text-transform: uppercase;
  position: absolute;
  top: 0; }

.widget_calendar #wp-calendar #prev > a,
.widget_calendar #wp-calendar #next > a {
  display: block;
  width: 40px;
  height: 40px;
  text-indent: -9999em;
  position: absolute;
  top: 0; }

.widget_calendar #wp-calendar #next {
  right: 0;
  padding-left: 1.0714285714285714em; }

.widget_calendar #wp-calendar #next:before {
  content: "\f105";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 5;
  opacity: 1;
  filter: alpha(opacity=100); }

.widget_calendar #wp-calendar #next > a {
  right: 0; }

.widget_calendar #wp-calendar #prev {
  left: 0;
  padding-left: 1.0714285714285714em; }

.widget_calendar #wp-calendar #prev:before {
  content: "\f104";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 5;
  opacity: 1;
  filter: alpha(opacity=100); }

.widget_calendar #wp-calendar #prev > a {
  left: 0; }

.widget_categories ul li {
  border-bottom: 1px solid #dbdada;
  text-align: right;
  overflow: hidden; }

.widget_categories ul li a {
  float: left; }

.widget_categories ul li a:before {
  font-family: "fontawesome";
  content: "\f0da";
  color: #fe6367;
  margin-right: 1.0714285714285714em; }

.widget_nav_menu ul li a,
.widget_meta ul li a,
.widget_pages ul li a {
  display: block; }

.widget_nav_menu ul li ul,
.widget_meta ul li ul,
.widget_pages ul li ul,
.widget_nav_menu ul li .sub-menu,
.widget_meta ul li .sub-menu,
.widget_pages ul li .sub-menu {
  border: 0;
  background-color: transparent;
  border-radius: 0; }

.widget_nav_menu ul li ul li a,
.widget_meta ul li ul li a,
.widget_pages ul li ul li a,
.widget_nav_menu ul li .sub-menu li a,
.widget_meta ul li .sub-menu li a,
.widget_pages ul li .sub-menu li a {
  padding-left: 2.142857142857143em; }

.widget_nav_menu ul li ul li:first-child a,
.widget_meta ul li ul li:first-child a,
.widget_pages ul li ul li:first-child a,
.widget_nav_menu ul li .sub-menu li:first-child a,
.widget_meta ul li .sub-menu li:first-child a,
.widget_pages ul li .sub-menu li:first-child a {
  border-radius: 0; }

.widget_nav_menu ul li ul ul li a,
.widget_meta ul li ul ul li a,
.widget_pages ul li ul ul li a,
.widget_nav_menu ul li .sub-menu ul li a,
.widget_meta ul li .sub-menu ul li a,
.widget_pages ul li .sub-menu ul li a {
  padding-left: 4.285714285714286em; }

.widget_nav_menu .menu > li:last-child a:first-child {
  border-bottom: 0; }

.widget_nav_menu .current-menu-item > a {
  background-color: transparent; }

.widget.widget_meta ul {
  border-bottom: 0; }

.widget.widget_meta li abbr[title] {
  border-bottom: 0; }

.widget.widget_pages ul {
  border-bottom: 0; }

.widget.widget_recent_comments li,
.widget.widget_recent_entries li,
.widget.widget_rss li {
  font-size: 14px; }

.widget.widget_recent_entries li a {
  display: block;
  border-bottom: 0;
  font-size: 14px;
  line-height: 1.7; }

.widget.widget_recent_entries li .post-date {
  display: block;
  color: #8e8e8e;
  font-weight: 300;
  font-size: 0.8571428571428571em; }

.widget.widget_rss .widget-title img[alt=RSS] {
  display: none !important;
  visibility: hidden !important; }

.widget.widget_rss .widget-title a:last-child:before {
  content: "\f143";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 8px;
  opacity: 0.5;
  filter: alpha(opacity=50); }

.widget.widget_rss li a {
  display: block;
  margin-top: 0.35714285714285715em;
  border-bottom: 0;
  font-size: 1.2857142857142858em;
  font-weight: 400; }

.widget.widget_rss li a:before {
  content: "\f08e";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 5px;
  opacity: 0.5;
  filter: alpha(opacity=50); }

.widget_rss .rss-date {
  display: block;
  margin-top: 0.35714285714285715em;
  text-transform: uppercase; }

.widget_rss .rss-date:not(:empty):before {
  content: "\f133";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 5px;
  opacity: 0.5;
  filter: alpha(opacity=50); }

.widget_rss .rssSummary {
  margin: 10px 0;
  font-size: 14px; }

.widget_rss cite {
  display: block;
  text-transform: uppercase; }

.widget_rss cite:not(:empty):before {
  content: "\f007";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 5px;
  opacity: 0.5;
  filter: alpha(opacity=50); }

.widget_tag_cloud,
.widget_product_tag_cloud {
  text-shadow: none; }

.widget_tag_cloud .tagcloud a,
.widget_product_tag_cloud .tagcloud a {
  margin: 0 5px 5px 0;
  color: #454545;
  background: #f4f4f4;
  border: 0;
  line-height: 20px;
  display: inline-block;
  font-size: 14px !important;
  padding: 0.3571428571em 0.7142857143em; }

.widget_tag_cloud .tagcloud a:hover,
.widget_product_tag_cloud .tagcloud a:hover {
  background: #fe6367;
  color: #ffffff; }

.recent-tweets li:before {
  content: "\f099";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 5px;
  opacity: 0.5;
  filter: alpha(opacity=50); }

.recent-tweets li:before {
  opacity: 1;
  font-size: 1.2857142857142858em;
  color: #8c8888;
  top: 2px; }

.recent-tweets li a {
  color: #fe6367 !important; }

.recent-tweets li a:hover {
  color: #000 !important; }

.recent-tweets .twitter_time {
  font-size: 0.8571428571428571em;
  border-bottom: 1px dotted #8d8d8d;
  color: #c2c2c2 !important; }

.recent-tweets .twitter_time:hover {
  color: #797979; }

.noo-event-widget .noo-event-item {
  float: left;
  width: 25%; }

.noo-event-widget .noo-event-item img {
  border-radius: 3px; }

@media (max-width: 767px) {
  .noo-event-widget .noo-event-item {
    width: 100%; } }

.noo-event-widget .noo-event-content {
  float: left;
  width: 75%;
  padding-left: 1.0714285714285714em;
  margin-bottom: 1.0714285714285714em; }

.noo-event-widget .noo-event-content h4 {
  margin-top: -5px;
  font-family: "PT Sans", sans-serif;
  font-size: 14px; }

.noo-event-widget .noo-event-content .content-meta span {
  line-height: 2em; }

@media (max-width: 767px) {
  .noo-event-widget .noo-event-content {
    width: 100%;
    padding-left: 0;
    padding-top: 2.142857142857143em; } }

.noo-event-category-widget ul li {
  display: block;
  list-style: none;
  position: relative; }

@media (max-width: 767px) {
  .noo-event-category-widget ul li {
    width: 100%; } }

.tweets-widget .recent-tweets li {
  margin-bottom: 1.0714285714285714em; }

.tweets-widget .recent-tweets .twitter_user a {
  font-weight: bold; }

.tweets-widget .recent-tweets .twitter_user img {
  width: 32px;
  height: auto;
  margin-right: 10px; }

.tweets-widget .recent-tweets .twitter_user .twitter_username {
  margin-left: 15px; }

.search-class-weekday {
  display: block;
  overflow: hidden;
  clear: both;
  position: relative;
  margin-bottom: 2.142857142857143em; }

.search-class-weekday span {
  width: 100%;
  font-weight: bold;
  float: left;
  padding: 10px 0; }

.search-class-weekday label {
  width: 50%;
  float: left;
  margin-bottom: 8px; }

.widget_class_slider {
  position: relative; }

@media (max-width: 991px) {
  .widget_class_slider .widget-class-slider-content .noo-class-slider-item .class-slider-featured img {
    width: 100%; } }

.widget_class_slider .widget-class-slider-content .noo-class-slider-item .class-slider-content {
  background: #f4f4f4;
  padding: 1.0714285714285714em; }

.widget_class_slider .widget-class-slider-content .noo-class-slider-item .class-slider-content .class-slider-category a {
  color: #fe6367; }

.widget_class_slider .widget-class-slider-content .noo-class-slider-item .class-slider-content .class-slider-category a:hover {
  color: #000; }

.widget_class_slider .widget-class-slider-content .noo-class-slider-item .class-slider-content .class-slider-title {
  font-size: 1.2857142857142858em;
  text-transform: uppercase; }

.widget_class_slider .slider-indicators {
  position: absolute;
  right: 2.142857142857143em;
  bottom: 120px; }

.widget_class_slider .slider-indicators a {
  -webkit-transition: all 0s ease 0s;
  -o-transition: all 0s ease 0s;
  transition: all 0s ease 0s;
  background: #fff;
  display: inline-block;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  float: left;
  margin: 0 5px; }

.widget_class_slider .slider-indicators a.selected {
  background: #fe6367; }

/**
 * 6.0 - Wordpress part
 *  6.4 - comments
 */
.comments-area {
  margin-top: 2.142857142857143em;
  padding: 2.142857142857143em 0; }

.comments-area > .comment-respond .comment-form-fields .comment-form-input {
  float: left; }

.comments-area > .comment-respond .comment-form-fields .comment-form-textarea label {
  display: none; }

@media (max-width: 767px) {
  .comments-area > .comment-respond .comment-form-fields .comment-form-input,
  .comments-area > .comment-respond .comment-form-fields .comment-form-textarea {
    float: none;
    padding: 0; }
  .comments-area > .comment-respond .comment-form-fields .comment-form-textarea label {
    display: block; }
  .comments-area > .comment-respond .comment-form-fields .comment-form-textarea textarea {
    margin-bottom: 20px;
    margin-top: 0px; } }

.comments-area > .comment-respond .comment-form-fields.comment-form-in-as .comment-form-textarea {
  float: none;
  padding: 0; }

.comments-area > .comment-respond .comment-form-fields.comment-form-in-as .comment-form-textarea label {
  display: block; }

.comments-area > .comment-respond .comment-form-fields.comment-form-in-as .comment-form-textarea textarea {
  margin-bottom: 20px;
  margin-top: 0px; }

.comments-area a:hover,
.comments-area a:focus {
  text-decoration: none; }

@media (max-width: 768px) {
  .comments-area {
    padding: 1.0714285714285714em; } }

.comments-title {
  text-transform: uppercase; }

.comments-title span {
  color: #fe6367; }

.comments-list,
.comments-list .children {
  margin: 0;
  padding: 0;
  list-style: none; }

li.comment {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #f3f3f3; }

@media (min-width: 768px) {
  .comments-list .children {
    padding-left: 100px;
    position: relative; } }

.comments-list .children li.comment {
  border-bottom: 0;
  border-top: 1px solid #f3f3f3; }

.comment-img {
  float: left; }

.comment-img img {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  z-index: 2;
  position: relative; }

@media (max-width: 767px) {
  .comment-img {
    float: none; } }

.comment-wrap {
  position: relative;
  padding: 2.142857142857143em 0; }

@media (max-width: 767px) {
  .comment-wrap {
    padding: 7%; } }

@media (max-width: 767px) {
  .comment-wrap {
    padding: 0; } }

.comment-block {
  padding: 0;
  margin: 0 0 0 100px; }

@media (max-width: 767px) {
  .comment-block {
    margin: 1.0714285714285714em 0; } }

.comment-header {
  padding-bottom: 0.42857142857142855em;
  display: inline-flex; }

.comment-header span.pull-right {
  margin-top: 6px; }

@media (max-width: 767px) {
  .comment-header {
    padding: 10px 0; } }

.comment-content {
  position: relative; }

.comment-author {
  margin-right: 1.0714285714285714em;
  font-weight: 700; }

.comment-author a {
  color: #5f5f5f;
  font-weight: bold; }

.comment-author a:hover {
  color: #fe6367; }

.ispostauthor {
  background: none repeat scroll 0 0 #fe6367;
  color: #ffffff;
  padding: 0px 5px;
  margin-left: 10px;
  border-radius: 3px; }

.comment-meta {
  position: relative;
  margin: 0;
  padding-left: 20px;
  padding-right: 15px;
  color: #8f8f8f; }

.comment-meta time:before {
  content: ".";
  position: absolute;
  font-size: 30px;
  top: -23px;
  left: 0;
  color: #fe6367;
  opacity: 0.7;
  filter: alpha(opacity=70); }

@media (max-width: 767px) {
  .comment-meta {
    padding-left: 10px;
    padding-right: 10px; } }

.comment-pending {
  padding: 0.4285714286em 0.8571428571em;
  text-align: center;
  color: #5f5f5f;
  background-color: #f4f4f4;
  border-radius: 3px; }

.comment-reply-link {
  border-radius: 3px;
  color: #696969;
  padding: 5px 0;
  opacity: 0.7;
  filter: alpha(opacity=70); }

.comment-reply-link i {
  margin-right: 0.7142857142857143em; }

.comment-reply-link:hover {
  color: #fe6367;
  opacity: 1;
  filter: alpha(opacity=100); }

#respond {
  margin-bottom: 50px;
  margin-top: 50px; }

#respond .required {
  position: relative;
  color: #5f5f5f; }

.comment-reply-title {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.7142857142857142em;
  font-family: 'PT Sans', serif;
  padding-bottom: 1.0714285714285714em; }

.comment-reply-title small {
  font-size: 14px; }

#commentform {
  margin-bottom: 0; }

.comment-notes:before,
.comment-notes:after {
  content: " ";
  display: table; }

.comment-notes:after {
  clear: both; }

.comment-notes:before,
.comment-notes:after {
  content: " ";
  display: table; }

.comment-notes:after {
  clear: both; }

.comment-notes,
.logged-in-as {
  margin-bottom: 20px; }

.comment-form-author,
.comment-form-email,
.comment-form-url,
.comment-form-rating,
.comment-form-comment {
  margin: 0; }

.comment-form-author label,
.comment-form-email label,
.comment-form-url label,
.comment-form-rating label,
.comment-form-comment label {
  font-weight: 300; }

.comment-form-author input,
.comment-form-email input,
.comment-form-url input {
  margin-bottom: 20px;
  font-style: italic;
  width: 100%; }

.comment-form-comment textarea {
  margin-bottom: 20px;
  margin-top: 0px;
  font-style: italic;
  height: 150px;
  resize: none;
  width: 100%; }

.form-submit {
  margin: 0; }

.form-submit input[type="submit"] {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 0px solid transparent;
  white-space: nowrap;
  background: #e7e7e7;
  padding: 0.7142857143em 3em;
  font-size: 14px;
  line-height: 1.7;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: border-color color 0.2s ease;
  -o-transition: border-color color 0.2s ease;
  transition: border-color color 0.2s ease;
  color: #ffffff;
  background-color: #fe6367;
  border-color: 0;
  padding: 10px 3.2142857143em;
  text-transform: uppercase;
  font-size: 13px; }

.form-submit input[type="submit"]:hover,
.form-submit input[type="submit"]:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.form-submit input[type="submit"]:hover,
.form-submit input[type="submit"]:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.form-submit input[type="submit"]:focus,
.form-submit input[type="submit"]:active:focus,
.form-submit input[type="submit"].active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.form-submit input[type="submit"]:hover,
.form-submit input[type="submit"]:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.form-submit input[type="submit"]:active,
.form-submit input[type="submit"].active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.form-submit input[type="submit"].disabled,
.form-submit input[type="submit"][disabled],
fieldset[disabled] .form-submit input[type="submit"] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-submit input[type="submit"].square {
  border-radius: 0; }

.form-submit input[type="submit"].pill {
  border-radius: 100em; }

.form-submit input[type="submit"].rounded {
  border-radius: 0.225em; }

.form-submit input[type="submit"] i {
  padding-right: 10px; }

.form-submit input[type="submit"].icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.form-submit input[type="submit"]:hover,
.form-submit input[type="submit"]:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.form-submit input[type="submit"]:hover,
.form-submit input[type="submit"]:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.form-submit input[type="submit"]:focus,
.form-submit input[type="submit"]:active:focus,
.form-submit input[type="submit"].active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.form-submit input[type="submit"]:hover,
.form-submit input[type="submit"]:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.form-submit input[type="submit"]:active,
.form-submit input[type="submit"].active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.form-submit input[type="submit"].disabled,
.form-submit input[type="submit"][disabled],
fieldset[disabled] .form-submit input[type="submit"] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-submit input[type="submit"].square {
  border-radius: 0; }

.form-submit input[type="submit"].pill {
  border-radius: 100em; }

.form-submit input[type="submit"].rounded {
  border-radius: 0.225em; }

.form-submit input[type="submit"] i {
  padding-right: 10px; }

.form-submit input[type="submit"].icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.form-submit input[type="submit"]:hover,
.form-submit input[type="submit"]:focus,
.form-submit input[type="submit"]:active,
.form-submit input[type="submit"].active,
.open > .dropdown-toggle.form-submit input[type="submit"] {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-submit input[type="submit"]:hover,
.form-submit input[type="submit"]:focus {
  outline: 0;
  background: #5f5f5f; }

.form-submit input[type="submit"]:hover,
.form-submit input[type="submit"]:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.form-submit input[type="submit"]:hover,
.form-submit input[type="submit"]:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.form-submit input[type="submit"]:focus,
.form-submit input[type="submit"]:active:focus,
.form-submit input[type="submit"].active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.form-submit input[type="submit"]:hover,
.form-submit input[type="submit"]:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.form-submit input[type="submit"]:active,
.form-submit input[type="submit"].active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.form-submit input[type="submit"].disabled,
.form-submit input[type="submit"][disabled],
fieldset[disabled] .form-submit input[type="submit"] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-submit input[type="submit"].square {
  border-radius: 0; }

.form-submit input[type="submit"].pill {
  border-radius: 100em; }

.form-submit input[type="submit"].rounded {
  border-radius: 0.225em; }

.form-submit input[type="submit"] i {
  padding-right: 10px; }

.form-submit input[type="submit"].icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.form-submit input[type="submit"]:hover,
.form-submit input[type="submit"]:focus,
.form-submit input[type="submit"]:active,
.form-submit input[type="submit"].active,
.open > .dropdown-toggle.form-submit input[type="submit"] {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-submit input[type="submit"]:hover,
.form-submit input[type="submit"]:focus {
  outline: 0;
  background: #5f5f5f; }

.form-submit input[type="submit"]:hover,
.form-submit input[type="submit"]:focus {
  background: #5f5f5f;
  color: #ffffff;
  border-color: #5f5f5f; }

/**
 * 6.0 - Wordpress part
 *  6.5 - pagination
 */
.pagination {
  display: table;
  padding-left: 0;
  margin: 1.6428571428571428em auto;
  line-height: 40px; }

.pagination .page-numbers {
  color: #454545;
  background: #f4f4f4;
  padding: 0 1.2em;
  text-transform: uppercase;
  float: left;
  font-weight: 700;
  margin: 0 5px;
  text-align: center; }

.pagination .page-numbers.current {
  background: #fe6367;
  color: #ffffff; }

.pagination .page-numbers.dots {
  border: none;
  border-radius: 0px;
  color: #5f5f5f;
  padding-left: 0.7142857142857143em;
  padding-right: 0.7142857142857143em; }

.pagination a.page-numbers {
  text-decoration: none;
  color: #5f5f5f; }

.pagination a.page-numbers:hover {
  background: #5f5f5f;
  color: #ffffff; }

.loadmore-action {
  display: block;
  width: 100%;
  text-align: center;
  margin: 1.6428571428571428em auto;
  line-height: 3;
  font-size: 14px; }

.loadmore-action .loadmore-loading {
  display: none; }

.loadmore-action ~ .pagination {
  display: none; }

.loadmore-loading {
  position: relative;
  height: 25px;
  width: 50px;
  margin-left: auto;
  margin-right: auto; }

.loadmore-loading.finished {
  width: auto; }

.loadmore-loading span {
  display: block;
  bottom: 0;
  width: 9px;
  height: 5px;
  background: #5f5f5f;
  position: absolute;
  -webkit-animation: preload_audio_wave 1.5s infinite ease-in-out;
  -o-animation: preload_audio_wave 1.5s infinite ease-in-out;
  animation: preload_audio_wave 1.5s infinite ease-in-out; }

.loadmore-loading span:nth-child(2) {
  left: 11px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }

.loadmore-loading span:nth-child(3) {
  left: 22px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }

.loadmore-loading span:nth-child(4) {
  left: 33px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s; }

.loadmore-loading span:nth-child(5) {
  left: 44px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s; }

@keyframes preload_audio_wave {
  0% {
    height: 5px;
    transform: translateY(0px);
    background: #fe6367; }
  25% {
    height: 30px;
    transform: translateY(15px);
    background: #fe6367; }
  50% {
    height: 5px;
    transform: translateY(0px);
    background: #fe6367; }
  100% {
    height: 5px;
    transform: translateY(0px);
    background: #fe6367; } }

@-webkit-keyframes preload_audio_wave {
  0% {
    height: 5px;
    -webkit-transform: translateY(0px);
    background: #fe6367; }
  25% {
    height: 30px;
    -webkit-transform: translateY(15px);
    background: #fe6367; }
  50% {
    height: 5px;
    -webkit-transform: translateY(0px);
    background: #fe6367; }
  100% {
    height: 5px;
    -webkit-transform: translateY(0px);
    background: #fe6367; } }

@-moz-keyframes preload_audio_wave {
  0% {
    height: 5px;
    -moz-transform: translateY(0px);
    background: #fe6367; }
  25% {
    height: 30px;
    -moz-transform: translateY(15px);
    background: #fe6367; }
  50% {
    height: 5px;
    -moz-transform: translateY(0px);
    background: #fe6367; }
  100% {
    height: 5px;
    -moz-transform: translateY(0px);
    background: #fe6367; } }

@keyframes preload_audio_wave {
  0% {
    height: 5px;
    transform: translateY(0px);
    background: #fe6367; }
  25% {
    height: 30px;
    transform: translateY(15px);
    background: #fe6367; }
  50% {
    height: 5px;
    transform: translateY(0px);
    background: #fe6367; }
  100% {
    height: 5px;
    transform: translateY(0px);
    background: #fe6367; } }

/**
 * 7.0 - Library
 *  7.1 - animations
 */
/******************
* Bounce in right *
*******************/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.bounceInRight,
.bounceInLeft,
.bounceInUp,
.bounceInDown {
  opacity: 0;
  -webkit-transform: translateX(400px);
  transform: translateX(400px); }

.fadeInRight,
.fadeInLeft,
.fadeInUp,
.fadeInDown {
  opacity: 0;
  -webkit-transform: translateX(400px);
  transform: translateX(400px); }

.flipInX,
.flipInY,
.rotateIn,
.rotateInUpLeft,
.rotateInUpRight,
.rotateInDownLeft,
.rotateDownUpRight,
.rollIn {
  opacity: 0; }

.lightSpeedInRight,
.lightSpeedInLeft {
  opacity: 0;
  -webkit-transform: translateX(400px);
  transform: translateX(400px); }

/***********
* bounceIn *
************/
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05); }
  70% {
    -webkit-transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1); } }

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3); }
  50% {
    opacity: 1;
    transform: scale(1.05); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

.bounceIn.go {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn; }

/****************
* bounceInRight *
****************/
@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(400px); }
  60% {
    -webkit-transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(400px); }
  60% {
    transform: translateX(-30px); }
  80% {
    transform: translateX(10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.bounceInRight.go {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight; }

/******************
* Bounce in left *
*******************/
@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-400px); }
  60% {
    -webkit-transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-400px); }
  60% {
    transform: translateX(30px); }
  80% {
    transform: translateX(-10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.bounceInLeft.go {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft; }

/******************
* Bounce in up *
*******************/
@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(400px); }
  60% {
    -webkit-transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(400px); }
  60% {
    transform: translateY(-30px); }
  80% {
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.bounceInUp.go {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp; }

/******************
* Bounce in down *
*******************/
@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-400px); }
  60% {
    -webkit-transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-400px); }
  60% {
    transform: translateY(30px); }
  80% {
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.bounceInDown.go {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown; }

/**********
* Fade In *
**********/
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
    display: block; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
    display: block; } }

.fadeIn {
  opacity: 0; }

.fadeIn.go {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

/**********
* Grow in *
***********/
@-webkit-keyframes growIn {
  0% {
    -webkit-transform: scale(0.2);
    opacity: 0; }
  50% {
    -webkit-transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
    opacity: 1; } }

@keyframes growIn {
  0% {
    transform: scale(0.2);
    opacity: 0; }
  50% {
    transform: scale(1.2); }
  100% {
    transform: scale(1);
    opacity: 1; } }

.growIn {
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  opacity: 0; }

.growIn.go {
  -webkit-animation-name: growIn;
  animation-name: growIn; }

/********
* Shake *
********/
@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: translateX(0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateX(-10px); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translateX(10px); } }

@keyframes shake {
  0%,
  100% {
    transform: translateX(0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px); }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px); } }

.shake.go {
  -webkit-animation-name: shake;
  animation-name: shake; }

/********
* ShakeUp *
********/
@-webkit-keyframes shakeUp {
  0%,
  100% {
    -webkit-transform: translateX(0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateY(-10px); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translateY(10px); } }

@keyframes shakeUp {
  0%,
  100% {
    transform: translateY(0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(-10px); }
  20%,
  40%,
  60%,
  80% {
    transform: translateY(10px); } }

.shakeUp.go {
  -webkit-animation-name: shakeUp;
  animation-name: shakeUp; }

/*************
* FadeInLeft *
*************/
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.fadeInLeft {
  opacity: 0;
  -webkit-transform: translateX(-400px);
  transform: translateX(-400px); }

.fadeInLeft.go {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

/*************
* FadeInRight *
*************/
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.fadeInRight {
  opacity: 0;
  -webkit-transform: translateX(400px);
  transform: translateX(400px); }

.fadeInRight.go {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

/*************
* FadeInUp *
*************/
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.fadeInUp {
  opacity: 0;
  -webkit-transform: translateY(400px);
  transform: translateY(400px); }

.fadeInUp.go {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

/*************
* FadeInDown *
*************/
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.fadeInDown {
  opacity: 0;
  -webkit-transform: translateY(-400px);
  transform: translateY(-400px); }

.fadeInDown.go {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

/*****************
* rotateIn *
*****************/
@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1; } }

@keyframes rotateIn {
  0% {
    transform-origin: center center;
    transform: rotate(-200deg);
    opacity: 0; }
  100% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1; } }

.rotateIn.go {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn; }

/*****************
* rotateInUpLeft *
*****************/
@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1; } }

.rotateInUpLeft.go {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft; }

/*******************
* rotateInDownLeft *
*******************/
@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1; } }

.rotateInDownLeft.go {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft; }

/******************
* rotateInUpRight *
*******************/
@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1; } }

.rotateInUpRight.go {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight; }

/********************
* rotateInDownRight *
********************/
@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1; } }

.rotateInDownRight.go {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight; }

/*********
* rollIn *
**********/
@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg); } }

@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg); } }

.rollIn.go {
  -webkit-animation-name: rollIn;
  animation-name: rollIn; }

/*********
* wiggle *
**********/
@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg); }
  10% {
    -webkit-transform: skewX(-8deg); }
  20% {
    -webkit-transform: skewX(7deg); }
  30% {
    -webkit-transform: skewX(-6deg); }
  40% {
    -webkit-transform: skewX(5deg); }
  50% {
    -webkit-transform: skewX(-4deg); }
  60% {
    -webkit-transform: skewX(3deg); }
  70% {
    -webkit-transform: skewX(-2deg); }
  80% {
    -webkit-transform: skewX(1deg); }
  90% {
    -webkit-transform: skewX(0deg); }
  100% {
    -webkit-transform: skewX(0deg); } }

@keyframes wiggle {
  0% {
    transform: skewX(9deg); }
  10% {
    transform: skewX(-8deg); }
  20% {
    transform: skewX(7deg); }
  30% {
    transform: skewX(-6deg); }
  40% {
    transform: skewX(5deg); }
  50% {
    transform: skewX(-4deg); }
  60% {
    transform: skewX(3deg); }
  70% {
    transform: skewX(-2deg); }
  80% {
    transform: skewX(1deg); }
  90% {
    transform: skewX(0deg); }
  100% {
    transform: skewX(0deg); } }

.wiggle.go {
  -webkit-animation-name: wiggle;
  animation-name: wiggle;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

/********
* swing *
*********/
@-webkit-keyframes swing {
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform-origin: top center; }
  20% {
    -webkit-transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg); } }

@keyframes swing {
  20% {
    transform: rotate(15deg); }
  40% {
    transform: rotate(-10deg); }
  60% {
    transform: rotate(5deg); }
  80% {
    transform: rotate(-5deg); }
  100% {
    transform: rotate(0deg); } }

.swing.go {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing; }

/*******
* tada *
********/
@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1); }
  10%,
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg); }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale(1.1) rotate(3deg); }
  40%,
  60%,
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0); } }

@keyframes tada {
  0% {
    transform: scale(1); }
  10%,
  20% {
    transform: scale(0.9) rotate(-3deg); }
  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(3deg); }
  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-3deg); }
  100% {
    transform: scale(1) rotate(0); } }

.tada.go {
  -webkit-animation-name: tada;
  animation-name: tada; }

/*********
* wobble *
**********/
@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%); } }

@keyframes wobble {
  0% {
    transform: translateX(0%); }
  15% {
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    transform: translateX(20%) rotate(3deg); }
  45% {
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    transform: translateX(10%) rotate(2deg); }
  75% {
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    transform: translateX(0%); } }

.wobble.go {
  -webkit-animation-name: wobble;
  animation-name: wobble; }

/********
* pulse *
*********/
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1); } }

@keyframes pulse {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

.pulse.go {
  -webkit-animation-name: pulse;
  animation-name: pulse; }

/***************
* lightSpeedInRight *
****************/
@-webkit-keyframes lightSpeedInRight {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    opacity: 1; }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1; } }

@keyframes lightSpeedInRight {
  0% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0; }
  60% {
    transform: translateX(-20%) skewX(30deg);
    opacity: 1; }
  80% {
    transform: translateX(0%) skewX(-15deg);
    opacity: 1; }
  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1; } }

.lightSpeedInRight.go {
  -webkit-animation-name: lightSpeedInRight;
  animation-name: lightSpeedInRight;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

/***************
* lightSpeedInLeft *
****************/
@-webkit-keyframes lightSpeedInLeft {
  0% {
    -webkit-transform: translateX(-100%) skewX(30deg);
    opacity: 0; }
  60% {
    -webkit-transform: translateX(20%) skewX(-30deg);
    opacity: 1; }
  80% {
    -webkit-transform: translateX(0%) skewX(15deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1; } }

@keyframes lightSpeedInLeft {
  0% {
    transform: translateX(-100%) skewX(30deg);
    opacity: 0; }
  60% {
    transform: translateX(20%) skewX(-30deg);
    opacity: 1; }
  80% {
    transform: translateX(0%) skewX(15deg);
    opacity: 1; }
  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1; } }

.lightSpeedInLeft.go {
  -webkit-animation-name: lightSpeedInLeft;
  animation-name: lightSpeedInLeft;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

/*******
* Flip *
*******/
@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in; } }

@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out; }
  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out; }
  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in; }
  80% {
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in; }
  100% {
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in; } }

.flip.go {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flip;
  backface-visibility: visible !important;
  animation-name: flip; }

/**********
* flipInX *
**********/
@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1; } }

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0; }
  40% {
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    transform: perspective(400px) rotateX(10deg); }
  100% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1; } }

.flipInX.go {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  backface-visibility: visible !important;
  animation-name: flipInX; }

/**********
* flipInY *
**********/
@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1; } }

@keyframes flipInY {
  0% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0; }
  40% {
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    transform: perspective(400px) rotateY(10deg); }
  100% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1; } }

.flipInY.go {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  backface-visibility: visible !important;
  animation-name: flipInY; }

/*****************
* Out animations *
*****************/
/************
* bounceOut *
*************/
@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3); } }

@keyframes bounceOut {
  0% {
    transform: scale(1); }
  25% {
    transform: scale(0.95); }
  50% {
    opacity: 1;
    transform: scale(1.1); }
  100% {
    opacity: 0;
    transform: scale(0.3); } }

.bounceOut.goAway {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut; }

/************
* bounceOutUp *
*************/
@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px); } }

@keyframes bounceOutUp {
  0% {
    transform: translateY(0); }
  20% {
    opacity: 1;
    transform: translateY(20px); }
  100% {
    opacity: 0;
    transform: translateY(-2000px); } }

.bounceOutUp.goAway {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp; }

/************
* bounceOutDown *
*************/
@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px); } }

@keyframes bounceOutDown {
  0% {
    transform: translateY(0); }
  20% {
    opacity: 1;
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    transform: translateY(2000px); } }

.bounceOutDown.goAway {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown; }

/************
* bounceOutLeft *
*************/
@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px); } }

@keyframes bounceOutLeft {
  0% {
    transform: translateX(0); }
  20% {
    opacity: 1;
    transform: translateX(20px); }
  100% {
    opacity: 0;
    transform: translateX(-2000px); } }

.bounceOutLeft.goAway {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft; }

/************
* bounceOutRight *
*************/
@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px); } }

@keyframes bounceOutRight {
  0% {
    transform: translateX(0); }
  20% {
    opacity: 1;
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    transform: translateX(2000px); } }

.bounceOutRight.goAway {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight; }

/************
* fadeOut *
*************/
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fadeOut.goAway {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

/************
* fadeOutUp *
*************/
@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px); } }

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(-2000px); } }

.fadeOutUp.goAway {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp; }

/************
* fadeOutDown *
*************/
@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px); } }

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(2000px); } }

.fadeOutDown.goAway {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown; }

/************
* fadeOutLeft *
*************/
@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px); } }

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(-2000px); } }

.fadeOutLeft.goAway {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft; }

/************
* fadeOutRight *
*************/
@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px); } }

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(2000px); } }

.fadeOutRight.goAway {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight; }

/************
* flipOutX *
*************/
@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0; } }

@keyframes flipOutX {
  0% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1; }
  100% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0; } }

.flipOutX.goAway {
  -webkit-animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  animation-name: flipOutX;
  backface-visibility: visible !important; }

/************
* flipOutY *
*************/
@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0; } }

@keyframes flipOutY {
  0% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1; }
  100% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0; } }

.flipOutY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  backface-visibility: visible !important;
  animation-name: flipOutY; }

/************
* lightSpeedOutRight *
*************/
@-webkit-keyframes lightSpeedOutRight {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0; } }

@keyframes lightSpeedOutRight {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1; }
  100% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0; } }

.lightSpeedOutRight.goAway {
  -webkit-animation-name: lightSpeedOutRight;
  animation-name: lightSpeedOutRight;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

/************
* lightSpeedOutLeft *
*************/
@-webkit-keyframes lightSpeedOutLeft {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-100%) skewX(30deg);
    opacity: 0; } }

@keyframes lightSpeedOutLeft {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1; }
  100% {
    transform: translateX(-100%) skewX(30deg);
    opacity: 0; } }

.lightSpeedOutLeft.goAway {
  -webkit-animation-name: lightSpeedOutLeft;
  animation-name: lightSpeedOutLeft;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

/************
* rotateOut *
*************/
@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(200deg);
    opacity: 0; } }

@keyframes rotateOut {
  0% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1; }
  100% {
    transform-origin: center center;
    transform: rotate(200deg);
    opacity: 0; } }

.rotateOut.goAway {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut; }

/************
* rotateOutUpLeft *
*************/
@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0; } }

@keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1; }
  100% {
    -transform-origin: left bottom;
    -transform: rotate(-90deg);
    opacity: 0; } }

.rotateOutUpLeft.goAway {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft; }

/************
* rotateOutDownLeft *
*************/
@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0; } }

@keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1; }
  100% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0; } }

.rotateOutDownLeft.goAway {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft; }

/************
* rotateOutUpRight *
*************/
@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0; } }

@keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1; }
  100% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0; } }

.rotateOutUpRight.goAway {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight; }

/************
* rollOut *
*************/
@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg); } }

@keyframes rollOut {
  0% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    transform: translateX(100%) rotate(120deg); } }

.rollOut.goAway {
  -webkit-animation-name: rollOut;
  animation-name: rollOut; }

/*
 * 7.0 - Library
 *  7.2 - carousel
 */
.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%; }

.carousel-inner > .item {
  display: none;
  position: relative;
  -webkit-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left; }

.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  line-height: 1; }

@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    transition: transform 0.6s ease-in-out;
    backface-visibility: hidden;
    perspective: 1000; }
  .carousel-inner > .item.next,
  .carousel-inner > .item.active.right {
    transform: translate3d(100%, 0, 0);
    left: 0; }
  .carousel-inner > .item.prev,
  .carousel-inner > .item.active.left {
    transform: translate3d(-100%, 0, 0);
    left: 0; }
  .carousel-inner > .item.next.left,
  .carousel-inner > .item.prev.right,
  .carousel-inner > .item.active {
    transform: translate3d(0, 0, 0);
    left: 0; } }

.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block; }

.carousel-inner > .active {
  left: 0; }

.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%; }

.carousel-inner > .next {
  left: 100%; }

.carousel-inner > .prev {
  left: -100%; }

.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0; }

.carousel-inner > .active.left {
  left: -100%; }

.carousel-inner > .active.right {
  left: 100%; }

.carousel.fade {
  opacity: 1;
  filter: alpha(opacity=100); }

.carousel.fade .carousel-inner .item {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition-property: opacity;
  transition-property: opacity; }

.carousel.fade .carousel-inner .active {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 1;
  display: block; }

.carousel.fade .carousel-inner .active.left,
.carousel.fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 1; }

.carousel.fade .carousel-inner .next.left,
.carousel.fade .carousel-inner .prev.right {
  opacity: 1;
  filter: alpha(opacity=100);
  display: block; }

.carousel-control {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 15%;
  opacity: 0.5;
  filter: alpha(opacity=50);
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6); }

.carousel-control.left {
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1); }

.carousel-control.right {
  left: auto;
  right: 0;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1); }

.carousel-control:hover,
.carousel-control:focus {
  outline: 0;
  color: #ffffff;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90); }

.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block; }

.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
  left: 50%;
  margin-left: -10px; }

.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
  right: 50%;
  margin-right: -10px; }

.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  font-family: serif; }

.carousel-control .icon-prev:before {
  content: '\2039'; }

.carousel-control .icon-next:before {
  content: '\203a'; }

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  text-align: center; }

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  cursor: pointer;
  background-color: #000 \9;
  background-color: transparent; }

.carousel-indicators .active {
  margin: 0;
  width: 12px;
  height: 12px;
  background-color: #ffffff; }

.carousel-caption {
  position: absolute;
  left: 15%;
  right: 15%;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6); }

.carousel-caption .btn,
.carousel-caption .widget_newsletterwidget .newsletter-submit {
  text-shadow: none; }

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    font-size: 30px; }
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -15px; }
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -15px; }
  .carousel-caption {
    left: 20%;
    right: 20%;
    padding-bottom: 30px; }
  .carousel-indicators {
    bottom: 20px; } }

/**
 * 7.0 - Library
 *  7.3 - caroufredsel
 */
.noo-slider {
  position: relative; }

.noo-slider .caroufredsel_wrapper {
  position: relative;
  height: auto !important; }

.noo-slider .caroufredsel_wrapper .sliders {
  list-style: none;
  margin: 0;
  padding: 0;
  height: auto !important;
  position: relative !important; }

.noo-slider .caroufredsel_wrapper .sliders .slide-item {
  float: left;
  position: relative;
  height: auto;
  width: 100%; }

.noo-slider .caroufredsel_wrapper .sliders .slide-item img {
  display: block;
  max-width: 100%;
  width: 100%; }

.noo-slider .slider-timer {
  background: none repeat scroll 0 0 #EFEDED;
  height: 2px;
  left: 0;
  position: absolute;
  top: 0; }

.noo-slider .slider-indicators {
  position: absolute;
  top: 10px;
  right: 10px; }

.noo-slider .slider-indicators a {
  -webkit-transition: all 0s ease 0s;
  -o-transition: all 0s ease 0s;
  transition: all 0s ease 0s;
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.5);
  display: inline-block;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  float: left;
  margin: 0 5px; }

.noo-slider .slider-indicators a.selected {
  background: #fff; }

.noo-slider.bottom-indicators {
  padding-bottom: 15px; }

.noo-slider.bottom-indicators .slider-indicators {
  right: auto;
  top: auto;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

.noo-slider .slider-control {
  font-size: 30px;
  color: #ffffff;
  opacity: 0.7;
  filter: alpha(opacity=70);
  position: absolute;
  left: 0;
  background: #fe6367;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0 17px;
  -webkit-transition: all 0s ease 0s;
  -o-transition: all 0s ease 0s;
  transition: all 0s ease 0s;
  z-index: 1000; }

.noo-slider .slider-control.prev-btn .slider-icon-prev:before {
  content: "\f104";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none; }

.noo-slider .slider-control.next-btn {
  right: 0;
  left: auto; }

.noo-slider .slider-control.next-btn .slider-icon-next:before {
  content: "\f105";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none; }

.noo-slider .slider-control:hover {
  opacity: 0.9;
  filter: alpha(opacity=90); }

.noo-slider .our-customer {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-weight: 300; }

.noo-slider .our-customer .customer-avatar {
  margin: 0 auto;
  text-align: center; }

.noo-slider .our-customer .customer-avatar img {
  width: auto !important;
  margin: 0 auto;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.4); }

.noo-slider .our-customer .custom-desc h5 {
  font-size: 14px;
  color: #fff;
  font-weight: bold; }

.noo-slider .our-customer .custom-desc p {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300; }

.noo-slider .our-customer .custom-desc i {
  padding: 2em 0;
  color: #fff;
  display: block; }

.noo-slider.about-slider .about-dreamer h3 {
  margin-top: 0.5;
  margin-bottom: 4em;
  position: relative; }

.noo-slider.about-slider .about-dreamer h3:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 3px;
  background: #000;
  bottom: -50px; }

@media (max-width: 767px) {
  .noo-slider.about-slider .about-dreamer .about-desc {
    margin-bottom: 50px; } }

.noo-slider.about-slider .about-dreamer .about-img {
  padding: 50px 90px 0 50px;
  position: relative; }

.noo-slider.about-slider .about-dreamer .about-img img {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: relative; }

.noo-slider.about-slider .about-dreamer .about-img:after {
  background: #fffbfb;
  content: "";
  width: auto;
  top: -50px;
  left: 100px;
  right: -100px;
  bottom: 50px;
  position: absolute;
  z-index: 1; }

@media (max-width: 767px) {
  .noo-slider.about-slider .about-dreamer .about-img:after {
    top: -20px;
    left: 40px;
    right: -40px;
    bottom: 20px; } }

@media (max-width: 767px) {
  .noo-slider.about-slider .about-dreamer .about-img {
    padding: 20px 40px 0 20px; } }

.noo-slider.about-slider .slider-control {
  left: 0;
  bottom: 50px;
  top: auto;
  opacity: 1;
  border-radius: 2px;
  background: #ebebeb;
  color: #5f5f5f;
  font-size: 24px;
  padding: 0 15px; }

.noo-slider.about-slider .slider-control.next-btn {
  right: auto;
  left: 58px; }

.noo-slider.about-slider .slider-control.prev-btn {
  margin-left: 15px; }

.noo-slider.about-slider .slider-control:hover {
  background: #fe6367;
  color: #fff; }

@media (max-width: 991px) {
  .noo-slider.about-slider .slider-control {
    bottom: -50px; } }

@media (max-width: 767px) {
  .noo-slider.about-slider .slider-control {
    bottom: -80px; } }

.noo-content li {
  float: left; }

.noo-thumb {
  text-align: center;
  padding: 0;
  margin: 0;
  list-style: none; }

.noo-thumb li {
  float: left;
  width: 90px; }

/**
 * 7.0 - Library
 *  7.4 - jplayer
 */
/* GENERAL
----------------------------------------------- */
.jp-jplayer {
  width: 0px;
  height: 0px;
  background-color: #000000;
  position: absolute; }

.jp-jplayer a:hover {
  text-decoration: none; }

.jp-controls-container {
  margin: 0 auto;
  width: 100%;
  max-width: 100%; }

.jp-video {
  position: absolute;
  bottom: 0; }

.jp-interface {
  position: relative;
  height: 30px;
  width: 100%;
  background-image: -webkit-linear-gradient(top, #3a3b3c 0%, #222222 100%);
  background-image: -o-linear-gradient(top, #3a3b3c 0%, #222222 100%);
  background-image: linear-gradient(to bottom, #3a3b3c 0%, #222222 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3a3b3c', endColorstr='#ff222222', GradientType=0);
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 3px; }

/* CONTROLS
----------------------------------------------- */
.jp-controls {
  list-style-type: none;
  padding: 0;
  margin: 0; }

.jp-controls a {
  display: block;
  position: absolute;
  top: 0;
  overflow: hidden;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #5f5f5f;
  z-index: 1; }

.jp-controls a:hover {
  color: #5f5f5f; }

.jp-controls a:before {
  content: "";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  display: block;
  line-height: 30px; }

.jp-controls .jp-play,
.jp-controls .jp-pause {
  border-right: 1px solid #323334; }

.jp-controls .jp-play:before {
  content: "\f04b"; }

.jp-controls .jp-pause {
  display: none; }

.jp-controls .jp-pause:before {
  content: "\f04c"; }

.jp-controls .jp-mute,
.jp-controls .jp-unmute {
  border-left: 1px solid #323334;
  display: block;
  position: absolute;
  top: 0;
  right: 55px; }

.jp-controls .jp-mute:before {
  content: "\f028"; }

.jp-controls .jp-unmute:before {
  content: "\f026"; }

/* PROGRESS BAR
----------------------------------------------- */
.jp-progress {
  position: absolute;
  padding: 0 95px 0 40px;
  margin-top: 11px;
  margin-bottom: 11px;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  height: 10px; }

.jp-seek-bar,
.jp-play-bar {
  width: 0px;
  height: 8px;
  border-radius: 1px; }

.jp-seek-bar {
  cursor: pointer;
  position: relative;
  z-index: 999;
  background: #464849; }

.jp-play-bar {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #5f5f5f;
  -webkit-animation: progress 0.75s linear infinite;
  -o-animation: progress 0.75s linear infinite;
  animation: progress 0.75s linear infinite; }

/* VOLUME BAR
----------------------------------------------- */
.jp-volume-bar {
  float: right;
  z-index: 99;
  position: relative;
  margin: 11px 10px 11px;
  width: 40px;
  height: 8px;
  cursor: pointer;
  background-color: #464849;
  -webkit-box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1);
  border-radius: 1px; }

.jp-volume-bar-value {
  background-color: #5f5f5f;
  width: 0px;
  height: 8px;
  margin: 1px;
  border-radius: 1px; }

/* Diagonal Background Bars: got it from Premium Pixels
https://github.com/lukemcdonald/jplayer-skins
/*/
.jp-play-bar,
.jp-volume-bar-value {
  -webkit-box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.3);
  -webkit-background-size: 7px 7px;
  -moz-background-size: 7px 7px;
  -o-background-size: 7px 7px;
  background-size: 7px 7px;
  /*#gradient.vertical(@jp-diagonal-bar-color-1, @jp-diagonal-bar-color-2);
  background-image: -webkit-gradient(
                        linear, 0 0, 100% 100%,
                        color-stop(.25, @jp-diagonal-bar-color-3), 
                        color-stop(.25, transparent),
                        color-stop(.50, transparent), 
                        color-stop(.50, @jp-diagonal-bar-color-3),
                        color-stop(.75, @jp-diagonal-bar-color-3), 
                        color-stop(.75, transparent),
                        to(transparent)
                    );
  background-image: -webkit-linear-gradient(
                        -45deg, @jp-diagonal-bar-color-3 25%, 
                        transparent 25%,
                        transparent 50%, 
                        @jp-diagonal-bar-color-3 50%, 
                        @jp-diagonal-bar-color-3 75%,
                        transparent 75%, 
                        transparent
                    );
  background-image: -moz-linear-gradient(
                        -45deg, 
                        @jp-diagonal-bar-color-3 25%, 
                        transparent 25%,
                        transparent 50%, 
                        @jp-diagonal-bar-color-3 50%, 
                        @jp-diagonal-bar-color-3 75%,
                        transparent 75%, 
                        transparent
                    );
  background-image: -ms-linear-gradient(
                        -45deg, 
                        @jp-diagonal-bar-color-3 25%, 
                        transparent 25%,
                        transparent 50%, 
                        @jp-diagonal-bar-color-3 50%, 
                        @jp-diagonal-bar-color-3 75%,
                        transparent 75%, 
                        transparent
                    );
  background-image: -o-linear-gradient(
                        -45deg, 
                        @jp-diagonal-bar-color-3 25%, 
                        transparent 25%,
                        transparent 50%, 
                        @jp-diagonal-bar-color-3 50%, 
                        @jp-diagonal-bar-color-3 75%,
                        transparent 75%, 
                        transparent
                    );
  background-image: linear-gradient(
                        -45deg, 
                        @jp-diagonal-bar-color-3 25%, 
                        transparent 25%,
                        transparent 50%,
                        @jp-diagonal-bar-color-3 50%,
                        @jp-diagonal-bar-color-3 75%,
                        transparent 75%,
                        transparent
                    );*/ }

/* CSS3 ANIMATIONS
----------------------------------------------- */
/* Progress Bar Animation */
@-webkit-keyframes progress {
  0% {
    background-position: 0; }
  100% {
    background-position: -7px; } }

@-moz-keyframes progress {
  0% {
    background-position: 0; }
  100% {
    background-position: -7px; } }

@-ms-keyframes progress {
  0% {
    background-position: 0; }
  100% {
    background-position: -7px; } }

@-o-keyframes progress {
  0% {
    background-position: 0; }
  100% {
    background-position: -7px; } }

/* Video Container */
.noo-video-container {
  position: relative; }

.noo-video-container .jp-video-play-icon {
  color: #fff;
  font-size: 100px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1000; }

.noo-video-container .jp-video-play-icon a,
.noo-video-container .jp-video-play-icon a:hover,
.noo-video-container .jp-video-play-icon a:focus {
  color: #fff;
  outline: none; }

.noo-video-container .jp-video-play-icon a i,
.noo-video-container .jp-video-play-icon a:hover i,
.noo-video-container .jp-video-play-icon a:focus i {
  border: 5px solid #fff;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  height: 200px;
  line-height: 200px;
  text-align: center;
  width: 200px; }

.noo-video-container .jp-video-play-icon a i:before,
.noo-video-container .jp-video-play-icon a:hover i:before,
.noo-video-container .jp-video-play-icon a:focus i:before {
  margin-left: 25px; }

.noo-video-container .video-inner {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden; }

.noo-video-container .video-inner video,
.noo-video-container .video-inner iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/**
 * 7.0 - Library
 *  7.5 - masonry
 */
.masonry {
  position: relative; }

.container-portfolio.container-boxed.offset {
  margin-top: 0; }

.masonry-portfolio.no-title .masonry-item > .content-wrap {
  display: none; }

.container-fullwidth .masonry-portfolio {
  padding: 0; }

.masory-item-wrap {
  position: relative; }

.masonry-item {
  z-index: 2;
  float: left;
  padding-left: 1.0714285714285714em !important;
  padding-right: 1.0714285714285714em !important; }

.masonry-hidden.masonry-item {
  pointer-events: none;
  z-index: 1; }

.masonry-header {
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: #fff;
  margin-right: 1.0714285714285714em; }

.masonry-header.masonry-header-gap {
  margin-bottom: 40px; }

.masonry-header .masonry-result {
  float: left;
  max-width: 30%; }

.masonry-header .masonry-result h3 {
  margin: 0;
  padding: 0; }

.masonry-header .masonry-filters {
  float: left;
  width: 100%; }

.masonry-header .masonry-filters ul {
  list-style: none; }

.masonry-header .masonry-filters ul li {
  display: inline-block;
  padding: 0 30px; }

.masonry-header .masonry-filters ul li a {
  color: #5f5f5f;
  position: relative;
  font-size: 14px; }

.masonry-header .masonry-filters ul li a:hover,
.masonry-header .masonry-filters ul li a.selected {
  color: #fe6367;
  text-decoration: none; }

.masonry-header .masonry-filters ul li:first-child > a:before {
  display: none; }

@media (max-width: 991px) {
  .masonry-header .masonry-filters ul li {
    padding: 0 2.1428571429em 0 0;
    line-height: 40px; } }

.masonry-container {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  position: relative; }

.masonry-overlay {
  background-color: black;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
  display: none;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  opacity: 0.5;
  pointer-events: none; }

.masonry-container > .masonry-item {
  margin: 0 0 2.1428571429em 0;
  padding: 0 0px 2.1428571429em;
  z-index: 1; }

.masonry-style-elevated .masonry-container > .masonry-item-hover {
  position: relative;
  z-index: 1000; }

.masonry-style-elevated .masonry-container > .masonry-item-hover .content-featured {
  -webkit-transform: translateY(-65px);
  -moz-transform: translateY(-65px);
  -ms-transform: translateY(-65px);
  transform: translateY(-65px); }

.masonry-portfolio .content-featured {
  margin-bottom: 0; }

.masonry-portfolio .portfolio-action,
.masonry-portfolio .title-line {
  display: none; }

.masonry-portfolio .content-date-portfolio {
  font-size: 0.8571428571428571em; }

.masonry-portfolio.no-gap {
  margin-top: 0px; }

.masonry-portfolio.no-gap .masonry-container .content-featured {
  -webkit-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  margin-bottom: 0;
  position: relative;
  z-index: 100; }

.masonry-portfolio.no-gap .masonry-container > .masonry-item {
  padding: 0; }

.masonry-style-vibrant .masonry-portfolio .masonry-container .hentry {
  border: none;
  background: transparent; }

.masonry-style-vibrant .masonry-portfolio .masonry-container .hentry:after {
  height: 0; }

.masonry-style-vibrant .masonry-portfolio .masonry-container > .masonry-item {
  margin: 0 0 2.1428571429em;
  padding-bottom: 0;
  position: relative;
  overflow: hidden; }

.masonry-style-vibrant .masonry-portfolio .masonry-container > .masonry-item:hover .content-wrap,
.masonry-style-vibrant .masonry-portfolio .masonry-container > .masonry-item:hover .portfolio-action {
  opacity: 1;
  filter: alpha(opacity=100); }

.masonry-style-vibrant .masonry-portfolio .masonry-container > .masonry-item:hover .content-category-portfolio,
.masonry-style-vibrant .masonry-portfolio .masonry-container > .masonry-item:hover .content-title-portfolio {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0); }

.masonry-style-vibrant .masonry-portfolio .masonry-container > .masonry-item .noo-slider .slider-control {
  background: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  padding: 5px 13px; }

.masonry-style-vibrant .masonry-portfolio .masonry-container > .masonry-item .noo-slider .slider-control .slider-icon-next:before {
  content: "\f18e"; }

.masonry-style-vibrant .masonry-portfolio .masonry-container > .masonry-item .noo-slider .slider-control .slider-icon-prev:before {
  content: "\f190"; }

.masonry-style-vibrant .masonry-portfolio .masonry-container .content-wrap {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  padding: 15px;
  opacity: 0;
  filter: alpha(opacity=0); }

.masonry-style-vibrant .masonry-portfolio .masonry-container .content-wrap .content-header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9; }

.masonry-style-vibrant .masonry-portfolio .masonry-container .content-wrap .portfolio-action {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  margin-top: 5px; }

.masonry-style-vibrant .masonry-portfolio .masonry-container .content-wrap .portfolio-action .content-share-portfolio {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.masonry-style-vibrant .masonry-portfolio .masonry-container .content-wrap .portfolio-action .content-share-portfolio a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  font-size: 2em;
  margin: 0 0.31234em; }

.masonry-style-vibrant .masonry-portfolio .masonry-container .content-wrap .portfolio-action .content-share-portfolio .noo-social {
  line-height: 1;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.masonry-style-vibrant .masonry-portfolio .masonry-container .content-wrap .portfolio-action .content-share-portfolio .noo-social a {
  text-decoration: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  width: 100%;
  text-align: center;
  height: 58px; }

.masonry-style-vibrant .masonry-portfolio .masonry-container .content-wrap .portfolio-action .content-share-portfolio .noo-social a i {
  background: rgba(0, 0, 0, 0.4);
  height: 45px;
  line-height: 45px;
  width: 45px;
  border-radius: 50%;
  color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.37);
  border: 3px solid rgba(0, 0, 0, 0.8);
  font-size: 20px;
  margin-top: 7px; }

.masonry-style-vibrant .masonry-portfolio .masonry-container .content-wrap .portfolio-action .content-share-portfolio .noo-social a i:before {
  position: absolute;
  background: rgba(0, 0, 0, 0.37);
  height: 58px;
  line-height: 58px;
  width: 58px;
  border-radius: 50%;
  color: #fff;
  left: 50%;
  top: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

.masonry-style-vibrant .masonry-portfolio .masonry-container .content-wrap .portfolio-action .content-share-portfolio .noo-social a:first-child {
  margin-left: 0px; }

.masonry-style-vibrant .masonry-portfolio .masonry-container .content-wrap .portfolio-action .content-share-portfolio .noo-social a.noo-share:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.masonry-style-vibrant .masonry-portfolio .masonry-container .content-wrap:after {
  background-image: url(../images/bg-portfolio-item.png);
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.masonry-style-vibrant .masonry-portfolio.no-gap .masonry-container > .masonry-item {
  margin: 0; }

.masonry-style-vibrant .masonry-portfolio .masonry-container .media-gallery .content-wrap .portfolio-action .content-share-portfolio .noo-social a {
  text-decoration: none;
  position: absolute;
  top: 0;
  -webkit-transform: none;
  transform: none;
  margin: 0 auto;
  left: 0;
  width: 100%;
  text-align: center;
  height: 100%; }

.masonry-style-default .masonry-portfolio .masonry-container .hentry {
  border: none; }

.masonry-style-default .masonry-portfolio .masonry-container > .masonry-item {
  margin: 0 0 40px;
  padding-bottom: 0;
  position: relative;
  overflow: hidden; }

.masonry-style-default .masonry-portfolio .masonry-container .content-wrap .content-title {
  margin: 10px 0 5px; }

.masonry-style-default .masonry-portfolio .masonry-container .content-wrap .content-category-portfolio a {
  color: #5f5f5f; }

.masonry-style-default .masonry-portfolio.no-gap .masonry-container > .masonry-item {
  margin: 0; }

.masonry-container.columns-1 > .regular {
  width: 100%; }

.masonry-container.columns-2 > .regular {
  width: 50%; }

.masonry-container.columns-2 > .wide {
  width: 100%; }

.masonry-container.columns-3 > .regular {
  width: 33.333%; }

@media (max-width: 992px) {
  .masonry-container.columns-3 > .regular {
    width: 50%; } }

.masonry-container.columns-3 > .wide {
  width: 66.666%; }

@media (max-width: 992px) {
  .masonry-container.columns-3 > .wide {
    width: 100%; } }

.masonry-container.columns-4 > .regular {
  width: 25%; }

@media (max-width: 992px) {
  .masonry-container.columns-4 > .regular {
    width: 50%; } }

.masonry-container.columns-4 > .wide {
  width: 50%; }

@media (max-width: 992px) {
  .masonry-container.columns-4 > .wide {
    width: 100%; } }

@media (max-width: 767px) {
  .masonry-container[class*="columns-"] > .masonry-item {
    width: 100% !important; } }

.masonry-portfolio.no-gap .masonry-container {
  margin: 0 !important; }

.masonry-portfolio.no-gap .masonry-item {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.masonry-portfolio.no-gap .masonry-container.columns-1 > .regular {
  width: 100%; }

.masonry-portfolio.no-gap .masonry-container.columns-2 > .regular {
  width: 50%; }

.masonry-portfolio.no-gap .masonry-container.columns-2 > .wide {
  width: 100%; }

.masonry-portfolio.no-gap .masonry-container.columns-3 > .regular {
  width: 33.3333%; }

@media (max-width: 767px) {
  .masonry-portfolio.no-gap .masonry-container.columns-3 > .regular {
    width: 50%; } }

.masonry-portfolio.no-gap .masonry-container.columns-3 > .wide {
  width: 66.6666%; }

@media (max-width: 767px) {
  .masonry-portfolio.no-gap .masonry-container.columns-3 > .wide {
    width: 50%; } }

.masonry-portfolio.no-gap .masonry-container.columns-4 > .regular {
  width: 25%; }

@media (max-width: 992px) {
  .masonry-portfolio.no-gap .masonry-container.columns-4 > .regular {
    width: 50%; } }

.masonry-portfolio.no-gap .masonry-container.columns-4 > .wide {
  width: 50%; }

@media (max-width: 992px) {
  .masonry-portfolio.no-gap .masonry-container.columns-4 > .wide {
    width: 100%; } }

/*
 * 8.0 - Plugin
 *  8.2 - contact-form-7
 */
.wpcf7-form-control:not(.form-submit) {
  margin-bottom: 25px; }

.wpcf7-form-control:not(.form-submit).wpcf7-checkbox,
.wpcf7-form-control:not(.form-submit).wpcf7-radio {
  height: auto; }

.wpcf7-form-control:not(.form-submit).wpcf7-textarea {
  height: 160px; }

.colophon.wigetized .wpcf7-form-control:not(.form-submit) {
  margin-bottom: 10px; }

.wpcf7-form-control-wrap {
  display: block !important;
  width: 100% !important;
  margin-bottom: 0.8571428571428571em; }

div.wpcf7 .screen-reader-response {
  border: 0 none;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.form-submit {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 0px solid transparent;
  white-space: nowrap;
  background: #e7e7e7;
  text-transform: uppercase;
  padding: 0.7142857143em 3em;
  font-size: 14px;
  line-height: 1.7;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: border-color color 0.2s ease;
  -o-transition: border-color color 0.2s ease;
  transition: border-color color 0.2s ease;
  color: #ffffff;
  background-color: #fe6367;
  border-color: 0;
  width: auto; }

.form-submit:hover,
.form-submit:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.form-submit:hover,
.form-submit:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.form-submit:focus,
.form-submit:active:focus,
.form-submit.active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.form-submit:hover,
.form-submit:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.form-submit:active,
.form-submit.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.form-submit.disabled,
.form-submit[disabled],
fieldset[disabled] .form-submit {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-submit.square {
  border-radius: 0; }

.form-submit.pill {
  border-radius: 100em; }

.form-submit.rounded {
  border-radius: 0.225em; }

.form-submit i {
  padding-right: 10px; }

.form-submit.icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.form-submit:hover,
.form-submit:focus,
.form-submit:active,
.form-submit.active,
.open > .dropdown-toggle.form-submit {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-submit:hover,
.form-submit:focus {
  outline: 0;
  background: #5f5f5f; }

.form-submit:hover,
.form-submit:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.form-submit:hover,
.form-submit:focus {
  outline: 0;
  background: #5f5f5f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.form-submit:focus,
.form-submit:active:focus,
.form-submit.active:focus {
  outline-offset: -2px;
  -webkit-box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  box-shadow: 0 0 2px rgba(117, 176, 138, 0.8);
  border-color: #fe6367; }

.form-submit:hover,
.form-submit:focus {
  background: #fe6367;
  color: #ffffff;
  border-color: #fe6367; }

.form-submit:active,
.form-submit.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.form-submit.disabled,
.form-submit[disabled],
fieldset[disabled] .form-submit {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-submit.square {
  border-radius: 0; }

.form-submit.pill {
  border-radius: 100em; }

.form-submit.rounded {
  border-radius: 0.225em; }

.form-submit i {
  padding-right: 10px; }

.form-submit.icon-right i {
  padding-left: 10px;
  padding-right: 0px; }

.form-submit:hover,
.form-submit:focus,
.form-submit:active,
.form-submit.active,
.open > .dropdown-toggle.form-submit {
  color: #ffffff;
  background-color: #fe6367;
  border-color: #fe6367;
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-submit:hover,
.form-submit:focus {
  outline: 0;
  background: #5f5f5f; }

.colophon .form-submit {
  height: auto;
  font-size: 1.2857142857142858em;
  font-weight: normal;
  padding: 5px 50px;
  width: auto; }

.wpcf7-not-valid-tip {
  color: #f56e6e;
  font-size: 0.8571428571428571em;
  display: block; }

.wpcf7-display-none {
  display: none; }

div.wpcf7-response-output {
  padding: 0.2857142857em 0.5714285714em;
  text-align: right;
  margin-top: 0.35714285714285715em;
  font-size: 0.8571428571428571em; }

div.wpcf7-validation-errors {
  border: 1px solid #f56e6e; }

.contact-actions {
  display: block;
  text-align: right; }

.contact-form:before {
  content: "";
  background: #eee;
  width: 98%;
  margin-right: 1.0714285714285714em;
  height: 1px;
  margin-top: -50px;
  left: 0;
  z-index: 999;
  margin-bottom: 50px; }

@media (max-width: 767px) {
  .contact-form .contact-desc {
    padding-left: 0 !important; } }

.contact-info-title {
  margin-bottom: 30px; }

.contact-info-title:after {
  content: "";
  width: 200px;
  height: 1px;
  position: absolute;
  background: #ebebeb;
  left: 50%;
  margin-left: -100px;
  bottom: -15px; }

.noo-gmaps ~ .contact-info {
  z-index: 99;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  right: 20%; }

.noo_event_google_map {
  height: 600px;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  width: 100%; }

@media (max-width: 767px) {
  .noo-gmaps ~ .contact-info {
    position: static;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    padding: 30px 0;
    background: #f4f4f4; } }

.noo-gmaps ~ .contact-info ul {
  padding: 0;
  margin: 0; }

.noo-gmaps ~ .contact-info ul li {
  list-style: none;
  background: #ffffff;
  margin-bottom: 4px;
  padding-right: 50px; }

.noo-gmaps ~ .contact-info ul li i {
  width: 56px;
  height: 62px;
  line-height: 62px;
  text-align: center;
  font-size: 1.7142857142857142em;
  color: #ffffff;
  background: #fe6367;
  margin-right: 30px; }

@media (max-width: 767px) {
  .noo-gmaps ~ .contact-info ul li {
    overflow: hidden;
    line-height: 30px;
    word-break: break-all; }
  .noo-gmaps ~ .contact-info ul li i {
    float: left; } }

@media (max-width: 991px) {
  .noo-gmaps ~ .contact-info {
    right: 30px; } }

@media (max-width: 767px) {
  .noo-gmaps ~ .contact-info {
    right: 20px;
    left: 20px; } }

/*
 * 8.0 - Plugin
 *  8.3 - revslider
 */
.tp-bullets.simplebullets.round .bullet {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.7);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  height: 12px;
  width: 12px; }

.tp-bullets.simplebullets.round .bullet.selected,
.tp-bullets.simplebullets.round .bullet:hover {
  background: none repeat scroll 0 0 transparent;
  -webkit-box-shadow: 0 0 0 2px #ffffff;
  box-shadow: 0 0 0 2px #ffffff;
  margin: 0 5px; }

.noo-slider-revolution-container.bg-video {
  position: relative;
  overflow: hidden; }

.noo-slider-revolution-container.bg-video .rev_slider_wrapper {
  background: transparent !important; }

.noo-slider-revolution-container.bg-video #big-video-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: #000; }

.noo-slider-revolution-container.bg-video #big-video-wrap .vjs-tech {
  left: 0;
  margin: 0; }

.noo-slider-revolution-container.bg-video object,
.noo-slider-revolution-container.bg-video video {
  min-width: 100%;
  min-height: 100%; }

/**
 * 9.0 - Main file
 *  9.1 - main
 */
body.boxed-layout {
  background-color: transparent;
  background-image: url("");
  background-position: left top;
  background-repeat: no-repeat;
  background-attachment: fixed; }

body.boxed-layout .site {
  margin: 0 auto;
  position: relative;
  padding: 0px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.4);
  width: 90%;
  max-width: 1200px; }

@media (min-width: 768px) {
  body.navbar-fixed-left-layout {
    padding-left: 240px; }
  body.navbar-fixed-right-layout {
    padding-right: 240px; } }

body.nice-scroll {
  position: relative;
  padding-right: 10px !important; }

body.nice-scroll .nicescroll-rails {
  background: #666;
  z-index: 9001 !important; }

.noo-main.left-sidebar {
  float: right; }

@media (max-width: 767px) {
  .noo-main.left-sidebar {
    float: none; } }

.noo-main {
  padding-top: 2.142857142857143em; }

@media (min-width: 992px) {
  .noo-main {
    padding-top: 80px; } }

.home-classic .noo-main {
  padding-top: 0; }

.noo-sidebar {
  padding-top: 2.142857142857143em; }

@media (min-width: 992px) {
  .noo-sidebar {
    padding-top: 80px; } }

.home-classic .noo-sidebar {
  padding-top: 0; }

.main-content > .row,
.main-content > .noo-pricing-table {
  position: relative; }

.noo-sidebar-line {
  display: none; }

.wp-caption {
  background: transparent;
  border: 0;
  max-width: 100%;
  padding: 2.1428571429em 2.1428571429em 2.1428571429em 0; }

@media (max-width: 767px) {
  .wp-caption {
    padding: 0; } }

.wp-caption.alignright {
  padding: 2.1428571429em 0 2.1428571429em 2.1428571429em; }

@media (max-width: 767px) {
  .wp-caption.alignright {
    padding: 0; } }

.wp-caption .wp-caption-text {
  text-align: center;
  margin-top: 10px; }

.gallery-caption {
  margin-left: 0;
  margin-bottom: 10px;
  padding: 0px 10px; }

.bypostauthor {
  margin: 0;
  padding: 0; }

#wpstats {
  display: none; }

.sticky {
  margin-bottom: 2.142857142857143em; }

.alignleft {
  float: left;
  margin-right: 15px; }

.alignright {
  float: right;
  margin-left: 15px; }

.aligncenter {
  display: block;
  margin: 15px auto; }

.noo-menu {
  margin-right: auto !important;
  margin-left: auto !important; }

.our-service .service-icon i {
  width: 100px !important;
  height: 100px !important;
  line-height: 80px !important;
  border-radius: 50%;
  border: 10px solid rgba(0, 0, 0, 0.15) !important;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  background: rgba(255, 255, 255, 0.15); }

.our-service:hover .service-icon i {
  border: 10px solid rgba(0, 0, 0, 0.3) !important;
  background: rgba(240, 231, 151, 0.3); }

.our-service:hover a {
  border-bottom: 1px solid #fff !important; }

.testimonial-desc {
  font-size: 22px;
  font-style: italic;
  font-weight: 300;
  width: 90%;
  text-align: center;
  margin: 2.1428571429em auto 1.0714285714em auto; }

.our-customer-info {
  text-align: center; }

.our-customer-info .custom-desc {
  text-align: left;
  color: #bbbbbb;
  font-style: italic;
  font-weight: 300; }

.our-customer-info .custom-desc h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 1.2857142857142858em;
  margin-bottom: 0; }

.slide-home-info {
  position: absolute;
  left: 0;
  top: 50%;
  background: transparent;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  min-height: 270px; }

@-webkit-keyframes inpulse {
  0% {
    -webkit-transform: scale(1, 1);
    opacity: 1; }
  50% {
    -webkit-transform: scale(0.9, 0.9);
    opacity: .8; }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 1; } }

@-moz-keyframes inpulse {
  0% {
    -moz-transform: scale(1, 1);
    opacity: 1; }
  50% {
    -moz-transform: scale(0.9, 0.9);
    opacity: .8; }
  100% {
    -moz-transform: scale(1, 1);
    opacity: 1; } }

@-o-keyframes inpulse {
  0% {
    -o-transform: scale(1, 1);
    opacity: 1; }
  50% {
    -o-transform: scale(0.9, 0.9);
    opacity: .8; }
  100% {
    -o-transform: scale(1, 1);
    opacity: 1; } }

@keyframes inpulse {
  0% {
    transform: scale(1, 1);
    opacity: 1; }
  50% {
    transform: scale(0.9, 0.9);
    opacity: .8; }
  100% {
    transform: scale(1, 1);
    opacity: 1; } }

.inpulse {
  -webkit-animation: inpulse 4s infinite;
  -moz-animation: inpulse 4s infinite;
  -o-animation: inpulse 4s infinite;
  animation: inpulse 4s infinite; }

[data-parallax="1"].parallax {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: repeat;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important; }

@media (max-width: 768px) {
  [data-parallax="1"].parallax[data-parallax_no_mobile="0"] {
    background-position: 50% 50% !important;
    background-attachment: scroll; } }

.noo-call-to-action {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding-left: 60px;
  padding-right: 60px;
  margin-left: 0;
  margin-right: 0; }

@media (max-width: 767px) {
  .noo-call-to-action {
    padding-left: 2.142857142857143em;
    padding-right: 2.142857142857143em; }
  .noo-call-to-action .btn-thirdary {
    padding: 10px 2.1428571429em !important; } }

.home .noo-main {
  padding-top: 0; }

/**
 * 9.0 - Main file
 *  9.2 - shortcode
 */
.noo-counter-holder > span.noo-counter {
  font-weight: bold; }

.noo-counter-holder .counter-text h3 {
  margin-top: 0; }

.noo-counter-holder .counter-text p {
  text-align: center; }

.noo-block-grid {
  display: block;
  overflow: hidden;
  padding: 0;
  list-style: disc; }

.noo-block-grid > li {
  display: block;
  height: auto;
  float: left; }

.noo-block-grid > li > img.noo-img-thumbnail {
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-transition: border 0.3s ease, box-shadow 0.3s ease;
  transition: border 0.3s ease, box-shadow 0.3s ease; }

.noo-block-grid.two-col {
  margin: 1.313em -1.25%; }

.noo-block-grid.two-col > li {
  width: 50%;
  padding: 1.25%; }

.noo-block-grid.two-col > li:nth-child(2n+1) {
  clear: both; }

.noo-block-grid.three-col {
  margin: 1.313em -1%; }

.noo-block-grid.three-col > li {
  width: 33.33333%;
  padding: 1%; }

.noo-block-grid.three-col > li:nth-child(3n+1) {
  clear: both; }

.noo-block-grid.four-col {
  margin: 1.313em -0.9%; }

.noo-block-grid.four-col > li {
  width: 25%;
  padding: 0.9%; }

.noo-block-grid.four-col > li:nth-child(4n+1) {
  clear: both; }

.noo-block-grid.five-col {
  margin: 1.313em -0.85%; }

.noo-block-grid.five-col > li {
  width: 20%;
  padding: 0.85%; }

.noo-block-grid.five-col > li:nth-child(5n+1) {
  clear: both; }

.noo-block-grid.six-col {
  margin: 1.313em -0.8%; }

.noo-block-grid.six-col > li {
  width: 16.66666%;
  padding: 0.8%; }

.noo-block-grid.six-col > li:nth-child(6n+1) {
  clear: both; }

/*
 *    Typography Shortcode  
 */
.noo-dropcap {
  float: left;
  margin: 0 10px 0 0;
  font-size: 42px;
  line-height: 35px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.15); }

.noo-dropcap.dropcap-square,
.noo-dropcap.dropcap-circle {
  padding: 7px 13px 11px; }

.noo-dropcap.dropcap-circle {
  border-radius: 100%; }

.noo-quote {
  border-left-color: #21c2f8;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  color: #75747b;
  margin: 10px; }

.noo-quote cite:before {
  content: "â€“ "; }

.noo-quote.text-right {
  border-left-width: 0;
  border-right: 5px solid #21c2f8; }

.noo-quote.text-right cite {
  font-weight: 300; }

.noo-quote.pullquote {
  width: 40%;
  font-size: 16px;
  border: none; }

.noo-quote.pullquote h1 {
  font-size: 16px !important;
  line-height: 1.3 !important; }

.noo-quote.pullquote.pullquote-left {
  float: left; }

.noo-quote.pullquote.pullquote-right {
  float: right; }

.noo-quote.pullquote.text-left {
  margin-left: 0;
  padding-left: 0; }

.noo-quote.pullquote.text-right {
  margin-right: 0;
  padding-right: 0; }

/*
 *    Lightbox Shortcode  
 */
.noo-lightbox-item > img {
  display: block;
  margin: 0 auto; }

.noo-lightbox-item .rounded {
  border-radius: 3px; }

.noo-lightbox-item .circle {
  border-radius: 50%; }

/*
 *    Callout Shortcode  
 */
.noo-cta-btn {
  background-color: #fff;
  border-radius: 0;
  margin: 0 auto;
  text-align: center; }

.noo-cta-btn .cta-title {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 10px; }

.noo-cta-btn .btn,
.noo-cta-btn .widget_newsletterwidget .newsletter-submit {
  text-transform: uppercase; }

@media (max-width: 767px) {
  .noo-cta-btn {
    width: 90%; }
  .noo-cta-btn .btn,
  .noo-cta-btn .widget_newsletterwidget .newsletter-submit {
    max-width: 200px;
    padding-right: 15px !important;
    padding-left: 15px !important; } }

.noo-icon.icon-style-custom.icon-circle > i {
  border: 1px solid transparent;
  border-radius: 50%;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.noo-icon.icon-style-custom.icon-square > i {
  border: 1px solid transparent;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.noo-separator {
  display: table; }

.noo-separator h4 {
  display: table-cell;
  font-size: 100%;
  line-height: 1em;
  white-space: pre; }

.noo-separator .noo-sep-line {
  border-top: 1px solid #dbdada;
  display: block;
  position: relative;
  top: 0px;
  width: 100%; }

.noo-separator.line-with-text.separator-align-center h4 {
  padding-left: 0.8em;
  padding-right: 0.8em; }

@media (max-width: 767px) {
  .noo-separator.line-with-text.separator-align-center h4 {
    padding-left: 1.0714285714285714em;
    padding-right: 1.0714285714285714em; } }

.noo-separator.line-with-text.separator-align-left h4 {
  padding-right: 0.8em; }

.noo-separator.line-with-text.separator-align-left .noo-sep-holder-l {
  display: none; }

.noo-separator.line-with-text.separator-align-left .noo-sep-holder-r {
  width: 100%; }

.noo-separator.line-with-text.separator-align-right h4 {
  padding-left: 0.8em; }

.noo-separator.line-with-text.separator-align-right .noo-sep-holder-l {
  width: 100%; }

.noo-separator.line-with-text.separator-align-right .noo-sep-holder-r {
  display: none; }

.noo-separator .noo-sep-holder-l {
  display: table-cell;
  position: relative;
  vertical-align: middle;
  width: 50%; }

.noo-separator .noo-sep-holder-r {
  display: table-cell;
  position: relative;
  vertical-align: middle;
  width: 50%; }

.noo-gap {
  margin-left: 0;
  margin-bottom: 0;
  border-color: transparent; }

.noo-clear {
  margin: 0;
  border: 0;
  clear: both; }

.noo-pie-chart {
  z-index: 0;
  text-align: center; }

.noo-pie-chart .noo-pie-chart-wrapper {
  position: relative;
  margin: 0 auto;
  -webkit-border-radius: 50%;
  border-radius: 50%; }

.noo-pie-chart .noo-pie-chart-heading {
  margin-top: 30px !important; }

.noo-pie-chart canvas {
  position: relative;
  z-index: 1; }

.noo-pie-chart .noo-pie-chart-value {
  font-size: 200%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center; }

.noo-pie-chart .noo-pie-chart-back {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 4px;
  left: 4px;
  border: 6px solid #f7f7f7;
  border-radius: 999px;
  border-radius: 50%;
  opacity: 0.3;
  filter: alpha(opacity=30);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noo-pricing-heading {
  text-align: center;
  margin-bottom: 100px; }

.noo-pricing-heading h1 {
  font-weight: 400;
  margin-top: 0; }

.noo-pricing-heading > div {
  font-size: 1.2857142857142858em;
  font-style: italic; }

.noo-pricing-table.pricing-1-col .noo-pricing-column,
.noo-pricing-table.one-col .noo-pricing-column {
  position: relative;
  min-height: 1px;
  padding-left: 1.0714285714285714em;
  padding-right: 1.0714285714285714em; }

@media (min-width: 992px) {
  .noo-pricing-table.pricing-1-col .noo-pricing-column,
  .noo-pricing-table.one-col .noo-pricing-column {
    float: left;
    width: 100%; } }

.noo-pricing-table.pricing-2-col .noo-pricing-column,
.noo-pricing-table.two-col .noo-pricing-column {
  position: relative;
  min-height: 1px;
  padding-left: 1.0714285714285714em;
  padding-right: 1.0714285714285714em; }

@media (min-width: 992px) {
  .noo-pricing-table.pricing-2-col .noo-pricing-column,
  .noo-pricing-table.two-col .noo-pricing-column {
    float: left;
    width: 50%; } }

.noo-pricing-table.pricing-3-col .noo-pricing-column,
.noo-pricing-table.three-col .noo-pricing-column {
  position: relative;
  min-height: 1px;
  padding-left: 1.0714285714285714em;
  padding-right: 1.0714285714285714em; }

@media (min-width: 992px) {
  .noo-pricing-table.pricing-3-col .noo-pricing-column,
  .noo-pricing-table.three-col .noo-pricing-column {
    float: left;
    width: 33.33333333333333%; } }

.noo-pricing-table.pricing-4-col .noo-pricing-column,
.noo-pricing-table.four-col .noo-pricing-column {
  position: relative;
  min-height: 1px;
  padding-left: 1.0714285714285714em;
  padding-right: 1.0714285714285714em; }

@media (min-width: 992px) {
  .noo-pricing-table.pricing-4-col .noo-pricing-column,
  .noo-pricing-table.four-col .noo-pricing-column {
    float: left;
    width: 25%; } }

.noo-pricing-table.pricing-6-col .noo-pricing-column,
.noo-pricing-table.six-col .noo-pricing-column {
  position: relative;
  min-height: 1px;
  padding-left: 1.0714285714285714em;
  padding-right: 1.0714285714285714em; }

@media (min-width: 992px) {
  .noo-pricing-table.pricing-6-col .noo-pricing-column,
  .noo-pricing-table.six-col .noo-pricing-column {
    float: left;
    width: 16.666666666666664%; } }

@media (max-width: 991px) {
  .noo-pricing-table .noo-pricing-column {
    width: 50%;
    margin: 0 0 30px;
    float: left; } }

@media (max-width: 480px) {
  .noo-pricing-table .noo-pricing-column {
    width: 100%;
    float: none; } }

.noo-pricing-table .noo-pricing-column .pricing-content {
  background: #f6f6f6;
  text-align: center;
  -webkit-box-shadow: 0 0 32px rgba(0, 0, 0, 0.19);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.19); }

.noo-pricing-table .noo-pricing-column .pricing-content .pricing-header {
  background-color: #5f5f5f;
  padding: 25px 0 10px;
  color: #ffffff;
  position: relative;
  margin-bottom: 40px; }

.noo-pricing-table .noo-pricing-column .pricing-content .pricing-header .pricing-title {
  margin: 10px 0 0;
  font-size: 1.2857142857142858em;
  text-transform: uppercase;
  color: #ffffff; }

.noo-pricing-table .noo-pricing-column .pricing-content .pricing-header .arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 50%;
  padding-right: 50%;
  overflow: hidden;
  -webkit-transform: translate(0, 99%);
  -ms-transform: translate(0, 99%);
  -o-transform: translate(0, 99%);
  transform: translate(0, 99%); }

.noo-pricing-table .noo-pricing-column .pricing-content .pricing-header .arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  margin-left: -500px;
  margin-top: -70px;
  border-left: 500px solid transparent;
  border-right: 500px solid transparent;
  border-top: 80px solid #5f5f5f; }

.noo-pricing-table .noo-pricing-column .pricing-content .pricing-price {
  padding: 20px 0; }

.noo-pricing-table .noo-pricing-column .pricing-content .pricing-price h3 {
  margin: 0;
  font-size: 60px;
  line-height: 0.8; }

.noo-pricing-table .noo-pricing-column .pricing-content .pricing-price h3 .noo-price > span.amount i,
.noo-pricing-table .noo-pricing-column .pricing-content .pricing-price h3 .noo-price ins span.amount i {
  font-style: normal;
  vertical-align: top;
  font-size: 0.4em; }

.noo-pricing-table .noo-pricing-column .pricing-content .pricing-price h3 ins {
  text-decoration: none; }

.noo-pricing-table .noo-pricing-column .pricing-content .pricing-price h3 del {
  font-size: 0.3em;
  font-weight: 400;
  font-style: italic;
  color: #5f5f5f; }

.noo-pricing-table .noo-pricing-column .pricing-content .pricing-info ul {
  list-style: none;
  margin: 0;
  padding: 20px 0;
  background: #ffffff; }

.noo-pricing-table .noo-pricing-column .pricing-content .pricing-info ul li {
  padding: 1.1428571429em 1.7142857143em;
  color: #333333;
  border-top: 1px solid #f3f3f3; }

.noo-pricing-table .noo-pricing-column .pricing-content .pricing-info ul li:first-child {
  border: 0; }

.noo-pricing-table .noo-pricing-column .pricing-content .pricing-info ul li i {
  position: relative;
  left: 0;
  top: 0; }

.noo-pricing-table .noo-pricing-column .pricing-content .pricing-footer .btn,
.noo-pricing-table .noo-pricing-column .pricing-content .pricing-footer .widget_newsletterwidget .newsletter-submit {
  width: 100%;
  background: #5f5f5f;
  color: #ffffff;
  padding: 1.1428571429em 1.7142857143em; }

.noo-pricing-table .noo-pricing-column .pricing-content .pricing-footer .btn:hover,
.noo-pricing-table .noo-pricing-column .pricing-content .pricing-footer .btn:focus,
.noo-pricing-table .noo-pricing-column .pricing-content .pricing-footer .widget_newsletterwidget .newsletter-submit:hover,
.noo-pricing-table .noo-pricing-column .pricing-content .pricing-footer .widget_newsletterwidget .newsletter-submit:focus {
  background: #fe6367; }

.noo-pricing-table .noo-pricing-column.featured {
  margin-top: -40px; }

@media (max-width: 991px) {
  .noo-pricing-table .noo-pricing-column.featured {
    margin-top: 0; } }

.noo-pricing-table .noo-pricing-column.featured .pricing-content .pricing-header {
  background-color: #fe6367;
  padding: 50px 0 25px; }

.noo-pricing-table .noo-pricing-column.featured .pricing-content .pricing-header .arrow:after {
  border-top-color: #fe6367; }

.noo-pricing-table .noo-pricing-column.featured .pricing-content .pricing-info ul {
  padding-bottom: 60px; }

.noo-pricing-table .noo-pricing-column.featured .pricing-content .pricing-footer .btn,
.noo-pricing-table .noo-pricing-column.featured .pricing-content .pricing-footer .widget_newsletterwidget .newsletter-submit {
  background: #fe6367; }

.noo-pricing-table .noo-pricing-column.featured .pricing-content .pricing-footer .btn:hover,
.noo-pricing-table .noo-pricing-column.featured .pricing-content .pricing-footer .btn:focus,
.noo-pricing-table .noo-pricing-column.featured .pricing-content .pricing-footer .widget_newsletterwidget .newsletter-submit:hover,
.noo-pricing-table .noo-pricing-column.featured .pricing-content .pricing-footer .widget_newsletterwidget .newsletter-submit:focus {
  background: #5f5f5f; }

.noo-post-list .title .sub-title {
  margin-top: 0.8214285714285714em; }

.noo-gmaps {
  position: relative; }

.noo-gmaps .map-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100; }

.noo-gmaps iframe {
  max-width: 100%;
  border: medium none;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative; }

.noo-gmaps.no-map-responsive iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0; }

#cta_buy_theme .noo-text-block p {
  font-size: 18px;
  line-height: 36px;
  text-align: center; }

@media (max-width: 992px) {
  #cta_buy_theme .noo-text-block p {
    text-align: center; }
  #cta_buy_theme .noo-text-block .btn,
  #cta_buy_theme .noo-text-block .widget_newsletterwidget .newsletter-submit {
    display: table;
    margin: 0 auto; } }

.noo-sc-testimonial {
  text-align: center;
  overflow: hidden; }

.noo-sc-testimonial .sc-title {
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 48px;
  text-transform: uppercase;
  color: #ffffff; }

.noo-sc-testimonial .testimonial-quote {
  padding: 0 0 80px; }

@media (min-width: 1200px) {
  .noo-sc-testimonial .testimonial-quote {
    max-width: 800px;
    margin: 0 auto; } }

.noo-sc-testimonial .testimonial-quote ul {
  list-style: none;
  padding: 0;
  margin: 0; }

.noo-sc-testimonial .testimonial-quote ul li {
  float: left;
  text-align: center; }

.noo-sc-testimonial .testimonial-quote ul li .quote {
  font-size: 1.2857142857142858em;
  color: #fff;
  margin-bottom: 30px; }

.noo-sc-testimonial .testimonial-quote ul li .name {
  color: #fe6367;
  font-weight: 700;
  margin: 0; }

.noo-sc-testimonial .testimonial-quote ul li .position {
  color: #a3a3a3; }

.noo-sc-testimonial .testimonial-author {
  display: table;
  margin: 0 auto;
  position: relative;
  background: #f4f4f4; }

.noo-sc-testimonial .testimonial-author ul {
  text-align: center;
  padding: 0;
  margin: 0;
  list-style: none; }

.noo-sc-testimonial .testimonial-author ul li {
  float: left;
  width: 90px;
  position: relative;
  padding-bottom: 10px; }

.noo-sc-testimonial .testimonial-author ul li:after {
  content: '';
  position: absolute;
  height: 4px;
  width: 0;
  bottom: 0;
  left: 0;
  background: #fe6367;
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s; }

.noo-sc-testimonial .testimonial-author ul li.selected:after {
  width: 100%; }

.noo-sc-testimonial .testimonial-author ul li.selected img,
.noo-sc-testimonial .testimonial-author ul li:hover img,
.noo-sc-testimonial .testimonial-author ul li:focus img {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%); }

.noo-recent-class .noo-rn-content .item-featured {
  position: relative;
  overflow: hidden; }

.noo-recent-class .noo-rn-content.info_overlay {
  padding: 100px 0 70px; }

.noo-recent-class .noo-rn-content.info_overlay .item-featured .infor-overlay {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff; }

.noo-recent-class .noo-rn-content.info_overlay .item-featured .infor-overlay .inner {
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%); }

.noo-recent-class .noo-rn-content.info_overlay .item-featured .infor-overlay .inner span {
  display: block; }

.noo-recent-class .noo-rn-content.info_overlay .item-title {
  margin: 0;
  line-height: 2;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: normal;
  padding: 1.0714285714285714em; }

.noo-recent-class .noo-rn-content.info_overlay .item-title a {
  color: #000; }

.noo-recent-class .noo-rn-content.info_overlay .item-wrap:hover .item-featured .infor-overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.noo-recent-class .noo-rn-content.info_overlay .item-wrap:hover .item-title {
  background: #fe6367; }

.noo-recent-class .noo-rn-content.info_overlay .item-wrap:hover .item-title a {
  color: #ffffff; }

.noo-recent-class .noo-rn-content.default .item-featured {
  height: 110px;
  background: #fff; }

.noo-recent-class .noo-rn-content.default .item-featured:before,
.noo-recent-class .noo-rn-content.default .item-featured:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 50%;
  background-color: inherit;
  padding-bottom: 17%; }

@media (max-width: 480px) {
  .noo-recent-class .noo-rn-content.default .item-featured:before,
  .noo-recent-class .noo-rn-content.default .item-featured:after {
    padding-bottom: 20%; } }

.noo-recent-class .noo-rn-content.default .item-featured:before {
  right: 50%;
  -ms-transform-origin: 100% 100%;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -ms-transform: skewX(82deg);
  -webkit-transform: skewX(82deg);
  transform: skewX(82deg); }

.noo-recent-class .noo-rn-content.default .item-featured:after {
  left: 50%;
  -ms-transform-origin: 0 100%;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -ms-transform: skewX(-82deg);
  -webkit-transform: skewX(-82deg);
  transform: skewX(-82deg); }

.noo-recent-class .noo-rn-content.default .item-title {
  text-transform: uppercase;
  margin: 30px 0; }

.noo-recent-class .noo-rn-content.default .info span {
  display: block;
  margin-bottom: 25px; }

.noo-recent-class .noo-rn-content.default .learn-more a {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  color: #ffffff; }

.noo-recent-class .loop-item {
  margin-bottom: 2.142857142857143em;
  text-align: center; }

.noo-recent-class .loop-item .item-wrap {
  background: #ffffff; }

@media (max-width: 767px) {
  .noo-recent-class .loop-item {
    width: 100%; } }

.noo-recent-class.col-4 .loop-item {
  width: 25%;
  text-align: center; }

@media (max-width: 991px) {
  .noo-recent-class.col-4 .loop-item {
    width: 50%; } }

@media (max-width: 767px) {
  .noo-recent-class.col-4 .loop-item {
    width: 100%; } }

.noo-recent-class.col-5 .loop-item {
  width: 20%;
  text-align: center; }

@media (max-width: 991px) {
  .noo-recent-class.col-5 .loop-item {
    width: 50%; } }

@media (max-width: 767px) {
  .noo-recent-class.col-5 .loop-item {
    width: 100%; } }

@media (max-width: 991px) {
  .noo-recent-class.col-6 .loop-item {
    width: 33.33%; } }

@media (max-width: 767px) {
  .noo-recent-class.col-6 .loop-item {
    width: 100%; } }

.our-class {
  background: #fff; }

.our-class .our-class-header {
  background: #b4b4b4;
  text-align: center;
  position: relative;
  padding-top: 1.0714285714285714em;
  margin-bottom: 40px; }

.our-class .our-class-header .arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 50%;
  padding-right: 50%;
  overflow: hidden;
  -webkit-transform: translate(0, 99%);
  -ms-transform: translate(0, 99%);
  -o-transform: translate(0, 99%);
  transform: translate(0, 99%); }

.our-class .our-class-header .arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  margin-left: -500px;
  margin-top: -70px;
  border-left: 500px solid transparent;
  border-right: 500px solid transparent;
  border-top: 80px solid #b4b4b4; }

.our-class h4 {
  text-transform: uppercase;
  text-align: center;
  padding-top: 1.0714285714285714em;
  font-size: 24px;
  color: #5f5f5f;
  letter-spacing: 2px; }

.our-class .class-info {
  text-align: center;
  line-height: 3em;
  padding-bottom: 2.142857142857143em; }

@media (max-width: 767px) {
  .our-class .class-info {
    padding-bottom: 60px; } }

@media (min-width: 767px) and (max-width: 991px) {
  .our-class .class-info {
    padding-bottom: 60px; } }

.our-class a {
  background: #ebebeb;
  text-transform: uppercase;
  text-align: center;
  padding: 13px 0;
  position: absolute;
  bottom: 0;
  left: 1.0714285714285714em;
  right: 1.0714285714285714em; }

@media (min-width: 767px) and (max-width: 991px) {
  .our-class a {
    margin-bottom: 2.142857142857143em; } }

@media (min-width: 767px) and (max-width: 991px) {
  .col-last {
    clear: left; } }

.noo-cta-btn {
  background: transparent;
  padding: 0 2.1428571429em; }

.noo-cta-btn .cta-title {
  font-size: 1.2857142857142858em;
  color: #ffffff;
  font-weight: normal; }

.noo-cta-btn .cta-message {
  font-size: 30px;
  color: #ffffff;
  padding-bottom: 1.0714285714285714em; }

.noo-cta-btn .btn,
.noo-cta-btn .widget_newsletterwidget .newsletter-submit {
  font-size: 24px;
  text-transform: none;
  text-transform: uppercase; }

.our-services > div {
  display: inline-block; }

@media (max-width: 767px) {
  .our-services > div {
    display: block;
    margin-bottom: 2.142857142857143em; } }

.our-services > div .noo-icon {
  float: left;
  margin-right: 20px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  background: #f4f4f4;
  border-radius: 50%;
  text-align: center;
  color: #6a695e;
  -webkit-transition: transform 600ms ease 0s;
  -o-transition: transform 600ms ease 0s;
  transition: transform 600ms ease 0s; }

.our-services > div .noo-text-block {
  padding: 0.7142857143em 2.1428571429em;
  line-height: 1.5; }

.our-services > div .noo-text-block i {
  display: block; }

@media (max-width: 991px) {
  .our-services > div .noo-text-block {
    padding: 1.0714285714285714em; } }

.our-services > div:before {
  position: absolute;
  content: " ";
  background: #dbdada;
  right: 0;
  font-style: italic;
  top: 40px;
  right: -2.142857142857143em;
  height: 1px;
  width: 85px;
  -webkit-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -o-transform: rotate(-60deg);
  transform: rotate(-60deg); }

@media (max-width: 991px) {
  .our-services > div:before {
    height: 0; } }

.our-services > div:last-child:before {
  height: 0; }

.our-services > div:hover .noo-icon {
  background: #fe6367;
  color: #ffffff; }

.contact-register {
  background: #fff;
  margin-top: -138px; }

@media (max-width: 991px) {
  .contact-register {
    margin-top: 0; } }

.contact-register .contact-register-header {
  background: #fe6367;
  text-align: center;
  position: relative;
  padding-top: 1.0714285714285714em;
  margin-bottom: 50px;
  color: #fff; }

.contact-register .contact-register-header h4 {
  text-transform: uppercase;
  color: #fff; }

.contact-register .contact-register-header .arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 50%;
  padding-right: 50%;
  overflow: hidden;
  -webkit-transform: translate(0, 99%);
  -ms-transform: translate(0, 99%);
  -o-transform: translate(0, 99%);
  transform: translate(0, 99%); }

.contact-register .contact-register-header .arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  margin-left: -500px;
  margin-top: -70px;
  border-left: 500px solid transparent;
  border-right: 500px solid transparent;
  border-top: 80px solid #fe6367; }

.contact-register .contact-register-content {
  padding: 1.0714285714285714em; }

.contact-register .contact-register-content input,
.contact-register .contact-register-content textarea {
  font-style: italic;
  margin-bottom: 1.0714285714285714em; }

.contact-register .form-submit {
  height: auto;
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px; }

.contact-register .ajax-loader {
  display: none; }

.videos.posts-loop .loop-thumb .loop-thumb-content .carousel-inner {
  margin: 0; }

.videos.posts-loop .loop-thumb .loop-thumb-content .carousel-inner .item > a {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 1.0714285714285714em;
  background: #f4f4f4;
  padding: 10px 30px 10px 80px;
  font-size: 1.2857142857142858em;
  color: #fe6367;
  height: 70px; }

@media (max-width: 991px) {
  .videos.posts-loop .loop-thumb .loop-thumb-content .carousel-inner .item > a {
    font-size: 14px; } }

.videos.posts-loop .loop-thumb .loop-thumb-content .carousel-inner .item > a .icon {
  width: 70px;
  height: 70px;
  line-height: 70px;
  display: inline-block;
  text-align: center;
  background: #fe6367;
  color: #ffffff;
  font-size: 1.2857142857142858em;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute; }

.videos.posts-loop .loop-thumb .loop-thumb-content .carousel-inner .item > a .thumbnail-content {
  font-size: 14px;
  color: #5f5f5f;
  display: block; }

@media (max-width: 991px) {
  .videos.posts-loop .loop-thumb .loop-thumb-content .carousel-inner .item > a .thumbnail-content {
    display: none; } }

.videos.posts-loop .loop-thumb .loop-thumb-content .carousel-indicators {
  position: absolute;
  right: -80px;
  top: 0; }

.videos.posts-loop .loop-thumb .loop-thumb-content .carousel-indicators li.active {
  border: 0; }

.videos.posts-loop .loop-thumb .loop-thumb-content .carousel-indicators li.active:before {
  border: 0; }

.videos.posts-loop .loop-thumb .loop-thumb-content .carousel-indicators li {
  background: #7d7d7d; }

.videos.posts-loop .loop-thumb .loop-thumb-content .carousel-indicators li:before {
  border: 0; }

@media (max-width: 767px) {
  .videos.posts-loop .loop-thumb {
    display: none; } }

.videos.posts-loop .video {
  margin-top: -180px; }

@media (max-width: 991px) {
  .videos.posts-loop .video {
    margin-top: 0; } }

.videos.posts-loop .video .loop-item-wrap {
  position: relative; }

.videos.posts-loop .video .loop-item-wrap .loop-item-featured {
  width: 100%; }

.videos.posts-loop .video .loop-item-wrap .loop-item-content {
  padding: 10px 0;
  padding-left: 80px;
  background: #f4f4f4;
  max-height: 70px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto; }

@media (max-width: 991px) {
  .videos.posts-loop .video .loop-item-wrap .loop-item-content {
    margin-left: 0;
    padding-left: 0;
    height: 70px; } }

.videos.posts-loop .video .loop-item-wrap .loop-item-content .icon {
  width: 70px;
  height: 70px;
  line-height: 70px;
  display: inline-block;
  text-align: center;
  background: #fe6367;
  color: #ffffff;
  font-size: 1.2857142857142858em;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute; }

@media (max-width: 768px) and (min-width: 991px) {
  .videos.posts-loop .video .loop-item-wrap .loop-item-content .icon {
    position: relative;
    width: 50px;
    height: 50px;
    line-height: 50px; } }

.videos.posts-loop .video .loop-item-wrap .loop-item-content .loop-item-title {
  font-size: 1.2857142857142858em;
  color: #fe6367;
  text-transform: none;
  margin-bottom: 0; }

@media (max-width: 991px) {
  .videos.posts-loop .video .loop-item-wrap .loop-item-content .loop-item-title {
    padding-left: 80px; } }

.videos.posts-loop .video .loop-item-wrap .loop-item-content .loop-item-excerpt {
  margin-top: 7px; }

@media (max-width: 991px) {
  .videos .loop-item-excerpt {
    display: none; } }

.testimonial-content {
  padding-left: 50px;
  position: relative; }

.testimonial-content:before {
  content: '\201C';
  position: absolute;
  top: -60px;
  left: -5px;
  font-size: 120px;
  color: #fe6367;
  line-height: 150%;
  font-weight: bold; }

/**
 * 9.0 - Main file
 *  9.3 - loop
 */
.posts-loop .loop-item-category {
  padding-bottom: 10px;
  padding-top: 5px;
  color: #fe6367; }

.posts-loop .loop-item-category a {
  color: #fe6367;
  padding-right: 10px; }

.posts-loop .loop-item-category a:hover {
  color: #000; }

.posts-loop .posts-loop-title {
  position: relative;
  margin-bottom: 2.142857142857143em;
  background: #f4f4f4;
  padding: 2.142857142857143em;
  text-transform: uppercase;
  margin: 0 1.0714285714em 2.1428571429em 1.0714285714em; }

.posts-loop .posts-loop-title h3 {
  margin: 0;
  font-size: 1.2857142857142858em;
  text-transform: uppercase; }

.posts-loop .posts-loop-title:before,
.posts-loop .posts-loop-title:after {
  content: " ";
  display: table; }

.posts-loop .posts-loop-title:after {
  clear: both; }

.posts-loop .posts-loop-title:before,
.posts-loop .posts-loop-title:after {
  content: " ";
  display: table; }

.posts-loop .posts-loop-title:after {
  clear: both; }

.posts-loop .posts-loop-title a {
  background-color: #fff;
  font-weight: bold;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 0.4285714286em 0.8571428571em;
  position: absolute;
  right: 2.142857142857143em;
  top: 50%;
  color: #5f5f5f;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%); }

@media (max-width: 767px) {
  .posts-loop .posts-loop-title a {
    display: none; } }

.posts-loop .posts-loop-title a.list-mode {
  right: 80px; }

.posts-loop .posts-loop-title a.active {
  color: #fe6367; }

.posts-loop .loop-item-title {
  margin-top: 0;
  font-size: 1.2857142857142858em;
  text-transform: uppercase;
  margin-bottom: 0.5rem; }

.posts-loop .loop-item-excerpt {
  margin-top: 1.5rem; }

.posts-loop .content-meta {
  margin-bottom: 0.7rem;
  margin-top: 0.7rem; }

.posts-loop .loop-item-featured img {
  width: 100%; }

.posts-loop .loop-item-action {
  margin-top: 2.142857142857143em; }

.posts-loop .hentry {
  background: transparent; }

.posts-loop.grid .loop-item {
  padding: 0 1.0714285714em;
  background: transparent;
  border: none;
  padding-bottom: 2.142857142857143em; }

.posts-loop.grid .loop-item:after {
  display: none; }

.posts-loop.grid .loop-item-wrap {
  position: relative;
  height: 100%;
  background: #fff; }

.posts-loop.grid .loop-item-action {
  text-align: center; }

.posts-loop.grid .loop-item-content {
  background: #f9f9f9; }

.posts-loop.grid .loop-item-content-summary {
  padding: 1.6428571428571428em; }

.posts-loop.grid .loop-item-action {
  margin-top: 0; }

@media (max-width: 767px) {
  .posts-loop.list .loop-item {
    padding: 1.0714285714285714em; } }

.posts-loop.list .loop-item-wrap {
  display: table;
  width: 100%; }

@media (max-width: 767px) {
  .posts-loop.list .loop-item-wrap {
    display: block; } }

.posts-loop.list .loop-item-featured {
  display: table-cell;
  vertical-align: top;
  width: 50%; }

@media (max-width: 767px) {
  .posts-loop.list .loop-item-featured {
    display: block;
    width: 100%; } }

.posts-loop.list .loop-item-content {
  display: table-cell;
  vertical-align: top;
  padding: 2.142857142857143em; }

@media (max-width: 991px) {
  .posts-loop.list .loop-item-content {
    padding: 1.0714285714em 2.1428571429em; } }

@media (max-width: 767px) {
  .posts-loop.list .loop-item-content {
    display: block;
    width: 100%; } }

.posts-loop.slider {
  position: relative; }

.posts-loop.slider .loop-item {
  padding: 0; }

.posts-loop.slider .loop-item:after {
  display: none; }

.posts-loop.slider .loop-item-wrap:before,
.posts-loop.slider .loop-item-wrap:after {
  content: " ";
  display: table; }

.posts-loop.slider .loop-item-wrap:after {
  clear: both; }

.posts-loop.slider .loop-item-wrap:before,
.posts-loop.slider .loop-item-wrap:after {
  content: " ";
  display: table; }

.posts-loop.slider .loop-item-wrap:after {
  clear: both; }

.posts-loop.slider .loop-item-title {
  font-size: 2.142857142857143em;
  line-height: 1.2; }

@media (min-width: 480px) {
  .posts-loop.slider .loop-item-content,
  .posts-loop.slider .loop-item-featured {
    float: left;
    width: 50%; }
  .posts-loop.slider .loop-item-content {
    padding: 2.142857142857143em; } }

@media (max-width: 1199px) {
  .posts-loop.slider .loop-item-content {
    padding: 1.0714285714285714em; }
  .posts-loop.slider .loop-item-title {
    font-size: 1.2857142857142858em; } }

@media (max-width: 800px) {
  .posts-loop.slider .carousel-inner {
    cursor: move; } }

.posts-loop.slider .loop-thumb-label {
  font-weight: bold;
  width: 41.66666666666667%; }

.posts-loop.slider .loop-thumb-label > span {
  display: inline-block;
  padding-left: 2.142857142857143em; }

@media (max-width: 1199px) {
  .posts-loop.slider .loop-thumb-label > span {
    padding-left: 1.0714285714285714em; } }

.posts-loop.slider .loop-thumb-content .carousel-inner {
  margin: 0 -8px;
  width: auto;
  margin-right: 4.285714285714286em; }

@media (max-width: 1199px) {
  .posts-loop.slider .loop-thumb-content .carousel-inner {
    margin-right: 3.2142857142857144em; } }

.posts-loop.slider .loop-thumb-content .item > a {
  float: left;
  width: 33.33333333333333%;
  padding: 0 8px; }

.posts-loop.slider .loop-thumb-content .item > a img {
  width: 100%;
  -webkit-border-radius: 3px;
  border-radius: 3px; }

.posts-loop.slider .loop-thumb-content .carousel-indicators {
  margin: 0;
  left: auto;
  right: 2.142857142857143em;
  top: 0;
  width: auto; }

@media (max-width: 1199px) {
  .posts-loop.slider .loop-thumb-content .carousel-indicators {
    right: 1.0714285714285714em; } }

.posts-loop.slider .loop-thumb-content .carousel-indicators li {
  width: 12px;
  height: 12px;
  margin: 7px 0;
  display: block;
  border-color: transparent;
  background: #e3e3e3; }

.posts-loop.slider .loop-thumb-content .carousel-indicators li.active {
  border-color: #fe6367;
  background: #fe6367;
  border-width: 2px;
  position: relative; }

.posts-loop.slider .loop-thumb-content .carousel-indicators li.active:before {
  border: 2px solid #fff;
  border-radius: 50%;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

/**
 * 9.0 - Main file
 *  9.4 - single
 */
.single-sidebar {
  background: #fff;
  margin-bottom: 2.142857142857143em; }

.single-sidebar iframe {
  width: 100%;
  height: auto; }

.content-summary {
  padding: 2.142857142857143em;
  margin-top: 2.142857142857143em;
  border: 1px solid #dbdada;
  background: #f9f9f9;
  margin-bottom: 2.142857142857143em; }

@media (max-width: 767px) {
  .content-summary {
    padding: 1.0714285714285714em; } }

.noo-events .event-info > div {
  line-height: 2;
  border-bottom: 1px solid #dbdada;
  padding: 7px 0; }

.noo-events .event-info > div i {
  color: #fe6367;
  padding-right: 5px; }

.noo-events .event-info > div:last-child {
  border: 0; }

.noo-events .loop-item-category {
  padding-top: 1.0714285714285714em; }

.noo-event .event-header {
  text-align: center;
  padding-bottom: 50px; }

.noo-event .event-header h2 {
  font-size: 24px;
  text-transform: uppercase; }

.noo-event .event-header .content-meta i {
  color: #fe6367; }

.noo-event .content-featured {
  padding-bottom: 2.142857142857143em; }

.noo-event ul {
  list-style-type: none; }

.noo-event ul li {
  line-height: 2; }

.noo-event ul li:before {
  content: "\f00c";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 5px;
  opacity: 0.5;
  filter: alpha(opacity=50); }

.noo-event ul li:before {
  color: #fe6367;
  opacity: 1; }

.noo-event .noo_event_map {
  position: relative; }

.noo-event .noo_event_map .event-info {
  position: absolute;
  top: 80px;
  right: 20%;
  background: #fff;
  padding: 2.142857142857143em; }

@media (max-width: 767px) {
  .noo-event .noo_event_map .event-info {
    position: static; } }

.noo-event .noo_event_map .event-info h5 {
  margin-top: 0;
  font-size: 18px;
  text-transform: uppercase;
  color: #fe6367;
  position: relative;
  padding-bottom: 10px;
  letter-spacing: 3px;
  line-height: 1.7; }

.noo-event .noo_event_map .event-info h5 span {
  color: #454545;
  font-weight: bold; }

.noo-event .noo_event_map .event-info h5:before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 25px;
  height: 1px;
  background: #fe6367; }

.noo-event .noo_event_map .event-info > div {
  line-height: 2;
  border-bottom: 1px solid #dbdada;
  padding: 5px 0; }

.noo-event .noo_event_map .event-info > div i {
  color: #fe6367;
  padding-right: 10px; }

.noo-event .noo_event_map .event-info > div:last-child {
  border: 0; }

.noo-event .noo_event_map .event-info > div.noo-social {
  padding-top: 1em; }

.noo-event .noo_event_map .event-info > div.noo-social a i {
  color: #5f5f5f;
  padding-right: 0; }

.noo-event .noo_event_map .event-info > div.noo-social a:hover i {
  color: #fff; }

/**
 * 10.0 - Trainer file
 */
.trainer-details .trainer-info {
  float: left; }

.trainer-details .trainer-info .view_class {
  float: right;
  color: #fe6367;
  position: absolute;
  right: 1.0714285714285714em;
  top: 10px; }

.trainer-details .trainer-info .view_class:hover {
  color: #000; }

@media (max-width: 767px) {
  .trainer-details .trainer-info .view_class {
    float: none;
    position: static;
    padding: 10px 0; } }

.trainer-details .trainer-info .trainer-category {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 1.0714285714285714em; }

.trainer-details .trainer-info .trainer-category a {
  font-weight: normal;
  padding-right: 1.0714285714285714em;
  margin-right: 10px;
  position: relative; }

.trainer-details .trainer-info .trainer-category a:before {
  content: "/";
  position: absolute;
  right: 0; }

.trainer-details .trainer-info .trainer-category a:last-child:before {
  content: ""; }

.trainer-details .trainer-info .trainer-category:before {
  content: "";
  background: #fe6367;
  width: 25px;
  height: 1px;
  position: absolute;
  bottom: 0; }

.trainer-details .trainer-info > div {
  line-height: 2.2em;
  width: 100%; }

.trainer-details .trainer-info > div span {
  font-weight: bold;
  width: 20%;
  display: inline-block; }

@media (max-width: 767px) {
  .trainer-details .trainer-info > div span {
    width: 100%; } }

.trainer-details .trainer-info > div.trainer-biography span {
  float: left; }

.trainer-details .trainer-info > div.trainer-biography div {
  width: 80%;
  float: left;
  line-height: 1.7; }

.trainer-details .trainer-info .trainer-social {
  text-align: left;
  float: left;
  padding-top: 2.142857142857143em; }

.trainer-details .trainer-info .trainer-social h5 {
  width: 20%;
  float: left;
  color: #5f5f5f; }

.trainer-details .trainer-info .trainer-social .noo-social-content {
  width: 80%;
  float: left; }

.trainer-details .trainer-info .trainer-social:before {
  height: 0; }

.trainer-content {
  background: #f4f4f4;
  padding: 50px 0; }

.trainer-content .content .content-title {
  margin-top: 0;
  font-size: 36px;
  text-transform: uppercase;
  color: #fe6367;
  position: relative;
  padding-bottom: 10px;
  letter-spacing: 3px;
  line-height: 1.7; }

.trainer-content .content .content-title span {
  color: #454545;
  font-weight: bold; }

.trainer-content .content .content-title:before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 25px;
  height: 1px;
  background: #fe6367; }

.trainer-content .content .content-title span {
  color: #454545;
  font-weight: bold; }

.trainer-content .content .content-title:before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 25px;
  height: 1px;
  background: #fe6367; }

.trainer-content .content ul {
  list-style-type: none; }

.trainer-content .content ul li {
  line-height: 2; }

.trainer-content .content ul li:before {
  content: "\f00c";
  position: relative;
  display: inline-block;
  font-family: "fontawesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  padding-right: 5px;
  opacity: 0.5;
  filter: alpha(opacity=50); }

.trainer-content .content ul li:before {
  color: #fe6367;
  opacity: 1; }

@media (max-width: 767px) {
  .trainer-content {
    padding: 30px; } }

.noo-trainers .trainers-header {
  margin-top: 0;
  font-size: 18px;
  text-transform: uppercase;
  color: #fe6367;
  position: relative;
  padding-bottom: 10px;
  letter-spacing: 3px;
  line-height: 1.7;
  margin-bottom: 2.142857142857143em; }

.noo-trainers .trainers-header span {
  color: #454545;
  font-weight: bold; }

.noo-trainers .trainers-header:before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 25px;
  height: 1px;
  background: #fe6367; }

.noo-trainers .masonry-filters {
  float: none;
  display: block; }

.noo-trainers .masonry-filters ul {
  margin: 0;
  padding: 0;
  text-align: center; }

.noo-trainers .masonry-filters ul li {
  float: none;
  display: inline-block;
  padding: 0; }

@media (max-width: 991px) {
  .noo-trainers .masonry-filters ul li {
    margin: 5px; } }

.noo-trainers .masonry-filters ul li a {
  border: 2px solid #8f8f8f;
  padding: 10px 2.1428571429em; }

.noo-trainers .masonry-filters ul li a.selected,
.noo-trainers .masonry-filters ul li a:hover {
  background: #fe6367;
  border-color: #fe6367;
  color: #fff; }

@media (max-width: 991px) {
  .noo-trainers .masonry-filters ul li a {
    padding: 5px 10px;
    line-height: 20px; } }

.noo-trainers .hentry {
  background: #fff; }

.noo-trainers .hentry.masonry-item {
  margin-bottom: 2.142857142857143em;
  padding-bottom: 0; }

.noo-trainers .hentry .trainer-item-wrap {
  background: #f4f4f4;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.noo-trainers .hentry .trainer-item-wrap .content-featured {
  overflow: hidden; }

.noo-trainers .hentry .trainer-item-wrap .content-featured img {
  -webkit-transition: transform 600ms ease 0s;
  -o-transition: transform 600ms ease 0s;
  transition: transform 600ms ease 0s;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1); }

.noo-trainers .hentry .trainer-item-wrap .content-wrap {
  padding: 1.0714285714285714em 0;
  text-align: center; }

.noo-trainers .hentry .trainer-item-wrap .content-wrap .content-title {
  font-size: 20px;
  text-transform: none; }

.noo-trainers .hentry .trainer-item-wrap .content-wrap .trainer-category {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 1.0714285714285714em; }

.noo-trainers .hentry .trainer-item-wrap .content-wrap .trainer-category a {
  font-weight: normal;
  padding: 0 10px;
  margin: 0;
  position: relative; }

.noo-trainers .hentry .trainer-item-wrap .content-wrap .trainer-category a:before {
  content: "/";
  position: absolute;
  right: 0; }

.noo-trainers .hentry .trainer-item-wrap .content-wrap .trainer-category a:last-child:before {
  content: ""; }

.noo-trainers .hentry .trainer-item-wrap .content-wrap .trainer-category:before {
  content: "";
  background: #fe6367;
  width: 26px;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -13px; }

.noo-trainers .hentry .trainer-item-wrap:hover {
  background: #fe6367; }

.noo-trainers .hentry .trainer-item-wrap:hover .content-featured img {
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1); }

.noo-trainers .hentry .trainer-item-wrap:hover .content-wrap .content-title a {
  color: #fff; }

.noo-trainers .hentry .trainer-item-wrap:hover .content-wrap .trainer-category a {
  color: #fff; }

.noo-trainers .hentry .trainer-item-wrap:hover .content-wrap .trainer-category:before {
  background: #fff; }

.single-noo_class .noo_class .noo-social {
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  -o-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.single-noo_class .noo_class .noo-social:before {
  height: 0 !important;
  top: 0 !important; }

.single-noo_class .noo_class .noo-social a {
  top: 8px; }

.single-noo_class .noo_class .content-footer {
  margin-top: 0; }

.single-noo_class .noo_class .content-footer #author-bio {
  background: #f4f4f4;
  padding: 50px 2.1428571429em;
  border-top: 1px solid #dbdada; }

.single-noo_class .noo_class .content-footer #author-bio .view-profile {
  color: #fe6367; }

.single-noo_class .noo_class .content-footer #author-bio .view-profile:hover {
  color: #000; }

.single-noo_class .single-sidebar .class-info-sidebar > div {
  padding: 10px 0;
  border-bottom: 1px solid #dbdada; }

.single-noo_class .single-sidebar .class-info-sidebar > div i {
  color: #fe6367;
  padding-right: 8px; }

.single-noo_class .single-sidebar .class-info-sidebar > div span {
  float: right;
  color: #fe6367;
  padding: 0 8px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  background: #f4f4f4;
  margin-left: 5px; }

.single-noo_class .single-sidebar .class-info-sidebar > div:last-child {
  border-bottom: 0; }

.single-noo_class .single-sidebar .register_button {
  width: 100%;
  margin-top: 2.142857142857143em;
  padding: 15px 0; }

.single-noo_class .related-class {
  position: relative; }

.single-noo_class .related-class .related-title {
  margin-top: 0;
  font-size: 18px;
  text-transform: uppercase;
  color: #fe6367;
  position: relative;
  padding-bottom: 10px;
  letter-spacing: 3px;
  line-height: 1.7; }

.single-noo_class .related-class .related-title span {
  color: #454545;
  font-weight: bold; }

.single-noo_class .related-class .related-title:before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 25px;
  height: 1px;
  background: #fe6367; }

@media (max-width: 991px) {
  .single-noo_class .related-class .noo-class-slider-content .noo-class-slider-item .class-slider-featured img {
    width: 100%; } }

.single-noo_class .related-class .noo-class-slider-content .noo-class-slider-item .class-slider-content {
  background: #f4f4f4;
  padding: 1.0714285714285714em; }

.single-noo_class .related-class .noo-class-slider-content .noo-class-slider-item .class-slider-content .class-slider-category a {
  color: #fe6367; }

.single-noo_class .related-class .noo-class-slider-content .noo-class-slider-item .class-slider-content .class-slider-category a:hover {
  color: #000; }

.single-noo_class .related-class .noo-class-slider-content .noo-class-slider-item .class-slider-content .class-slider-title {
  font-size: 1.2857142857142858em;
  text-transform: uppercase;
  line-height: 1.7; }

.single-noo_class .related-class .slider-indicators {
  position: absolute;
  right: 2.142857142857143em;
  top: 220px; }

@media (max-width: 991px) {
  .single-noo_class .related-class .slider-indicators {
    top: 80px; } }

.single-noo_class .related-class .slider-indicators a {
  -webkit-transition: all 0s ease 0s;
  -o-transition: all 0s ease 0s;
  transition: all 0s ease 0s;
  background: #fff;
  display: inline-block;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  float: left;
  margin: 0 5px; }

.single-noo_class .related-class .slider-indicators a.selected {
  background: #fe6367; }

.noo-classes.list .hentry {
  margin: 0;
  margin-bottom: 2.142857142857143em;
  padding-bottom: 0; }

.noo-classes.list .hentry .loop-item-wrap {
  background: #f4f4f4; }

.noo-class-schedule .class-schedule-filter {
  padding: 2.142857142857143em 0; }

.noo-class-schedule .class-schedule-filter ul {
  margin: 0;
  padding: 0;
  text-align: center; }

.noo-class-schedule .class-schedule-filter ul li {
  float: none;
  display: inline-block;
  padding: 0; }

@media (max-width: 991px) {
  .noo-class-schedule .class-schedule-filter ul li {
    margin: 5px; } }

.noo-class-schedule .class-schedule-filter ul li a {
  border: 2px solid #8f8f8f;
  padding: 1.0714285714em 2.1428571429em;
  text-transform: uppercase;
  display: block; }

.noo-class-schedule .class-schedule-filter ul li a.selected,
.noo-class-schedule .class-schedule-filter ul li a:hover,
.noo-class-schedule .class-schedule-filter ul li a.active {
  background: #fe6367;
  border-color: #fe6367;
  color: #fff; }

@media (max-width: 991px) {
  .noo-class-schedule .class-schedule-filter ul li a {
    padding: 5px 10px;
    line-height: 20px; } }

.noo-class-schedule .fc-toolbar {
  padding: 1.0714285714285714em 0; }

.noo-class-schedule .fc-toolbar .fc-left .fc-state-default,
.noo-class-schedule .fc-toolbar .fc-right .fc-state-default {
  background: transparent;
  border-radius: 50%;
  background-image: none;
  box-shadow: none;
  width: 40px;
  height: 40px;
  text-shadow: none; }

.noo-class-schedule .fc-toolbar .fc-left .fc-state-default:hover,
.noo-class-schedule .fc-toolbar .fc-right .fc-state-default:hover,
.noo-class-schedule .fc-toolbar .fc-left .fc-state-default:focus,
.noo-class-schedule .fc-toolbar .fc-right .fc-state-default:focus {
  background: #fe6367;
  color: #fff;
  outline: 0; }

.noo-class-schedule .fc-toolbar .fc-center h2 {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase; }

@media (max-width: 991px) {
  .noo-class-schedule .fc-toolbar .fc-center h2 {
    font-size: 1.2857142857142858em; } }

.noo-class-schedule .fc-view {
  background: #fff; }

.noo-class-schedule .fc-view table {
  border: 0; }

@media (max-width: 991px) {
  .noo-class-schedule .fc-view table {
    border: 1px solid #f4f4f4; } }

.noo-class-schedule .fc-view table tr {
  border: 0; }

.noo-class-schedule .fc-view table th {
  border: 4px solid #f4f4f4;
  border-bottom: 0; }

@media (max-width: 991px) {
  .noo-class-schedule .fc-view table th {
    border: 1px solid #f4f4f4; } }

.noo-class-schedule .fc-view table td {
  border: 4px solid #f4f4f4; }

@media (max-width: 991px) {
  .noo-class-schedule .fc-view table td {
    border: 1px solid #f4f4f4; } }

.noo-class-schedule .fc-view .fc-widget-header .fc-widget-header th {
  padding: 1.0714285714285714em 0;
  font-size: 14px;
  font-weight: 300; }

.noo-class-schedule .fc-view .fc-widget-header .fc-widget-header th:first-child {
  background: #f4f4f4;
  width: 108px !important; }

@media (max-width: 991px) {
  .noo-class-schedule .fc-view .fc-widget-header .fc-widget-header th:first-child {
    width: 40px !important; } }

.noo-class-schedule .fc-view .fc-widget-header .fc-widget-header td {
  border: 0; }

.noo-class-schedule .fc-view .fc-widget-content .fc-time-grid tr {
  height: 80px; }

@media (max-width: 991px) {
  .noo-class-schedule .fc-view .fc-widget-content .fc-time-grid tr {
    height: 50px; } }

.noo-class-schedule .fc-view .fc-widget-content .fc-time-grid .fc-time-grid-event {
  border-radius: 0;
  text-align: center;
  color: #5f5f5f;
  font-size: 14px;
  margin: -2px -4px 4px -2px;
  transition: margin 0.3s ease 0s, left 0.3s ease 0s; }

.noo-class-schedule .fc-view .fc-widget-content .fc-time-grid .fc-time-grid-event .fc-content {
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  position: absolute;
  padding: 5px;
  font-size: 0.8571428571428571em;
  line-height: 1.7; }

.noo-class-schedule .fc-view .fc-widget-content .fc-time-grid .fc-time-grid-event .fc-content .fc-trainer {
  color: #fe6367; }

.noo-class-schedule .fc-view .fc-widget-content .fc-time-grid .fc-time-grid-event .fc-content .fc-trainer:hover {
  color: #000; }

@media (max-width: 767px) {
  .noo-class-schedule .fc-view .fc-widget-content .fc-time-grid .fc-time-grid-event .fc-content .fc-trainer {
    display: none; } }

.noo-class-schedule .fc-view .fc-widget-content .fc-time-grid .fc-time-grid-event .fc-content .fc-title {
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  color: #5f5f5f;
  padding: 5px 0 10px 0;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.3; }

.noo-class-schedule .fc-view .fc-widget-content .fc-time-grid .fc-time-grid-event .fc-content .fc-title:before {
  position: absolute;
  bottom: 0px;
  width: 30px;
  height: 1px;
  content: "";
  background: #5f5f5f;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

@media (max-width: 767px) {
  .noo-class-schedule .fc-view .fc-widget-content .fc-time-grid .fc-time-grid-event .fc-content .fc-time {
    display: none; } }

.noo-class-schedule .fc-view .fc-widget-content .fc-time-grid .fc-time-grid-event .fc-bg {
  background: transparent;
  opacity: 1; }

.noo-class-schedule .fc-view .fc-widget-content .fc-time-grid .fc-time-grid-event:hover {
  left: 0 !important;
  right: 0 !important;
  z-index: 99 !important;
  margin: -2px -4px 4px -2px !important; }

.noo-class-schedule .fc-view .fc-widget-content .fc-time-grid table tbody tr td.fc-axis {
  width: 100px !important;
  text-align: center; }

@media (max-width: 991px) {
  .noo-class-schedule .fc-view .fc-widget-content .fc-time-grid table tbody tr td.fc-axis {
    width: 38px !important; } }

@media (max-width: 991px) {
  .noo-class-schedule .fc-view-container {
    width: 100%;
    overflow-x: scroll; }
  .noo-class-schedule .fc-view {
    width: 768px !important; } }

@media (min-width: 1200px) {
  .post-type-archive-noo_class .noo_class .content-meta > span {
    padding-right: 3em; } }

.single-noo_class .noo_class .content-meta span {
  color: #fe6367; }

.noo-ajax-result {
  font-size: 0.8571428571428571em;
  margin-bottom: 10px;
  display: block; }

.user-membership-package {
  text-align: center; }

.user-membership-package .user-membership-package-content {
  width: 50%;
  margin-top: 50px;
  background: #f9f9f9;
  margin: 0 auto;
  padding: 2.142857142857143em; }

@media (max-width: 767px) {
  .user-membership-package .user-membership-package-content {
    width: 100%; } }

.user-membership-package .user-membership-package-content > .row,
.user-membership-package .user-membership-package-content > .noo-pricing-table {
  margin-bottom: 1.0714285714285714em;
  text-align: left; }

/*-------------------- Sections --------------------*/
.our-class-section {
  background-color: #f4f4f4;
  padding-top: 20px;
  padding-bottom: 20px; }

.our-class-header {
  padding-top: 20px;
  padding-bottom: 0px; }

.our-class-content {
  padding-top: 20px;
  padding-bottom: 20px; }

.our-class-section-2 {
  padding-top: 50px;
  padding-bottom: 0px; }

.best-trainer-section {
  padding-top: 90px;
  padding-bottom: 60px; }

.testimonial-section {
  background-color: #f4f4f4;
  padding-top: 20px;
  padding-bottom: 20px; }

.testimonial-section .testimonial-content p.text {
  font-size: 18px;
  font-style: italic;
  width: 75%; }

.testimonial-section-2 {
  background-image: url(../images/bg-testimonial.png);
  background-color: #f4f4f4;
  padding-top: 30px;
  padding-bottom: 0px; }

.our-blog {
  padding-top: 80px;
  padding-bottom: 80px; }

.subscribe-section {
  background-image: url(../images/member-ship-bg.jpg);
  padding-top: 20px;
  padding-bottom: 20px; }

.subscribe-section .btn-subscribe {
  color: #ffffff;
  background-color: #2a2a2a;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 70px;
  padding-right: 70px; }

.our-services .noo-icon {
  font-size: 24px; }

.our-services-2 {
  background-color: #f4f4f4;
  padding-top: 0px;
  padding-bottom: 0px; }

.our-services-2 .row.text-center {
  padding-top: 40px;
  padding-bottom: 40px;
  color: #626262; }

.our-services-2 .icon.text-primary {
  font-size: 48px; }

.membership-section {
  background-color: #f4f4f4;
  padding-top: 0px;
  padding-bottom: 0px; }

.membership-section .membership-package {
  padding-top: 70px;
  padding-bottom: 50px; }

.noo-class-schedule {
  background-color: #f4f4f4;
  padding-top: 0px;
  padding-bottom: 0px; }

.noo-class-schedule .row {
  padding-top: 20px;
  padding-bottom: 20px; }

.about-us-section {
  padding-top: 20px;
  padding-bottom: 20px; }

.contact-form-section {
  padding-top: 0px;
  padding-bottom: 20px; }

.contact-form-section h3 {
  margin-top: 0;
  margin-bottom: 50px;
  font-weight: 400; }

.shop-masonry-section {
  padding-top: 20px;
  padding-bottom: 20px; }
